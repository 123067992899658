import React from "react";


class TelegramAdvantagesBlock extends React.Component {

    render () {

        return <div className="adv-block">
           
            <div className="adv-second">
                <div className="adv-second-item">
                    <div className="adv-second-title">
                        <svg className="adv-second-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 5L4.69699 5.07744C7.14576 5.34953 9.60878 4.70802 11.6137 3.27594L12 3L12.3863 3.27594C14.3912 4.70802 16.8542 5.34953 19.303 5.07744L20 5V12.0557C20 15.0859 18.288 17.856 15.5777 19.2111L12 21L8.42229 19.2111C5.71202 17.856 4 15.0859 4 12.0557V5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                        <span className="adv-second-title-text">Конфиденциальность</span>
                    </div>
                    <div className="adv-second-content">
                        Ваша информация защищена на 100% и не передается третьим лицам. Только вы и психолог. Специалисты Uwio подписывают соответствующее соглашение
                    </div>
                </div>

            </div>

        </div>;
    }
}

export default TelegramAdvantagesBlock;