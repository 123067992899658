import React from "react";
import { Navigate } from "react-router";
import connection from "../../scripts/connection";
import Loader from "../Loader";
import { isExpired, decodeToken } from "react-jwt";
import { Link } from "react-router-dom";



class Payment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,

            bookAlreadyExpired: false
        }
    }

    componentDidMount() {
        window._tmr.push({ type: 'reachGoal', id: 3479354, goal: 'payments'});


        connection.AuthorizeGet('/booking/booked',
        (success) => {
            console.log(success);

            let bookingTimer;

            if (success.value.bookExpires >= 0)
            {
                let expireDate = new Date();
                expireDate.setSeconds(expireDate.getSeconds() + success.value.bookExpires)

                bookingTimer = setInterval(() => {
                    //let bookingExpires = this.state.expTime ?? success.value.bookExpires;
                    let bookingExpires = (expireDate.getTime() - new Date().getTime()) / 1000;
                    if (bookingExpires > 0)
                    {
                        bookingExpires--;
                        this.setState({expTime: bookingExpires})
                    }
                    else
                    {
                        clearInterval(bookingTimer);
                        this.setState({bookingTimer: undefined, bookingExpires: 0, bookAlreadyExpired: true});
                    }
                }, 1000);
            }

            this.setState({specialist: { name: success.value.specialistName, photo: success.value.specialistPhoto}, 
                expires: success.value.bookExpires,
                consultationDate: success.value.consultationDate,
                payment: success.value.payment,
                expTime: success.value.bookExpires,
                loaded: true,
                id: success.value.bookId,
                bookingTimer: bookingTimer,
                consultationType: success.value.consultationType
            })

            
        },
        (error) => {
            console.log(error)
            this.setState({bookAlreadyExpired: true, loaded: true})
        },
        (fatalError) => {
            console.log(fatalError)
            this.setState({error: true})
        });
        
    }


    payWidget = () => {
        let result = decodeToken(localStorage.getItem('token'));
        console.log({
            type: 0, // Card Privyazka
            bookId: this.state.id 
        })
        console.log(this.state)

        var widget = new window.cp.CloudPayments();
        widget.pay('charge', // или 'charge'
            { //options
                publicId: 'pk_fc4a2c2b11763311e816f257f3031', //id из личного кабинета
                description: 'Привязка карты', //назначение
                amount: 1, //сумма
                currency: 'RUB', //валюта
                accountId: result.id, //идентификатор плательщика (необязательно)
                //invoiceId: '1234567', //номер заказа  (необязательно)
                //email: 'user@example.com', //email плательщика (необязательно)
                skin: "mini", //дизайн виджета (необязательно)
                autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
                data: {
                    type: 0, // Card Privyazka
                    bookId: this.state.id 
                }
            },
            {
                onSuccess: this.pay,
                onFail: function (reason, options) { // fail
                    //действие при неуспешной оплате
                },
                onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                    //например вызов вашей аналитики Facebook Pixel
                }
            }
        )
    }


    calcExpireTime = (expTime) => {
        
        expTime = parseInt(expTime);

        let minutes = parseInt(expTime / 60);

        let seconds = expTime % 60;

        if (minutes < 10)
            minutes = '0' + minutes;
        if (seconds < 10)
            seconds = '0' + seconds;
        
        return minutes + ':' + seconds;
    }

    parseDate = (date) => {
        let months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        let days = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];

        let dt = new Date(date);
        let minutes = dt.getUTCMinutes() < 10 ? '0' + dt.getUTCMinutes() : dt.getUTCMinutes();
        
        return dt.getUTCDate() + ' ' + months[dt.getUTCMonth()] + ', ' + dt.getUTCHours() + ':' + minutes + ', ' + days[dt.getUTCDay()];
    }

    pay = () => {
        console.log("Pay executed")
        connection.AuthorizePost('/client/CardLinked', {
        },
        (success) => {
            this.setState({paid: true});
        },
        (error) => {
            this.setState({error: true});
        },
        (fatalError) => {
            this.setState({error: true});
        });
    }

    render() {
        if (!this.state.loaded)
            return <div style={{height: '600px'}}><Loader /></div>;

        if (this.state.paid)
        {
            this.props.onPaid();
            return <Navigate to='/success'/>
        }

        if (this.state.error)
            return <div>Прозишла ошибка, попробуйте позже</div>;

        if (this.state.bookAlreadyExpired)
        {
            console.log('expired')
            this.props.onExpired();
            return <Navigate to='/select'/>
        }

        return <div className="reg-complete-content">
            <div className="reg-steps">
                <Link to='/survey'>
                <div className="reg-steps-item">
                        <span className="step-num step-num-current">1</span>
                        <span className="body-text-small reg-num-title">Общие вопросы</span>
                    </div>
                </Link>
                <div className="reg-steps-divide reg-steps-divide-type1"></div>
                <Link to='/select' style={{textDecoration: 'none', cursor: 'pointer'}}>
                <div className="reg-steps-item">
                    <span className="step-num step-num-current">2</span>
                    <span className="body-text-small reg-num-title">Выбор специалиста</span>
                </div>
                </Link>
                <div className="reg-steps-divide reg-steps-divide-type1"></div>
                <div className="reg-steps-item">
                    <span className="step-num step-num-current">3</span>
                    <span className="body-text-small reg-num-title">Запись и оплата</span>
                </div>
            </div>
            <div className="big-card medium-card payment-card">
                <div style={{marginBottom: '28px'}}>
                    <h1>Добавьте карту для записи</h1>
                    <span>При оплате картой спишем 1 рубль, чтобы проверить карту и подтвердить бронь</span>
                </div>
                <div className="payment-columns">
                    <div className="reg-column-block booking-left-block">
                        <div className="booking-about">
                            <img className="booking-person-pic" width="80" height="80" src={this.state.specialist.photo ? 'avatars/' + this.state.specialist.photo : "images/avatars/selected.png"}/>
                            <div className="booking-about-details">
                                <span className="booking-person-text">{this.state.specialist.name}</span>
                                <span>{this.state.consultationType === "Single" ? 'Индивидуальная' : 'Парная'} сессия {this.state.consultationType === "Single" ? '55' : '100'} мин</span>
                                <span>{this.parseDate(this.state.consultationDate)}</span>
                            </div>
                        </div>

                        <div className="divide-line"></div>
                        <div className="booking-details">
                            <span>Стоимость сессии</span>
                            <span>{this.state.payment} P</span>
                        </div>
                        <div className="booking-details">
                            <span>Бронь действительна ещё</span>
                            <span>{this.calcExpireTime(this.state.expTime)}</span>
                        </div>
                        <button onClick={this.payWidget} className="booking-btn">
                            <img src="images/icons/creditCard.svg"/>
                            <span>Добавить карту и записаться</span>
                        </button>
                    </div>
                    <div className="reg-column-block">
                        <ul className="booking-info">
                            <li>
                                <div>Спишем деньги с карты за 8 часов до сессии</div>
                                <span className="booking-info-details">Или сразу после проверки карты, если до сессии меньше 8 часов</span>
                            </li>
                            <li>Создадим личный кабинет и комнату для консультаций</li>
                            <li>Бесплатная отмена сессии за 8 и более часов</li>
                            <li>
                                <span>Ваш платеж защищен</span>
                                <img className="info-icon" src="images/icons/info.svg"/>
                            </li>
                        </ul>
                        <div className="card-block">
                            <img src="images/icons/card1.png"/>
                            <img src="images/icons/card2.png"/>
                            <img src="images/icons/card3.png"/>
                            <img src="images/icons/card4.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default Payment;