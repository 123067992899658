import React from "react";
import FreeSelectSpecialist from "./FreeSelectSpecialist";
import SpecialistsShowcase from "./SpecialistsShowcase";


class ScreenSizeWrapper extends React.Component {



    render () {

        if (window.screen.width <= 520)
            return this.props.mobile;

        return this.props.desktop;
    }
}

export default ScreenSizeWrapper;