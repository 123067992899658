import React from "react";
import { act } from "react-dom/test-utils";
import Connections from "../../scripts/connection";
import Calendar from "../Calendar";


class TimeSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            selectedDate: null,
            selectedTime: [],
            timezone: null,

            availableTimeByDate: null,
            loaded: false,

            selectedDateState: 0
        }
    }

    componentDidMount() {
        Connections.Get('/booking/availabletimeold?timezoneOffset=' + new Date().getTimezoneOffset(),
            (result) => {
                console.log(result.value)
                result = result.value;
                let selectedDate = result.availableDate.find(item => item.tag === 'today').date;
                let availableTimeByDate = result.availableTime.find(item => item.date === selectedDate).time;
                this.setState({availableTime: result.availableTime, 
                    availableDate: result.availableDate, 
                    selectedDate: selectedDate, 
                    availableTimeByDate: availableTimeByDate,
                    loaded: true
                });
            },
            this.props.onErrorOccured,
            this.props.onErrorOccured
        );
    }

    timeSelectorBtn = () => {

        let result = [[],[],[]];

        for (let i = 0; i < 8; i++)
        {
            if (this.state.availableTimeByDate.findIndex(item => item === i) !== -1)
            {
                result[0].push(
                    <button key={i} onClick={() => this.selectTime(i)} className={"time-selector-btn" + (-1 !== this.state.selectedTime.findIndex(item => item === i) ? ' time-selector-btn-active' : '')}>0{i}:00</button>
                );
            }
            else
            {
                result[0].push(
                    <button key={i} className="time-selector-btn time-selector-btn-disabled">0{i}:00</button>
                );
            }
        }

        for (let i = 8; i < 16; i++)
        {
            if (this.state.availableTimeByDate.findIndex(item => item === i) !== -1)
            {
                result[1].push(
                    <button key={i} onClick={() => this.selectTime(i)} className={"time-selector-btn" + (-1 !== this.state.selectedTime.findIndex(item => item === i) ? ' time-selector-btn-active' : '')}>{i < 10 ? '0' + i : i}:00</button>
                );
            }
            else
            {
                result[1].push(
                    <button key={i} className="time-selector-btn time-selector-btn-disabled">{i < 10 ? '0' + i : i}:00</button>
                );
            }
        }

        for (let i = 16; i < 24; i++)
        {
            if (this.state.availableTimeByDate.findIndex(item => item === i) !== -1)
            {
                result[2].push(
                    <button key={i} onClick={() => this.selectTime(i)} className={"time-selector-btn" + (-1 !== this.state.selectedTime.findIndex(item => item === i) ? ' time-selector-btn-active' : '')}>{i}:00</button>
                );
            }
            else
            {
                result[2].push(
                    <button key={i} className="time-selector-btn time-selector-btn-disabled">{i}:00</button>
                );
            }
        }

        return result.map((item, index) => (<div key={index} className="time-selector-btn-block">{item.map(i => i)}</div>));
    }

    
    getDateTime = (dt) => {
        let currentTime = this.state.availableTime.find(item => item.date == dt);

        if (currentTime)
        {
            this.setState({availableTimeByDate: currentTime.time});
        }
        else
        {
            let request = new Date(dt);
            Connections.Get('/booking/availabletimebydate?year=' + request.getFullYear() +'&month=' + (request.getMonth() + 1) + "&day=" + request.getDate() + '&timezoneOffset=' + new Date().getTimezoneOffset(),
            (result) => {
                let avTime = this.state.availableTime;

                avTime.push(result.value)

                this.setState({availableTime: avTime, availableTimeByDate: result.value.time});
            },
            this.props.onErrorOccured,
            this.props.onErrorOccured
            );
        }
    }



    selectDate = (dt, selectedIndex) => {
        this.setState({selectedDate: dt, availableTimeByDate: [], selectedDateState: selectedIndex,showCalendar: false});
        this.selectTime(-1);
        this.getDateTime(dt);
    }


    getTimeZone = () => {
        let hours = new Date().getHours();
        let minutes = new Date().getMinutes();
        let time = (hours < 10 ? '0' + hours : hours) + ':' +(minutes < 10 ? '0' + minutes : minutes);
        return 'GMT+' + (new Date().getTimezoneOffset() / -60) + ' (' + time + ')';
    }

    selectTime = (time) => {
        console.log(time)
        if (time === -1)
        {
            this.props.onSelect(undefined);
            this.setState({selectedTime: []});
        }
        else
        {
            // Добавить проверку на количество

            let selectedTime = this.state.selectedTime;
            let timeIndex = this.state.selectedTime.findIndex(item => item === time);

            if (timeIndex != -1)
                selectedTime.splice(timeIndex, 1);
            else if (selectedTime.length < 4)
                selectedTime.push(time);

            this.props.onSelect(selectedTime.map(st => { return { date: this.state.selectedDate, time: st }}));

            this.setState({selectedTime: selectedTime});
        }
    }

    getCurrentDate = () => {
        const monthList = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря']
        const weekDays = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота']

        let dt = new Date(this.state.selectedDate)
        let day = dt.getDate();
        let month = monthList[dt.getMonth()]

        return <div style={{fontWeight: '600', marginBottom: '0.7rem'}}>
            {day} {month}, {weekDays[dt.getDay()]}
        </div>
    }

    render() {
        if (!this.state.loaded)
            return "Загрузка доступного времени...";

        return(
            <div>
                <Calendar show={this.state.showCalendar} dates={this.state.availableDate} onSelect={(dt) => this.selectDate(dt, 2)} onHide={() => this.setState({showCalendar: false})}/>
                <h2 className="reg-block-header">Выберите время сессии</h2>

                <div className="body-text-small time-selector-block day-selector-block">
                    <div onClick={()=> this.selectDate(this.state.availableDate[0].date, 0)} className={"day-selector" + (this.state.selectedDateState === 0 ? ' day-selector-active' : '')}>Сегодня</div>
                    <div onClick={()=> this.selectDate(this.state.availableDate[1].date, 1)} className={"day-selector" + (this.state.selectedDateState === 1 ? ' day-selector-active' : '')}>Завтра</div>
                    <div onClick={()=> this.setState({showCalendar: true})} className={"day-selector" + (this.state.selectedDateState === 2 ? ' day-selector-active' : '')}>Другой день</div>
                </div>
                
                {this.getCurrentDate()}

                <div className="body-text-small time-selector-block">
                    <img className="info-icon" style={{width: '30px', marginRight: '0.5rem'}} src="images/icons/blueInfo.svg"/>
                    <div style={{width: '325px'}}>Выберите несколько удобных часов</div>
                </div>

                {this.timeSelectorBtn()}

                <div className="body-text-small">
                    Часовой пояс: <span className="timezone">{this.getTimeZone()}</span>
                </div>
            </div>
        )
    }
}

export default TimeSelector;