import React from "react";


class SliderCard extends React.Component {


    getName = (name) => {
        return name.split(' ')[0];
    }

    getExperience = (experience) => {
        if (experience == 11 || experience == 12 || experience == 13 || experience == 14)
            return experience + ' лет';

        let last = experience % 10;

        if (last === 1)
            return experience + ' год';

        if (last < 5 && last != 0)
            return experience + ' года';

        return experience + ' лет';
    }

    render () {

        return <div onClick={() => this.props.onSelect(this.props.index)} className={"onboarding-slider-card " + (this.props.selected ? 'active' : '')}>
            
                <img className="slider-card-photo" src={"avatars/" + this.props.photo ?? 'default.png'} alt="Фото"/>
            
            <div className="slider-content">
                <div className="slider-name">{this.getName(this.props.name)}</div>
                <div className="slider-exp">Опыт: <span className="slider-exp-value">{this.getExperience(this.props.experience)}</span></div>
            </div>
        </div>;
    }
}

export default SliderCard;