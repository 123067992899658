import React from "react";
import { isExpired, decodeToken } from "react-jwt";
import connection from "../scripts/connection";
import Loader from "./Loader";
import OkCancelModal from "./OkCancelModal";



class BillingData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        connection.AuthorizeGet('/billing/data',
        (success) => {
            console.log(success)

            this.setState({cardNum: success.value.cardNum, payments: success.value.payments, loaded: true})
        },
        (error) => console.log(error),
        (fatalError) => console.log(fatalError));
    }

    payWidget = () => {
        let result = decodeToken(localStorage.getItem('token'));

        var widget = new window.cp.CloudPayments();
        widget.pay('charge', // или 'charge'
            { //options
                publicId: 'pk_fc4a2c2b11763311e816f257f3031', //id из личного кабинета
                description: 'Привязка карты', //назначение
                amount: 1, //сумма
                currency: 'RUB', //валюта
                accountId: result.id, //идентификатор плательщика (необязательно)
                //invoiceId: '1234567', //номер заказа  (необязательно)
                //email: 'user@example.com', //email плательщика (необязательно)
                skin: "mini", //дизайн виджета (необязательно)
                autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
                data: {
                    type: 2,
                }
            },
            {
                onSuccess: this.loadData,
                onFail: function (reason, options) { // fail
                    //действие при неуспешной оплате
                },
                onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                    //например вызов вашей аналитики Facebook Pixel
                }
            }
        )
    }
    

    removeCard = () => {
        this.setState({removeCardModal: false})

        connection.AuthorizePost('/billing/removecard', 
        undefined,
        (success) => {
            console.log(success)

            this.setState({cardNum: undefined});
        },
        (error) => {
            console.log(error)
        },
        (fatalError) => {
            console.log(fatalError)
        })
    }

    getStatusDisplay = (status) => {
        if (status === "Booked")
            return <div className="payments-result payment-status-booked">Запланировано</div>;
        if (status === "Planned")
            return <div className="payments-result payment-status-inprocess">В процессе</div>;
        if (status === "Cancelled")
            return <div className="payments-result payment-status-cancelled">Отклонено</div>;
        if (status === "Completed")
            return <div className="payments-result payment-status-success">Успешно</div>;
    }

    getDateDisplay = (date) => {
        date = new Date(date);

        let months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        let days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

        // Время
        // День с месяцем
        // день недели
        let time = date.getUTCHours() + ':' + (date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes());
        let day = date.getUTCDate() + ' ' + months[date.getUTCMonth()];
        let dayOfWeek = days[date.getUTCDay()];


        return day + ' ' + date.getUTCFullYear() + ', ' + time;
    }

    render() {
        if (!this.state.loaded)
            return <Loader />
            

        return <div className="big-card">
            {this.state.removeCardModal ?
                <OkCancelModal message='Вы действительно хотите удалить карту?' header='Удаление карты' onHide={() => this.setState({removeCardModal: false})} onAccept={this.removeCard}/>
                :
                undefined
            }

            <div>
                <h1 className="payments-header">Оплата</h1>
                <h3 style={{fontSize: '20px'}}>Способ оплаты</h3>
                <span>Карта: {this.state.cardNum ?? "Не указана"}</span>
                {this.state.cardNum ?
                <div className="payments-controls">
                    <button onClick={this.payWidget} className="payments-button">Привязать другую</button>
                    <button onClick={() => this.setState({removeCardModal: true})} className="button-white payments-button">Удалить карту</button>
                </div>
                :
                <div className="payments-controls">
                    <button onClick={this.payWidget} className="payments-button">Привязать карту</button>
                </div>
                }
            </div>
            <div className="divide-line"></div>
            <div>
                <h3 style={{fontSize: '20px'}}>История платежей</h3>
                <table className="payments-list">
                    <thead>
                        <tr>
                            {/*
                            <td className="payments-list-header">Номер заявки</td>
                            <td className="payments-list-header">Сессия</td>
                            <td className="payments-list-header">Сумма</td>
                            <td className="payments-list-header">Холд</td>
                            <td className="payments-list-header">Списание</td>
                            <td className="payments-list-header">Статус</td>*/}
                            <td className="payments-list-header">Специалист</td>
                            <td className="payments-list-header">Дата сессии</td>
                            <td className="payments-list-header">Сумма</td>
                            <td className="payments-list-header">Статус</td>
                        </tr>
                    </thead>
                    <tbody>

                        {this.state.payments?.map( (item, index) => {
                            return <tr key={index} className="payments-list-item">
                                        <td>
                                            {item.specialist}
                                        </td>
                                        <td>
                                            {this.getDateDisplay(item.date)}
                                        </td>
                                        <td>{item.price} ₽</td>
                                        <td>
                                            {this.getStatusDisplay(item.paymentStatus)}
                                        </td>
                                    </tr>
                        })
                        }
                        {/*
                        <tr className="payments-list-item">
                            <td>579231213</td>
                            <td>
                                <div className="payments-list-multiple">
                                    <span>Екатерина Усова</span>
                                    <span className="payments-addition">20 октября, среда</span>
                                </div>
                            </td>
                            <td>2 850 ₽</td>
                            <td>
                                <div className="payments-list-multiple">
                                    <span>11.11.2011</span>
                                    <span className="payments-addition">запланировано</span>
                                </div>
                            </td>
                            <td>
                                <div className="payments-list-multiple">
                                    <span>11.11.2011</span>
                                    <span className="payments-addition">запланировано</span>
                                </div>
                            </td>
                            <td>
                                <div className="payments-result">
                                    Успешно
                                </div>
                            </td>
                        </tr>
                        <tr className="payments-list-item">
                            <td>579231213</td>
                            <td>
                                <div className="payments-list-multiple">
                                    <span>Екатерина Усова</span>
                                    <span className="payments-addition">20 октября, среда</span>
                                </div>
                            </td>
                            <td>2 850 ₽</td>
                            <td>
                                <div className="payments-list-multiple">
                                    <span>11.11.2011</span>
                                    <span className="payments-addition">запланировано</span>
                                </div>
                            </td>
                            <td>
                                <div className="payments-list-multiple">
                                    <span>11.11.2011</span>
                                    <span className="payments-addition">запланировано</span>
                                </div>
                            </td>
                            <td>
                                <div className="payments-result">
                                    Успешно
                                </div>
                            </td>
    </tr>*/}
                    </tbody>
                </table>
                {/*<div className="show-more">Показать ещё <img src="images/icons/dropDown.svg"/></div>*/}
            </div>
        </div>;
    }
}

export default BillingData;