import React from "react";

import { Modal, Stack, Button, Dropdown, DropdownButton } from "react-bootstrap";
import TestPanelRoute from "./components/TestPanelRoute";

class SoundTestPanel extends React.Component {

    constructor(props) {
        super(props);


        this.state = {

        };
    }

    componentDidMount() {

        console.log('Sound mounted');
    }

    componentWillUnmount() {

        this.state.audio?.pause();
    }


    downloadTestSound = () => {

        let audio = this.state.audio;

        if (audio === undefined)
        {
            audio = new Audio('sounds/soundTest.wav');
            this.setState({audio: audio});
        }


        let playPromise = audio.play();

        if (playPromise !== undefined) {
            playPromise.then(function() {
                
                // Automatic playback started!
                console.log('playing')

            }).catch(error => {
                // Automatic playback failed.
                // Show a UI element to let the user manually start playback.
                console.log('Error');
            });

            this.props.onTestResult();
        }
        else
        {
            console.log('Browser does not support');
        }
    }

    render() {

        return (
            <Stack gap={5}>
                <TestPanelRoute />

                <Stack gap={3}>
                    <div className="device-test-message">
                        Нажмите на кнопку, чтобы проверить колонки или наушники
                    </div>
                
                    <Button onClick={this.downloadTestSound} variant="device-test-button"><img src="images/icons/playTriangle.svg" /> Тест звука</Button>
                </Stack>

                <Stack gap={3} style={{alignSelf: 'center'}}>
                    <div className="device-selector-message">
                        Выберите устройство
                    </div>
                    <Stack direction="horizontal" gap={2} style={{position: 'relative', alignSelf: 'center'}}>
                        <img src="images/icons/Speaker.svg" className="device-selector-icon"/>
                        <DropdownButton variant="device-selector" title="По умолчанию">
                            <Dropdown.Item as="button" style={{width: '222px'}} />
                        </DropdownButton>
                    </Stack>
                    {
                     this.props.failed ?
                        <div style={{color: 'red'}}>
                            Проверьте подключение вашего устройства и попробуйте ещё раз
                        </div>
                    : undefined
                    }
                </Stack>

            </Stack>
        );
    }
}

export default SoundTestPanel;