import { Stack, Button } from "react-bootstrap";


function NoDeviceContent(props) {
    return (
        <Stack gap={4} style={{alignItems: 'center'}}>
            <div className="device-test-message" style={{fontSize: '2rem'}}>
                Не найдено устройств
            </div>
            <div className="device-test-message" style={{fontSize: '1rem'}}>
                Проверьте подключение устройств и повторите попытку
            </div>
            <Button onClick={props.onDevicesUpdate} variant="device-test-button">Обновить</Button>
        </Stack>);
}

export default NoDeviceContent;