import React from "react";
import Loader from "./Loader";
import connection from "../scripts/connection";
import analysis from "../scripts/analysis";


class ClientAnalysis extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };

        this.wellnessRef = React.createRef();
        this.sleepRef = React.createRef();
        this.selfTimeRef = React.createRef();
    }

    componentDidMount() {
        let clientId = localStorage.getItem('clientId');

        setTimeout(() =>
        analysis.InitAnalysis(clientId, this.wellnessRef.current, this.sleepRef.current, this.selfTimeRef.current)
        );
    }

    render () {



        return  <div>
        <div className="wellness">
            <div className="wellness-header">Самочувствие и продуктивность</div>
            <canvas id="wellness" ref={this.wellnessRef} width="360" height="370"></canvas>
            <div className="wellness-left-axe">
                <div>10</div>
                <div>9</div>
                <div>8</div>
                <div>7</div>
                <div>6</div>
                <div>5</div>
                <div>4</div>
                <div>3</div>
                <div>2</div>
                <div>1</div>
            </div>
            <div id="wellness-down-axe" className="wellness-down-axe" style={{bottom: '65px'}}>
                <div className="down-axe-item">1</div>
                <div className="down-axe-item">2</div>
                <div className="down-axe-item">3</div>
                <div className="down-axe-item">4</div>
                <div className="down-axe-item">5</div>
                <div className="down-axe-item">6</div>
                <div className="down-axe-item">7</div>
                <div className="down-axe-item">8</div>
                <div className="down-axe-item">9</div>
            </div>
            <div className="wellness-legend">
                <div className="wellness-line">
                    — Самочувствие и продуктивность
                </div>
            </div>

            
            <img onClick="showWellnessFaq()" className="faq-question-icon" src="images/icons/question.svg" width="18" height="18"/>

            <div id="wellness-faq" className="faq-card" style={{display: 'none'}}>
                График показывает динамику изменений вашего эмоционального состояния и продуктивности в рамках проходимой терапии<br/><br/>
                По нажатию на любую из точек на графике вы сможете ознакомиться с эмоциями, которые вы испытывали в день заполнения сканера состояния
            </div>

        </div>

      
        <div id="wellness-card" className="details-card" style={{display: 'none'}}>
            <div onClick={analysis.closeWellnessCard} className="details-close"><img src="img/menu/cross.svg" alt="x" width="12" height="12"/></div>
            <div id="wellness-card-date" className="details-card-date">17.08.2024</div>
            <div id="wellness-card-emotions" className="details-card-header">Эмоции</div>
            <div id="wellness-card-content" className="details-card-content">Курс состоит из 14 дней. Каждый день Вы будете получать ссылки на исследования и практические задания В дневнике вы сможете вносить данные о вашем текущем эмоциональном состоянии</div>
        </div>

        <div id="sleep-card" className="details-card" style={{display: 'none'}}>
            <div onClick={analysis.closeSleepCard} className="details-close"><img  src="img/menu/cross.svg" alt="x" width="12" height="12"/></div>
            <div id="sleep-card-date" className="details-card-date">17.08.2024</div>
            <div className="details-card-header">Сон</div>
            <div style={{marginBottom: '15px'}}>
                <div style={{marginBottom: '5px'}} className="details-card-header">Длительность засыпания</div>
                <div id="asleep-block-content" className="asleep-block">
                    <div className="asleep-icon"><img id="asleep-card-asleep-icon" src="images/icons/asleep-fast.svg" width="18" height="18"/></div>
                    <span id="sleep-card-asleep">Быстро</span>
                </div>
            </div>
            <div style={{marginBottom: '15px'}}>
                <div style={{marginBottom: '5px'}} className="details-card-header">Количество ночных пробуждений</div>
                <div id="awake-block-content" className="asleep-block">
                    <div id="sleep-card-awake-count-icon" className="asleep-icon">0</div>
                    <div id="sleep-card-awake-count">0</div>
                </div>
            </div>

            <div id="sleep-card-awake-comment" className="details-card-content">Курс состоит из 14 дней. Каждый день Вы будете получать ссылки на исследования и практические задания В дневнике вы сможете вносить данные о вашем текущем эмоциональном состоянии</div>
        </div>

        <div id="self-card" className="details-card" style={{display: 'none'}}>
            <div onClick={analysis.closeSelfCard} className="details-close"><img src="img/menu/cross.svg" alt="x" width="12" height="12"/></div>
            <div id="self-card-date" className="details-card-date">17.08.2024</div>
            <div id="self-card-emotions" className="details-card-header">Эмоции</div>
            <div id="self-card-content" className="details-card-content">Курс состоит из 14 дней. Каждый день Вы будете получать ссылки на исследования и практические задания В дневнике вы сможете вносить данные о вашем текущем эмоциональном состоянии</div>
        </div>

        
        <div className="wellness emotions">
            <img id="sad-smile" src="images/icons/sad.svg" style={{display: 'none'}}/>
            <img id="happy-smile" src="images/icons/happy.svg" style={{display: 'none'}}/>
            <img id="neutral-smile" src="images/icons/neutral.svg" style={{display: 'none'}}/>

            <div className="wellness-header">Карта эмоционального состояния</div>
            <div className="emotions-container">
                <div id="emotions-item-1" className="emotions-item" style={{background: '#AFCCCE'}}>
                    <img src="images/icons/neutral.svg"/>
                </div>
                <div id="emotions-item-2" className="emotions-item" style={{background: '#AFCCCE'}}>
                    <img src="images/icons/neutral.svg"/>
                </div>
                <div id="emotions-item-3" className="emotions-item" style={{background: '#AFCCCE'}}>
                    <img src="images/icons/neutral.svg"/>
                </div>
                <div id="emotions-item-4" className="emotions-item" style={{background: '#AFCCCE'}}>
                    <img src="images/icons/neutral.svg"/>
                </div>
                <div id="emotions-item-5" className="emotions-item" style={{background: '#AFCCCE'}}>
                    <img src="images/icons/neutral.svg"/>
                </div>
                <div id="emotions-item-6" className="emotions-item" style={{background: '#AFCCCE'}}>
                    <img src="images/icons/neutral.svg"/>
                </div>
                <div id="emotions-item-7" className="emotions-item" style={{background: '#AFCCCE'}}>
                    <img src="images/icons/neutral.svg"/>
                </div>
                <div id="emotions-item-8" className="emotions-item" style={{background: '#AFCCCE'}}>
                    <img src="images/icons/neutral.svg"/>
                </div>
                <div id="emotions-item-9" className="emotions-item" style={{background: '#AFCCCE'}}>
                    <img src="images/icons/neutral.svg"/>
                </div>
            </div>
            <div id="emotions-down-axe" className="wellness-down-axe emotions-down-axe" style={{gap: '7.8px'}}>
                <div className="down-axe-item">1</div>
                <div className="down-axe-item">2</div>
                <div className="down-axe-item">3</div>
                <div className="down-axe-item">4</div>
                <div className="down-axe-item">5</div>
                <div className="down-axe-item">6</div>
                <div className="down-axe-item">7</div>
                <div className="down-axe-item">8</div>
                <div className="down-axe-item">9</div>
            </div>
            <div className="emotions-description">
                <div className="emotions-description-item">
                    <div id="good-days-percent" style={{backgroundColor: '#60E25D'}} className="emotions-description-item-value">75%</div>
                    <svg width="14" height="1" viewBox="0 0 14 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 0.5H13" stroke="white" stroke-linecap="round"/>
                    </svg>
                    Хорошие дни
                </div>
                <div className="emotions-description-item">
                    <div id="neutral-days-percent" style={{backgroundColor: '#AFCCCE'}} className="emotions-description-item-value">75%</div>
                    <svg width="14" height="1" viewBox="0 0 14 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 0.5H13" stroke="white" stroke-linecap="round"/>
                    </svg>
                    Нейтральные дни
                </div>
                <div className="emotions-description-item">
                    <div id="bad-days-percent" style={{backgroundColor: '#F93434'}} className="emotions-description-item-value">75%</div>
                    <svg width="14" height="1" viewBox="0 0 14 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 0.5H13" stroke="white" stroke-linecap="round"/>
                    </svg>
                    Плохие дни
                </div>
            </div>
        </div>

        
        <div className="wellness">
            <div className="wellness-header">Сон</div>
            <canvas id='sleep' ref={this.sleepRef} width="360" height="430"></canvas>
            <div style={{left: '7px', fontSize: '9px'}} className="wellness-left-axe">
                <div>05:00</div>
                <div>04:00</div>
                <div>03:00</div>
                <div>02:00</div>
                <div>01:00</div>
                <div>00:00</div>
                <div>23:00</div>
                <div>22:00</div>
                <div>21:00</div>
                <div>20:00</div>
                <div>19:00</div>
                <div>18:00</div>
            </div>
            <div id="asleep-down-axe" style={{bottom: '82px'}} className="wellness-down-axe">
                <div className="down-axe-item">1</div>
                <div className="down-axe-item">2</div>
                <div className="down-axe-item">3</div>
                <div className="down-axe-item">4</div>
                <div className="down-axe-item">5</div>
                <div className="down-axe-item">6</div>
                <div className="down-axe-item">7</div>
                <div className="down-axe-item">8</div>
                <div className="down-axe-item">9</div>
            </div>
            <div className="sleep-legend">
                <div className="sleep-time">
                    — Время отхода ко сну
                </div>
                <div className="sleep-quality">
                    — Оценка качества сна
                </div>
            </div>

            <img onClick="showSleepFaq()" className="faq-question-icon" src="images/icons/question.svg" width="18" height="18"/>

            <div id="sleep-faq" className="faq-card" style={{display: 'none'}}>
                Следите на графике за изменением времени отхода ко сну и вашей оценкой качества сна.<br/><br/>
                Оптимальное время отхода ко сну от 22:00 до 24:00<br/><br/>
                При нажатии на любой элемент графика вы получите дополнительную информацию, включающую в себя следующие данные:<br/><br/>
                1) Длительность засыпания<br/>
                2) Количество ночных пробуждений
            </div>
        </div>

        <div style={{marginBottom: '100px'}} className="wellness">
            <div className="wellness-header">Время на себя</div>
            <canvas id='self-time' ref={this.selfTimeRef} width="360" height="240"></canvas>
            <div style={{left: '10px'}} className="wellness-left-axe">
                <div>&lt;8</div>
                <div>6-7</div>
                <div>4-5</div>
                <div>2-3</div>
                <div>1</div>
                <div>&lt;1</div>
            </div>
            <div id="self-down-axe" style={{bottom: '27px'}} className="wellness-down-axe">
                <div className="down-axe-item">1</div>
                <div className="down-axe-item">2</div>
                <div className="down-axe-item">3</div>
                <div className="down-axe-item">4</div>
                <div className="down-axe-item">5</div>
                <div className="down-axe-item">6</div>
                <div className="down-axe-item">7</div>
                <div className="down-axe-item">8</div>
                <div className="down-axe-item">9</div>
            </div>
        
            <img onClick="showSelfFaq()" className="faq-question-icon" src="images/icons/question.svg" width="18" height="18"/>

            <div id="self-faq" className="faq-card" style={{display: 'none'}}>
                Каждый день желательно уделять своим потребностям и увлечениям хотя бы 2-3 часа в день, так как это один из важных пунктов в борьбе с симптомами тревоги, депрессии и выгорания.
                <br/><br/>
                На графике вы можете следить за тем, сколько времени уделили себе, а по нажатию на любой из столбцов узнаете, что конкретно делали для своего удовольствия
            </div>
        </div>

        <img id="awake-bad-img" style={{display: 'none'}} src="images/icons/awake-bad-black.svg" />
        <img id="awake-ideal-img" style={{display: 'none'}} src="images/icons/awake-ideal-black.svg" />
        <img id="awake-mid-img" style={{display: 'none'}} src="images/icons/awake-mid-black.svg" />
        <img id="awake-well-img" style={{display: 'none'}} src="images/icons/awake-well-black.svg" />

    </div>;
    }
}

export default ClientAnalysis;