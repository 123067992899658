import React from "react";

import Connections from "../../scripts/connection"

import PhoneInput from "react-phone-number-input/input";

class RegisterForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            phone: '+7',
        }
    }

    register = () => {
        if (this.state.phone.trim() == '+7')
            return;

        var surveyData = localStorage.getItem('survey');
        var survey = JSON.parse(surveyData);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        Connections.Post('/client/create', 
            {
                subcategoriesId: this.props.subcategoriesId,
                preferredGender: this.props.preferredGender,
                timezoneOffsetInMinutes: this.props.timezoneOffset,
                timePeriods: this.props.timePeriods,
                consultationType: 'Single',
                phoneNumber: this.state.phone,
                questionsAnswers: survey.questionsAnswers,
                name: survey.name,
                age: survey.age,
                
                utmSource: urlParams.get('utm_source'),
                utmMedium: urlParams.get('utm_medium'),
                utmCampaign: urlParams.get('utm_campaign'),
                utmTerm: urlParams.get('utm_term'),
                utmContent: urlParams.get('utm_content')
            },
            (result) => {
                
                this.props.onRegistered(this.state.phone);
                //this.setState({message: 'Код отправлен!'});
                //this.props.onCodeSended(this.state.phone);
            },
            (result) => {
                console.log(result)
                //console.log(result)
                if (result.value.code === 'number_already_used')
                    this.setState({message: "Номер уже зарегистрирован"})
                else if (result.value.code === 'age_is_not_valid')
                    this.setState({message: "Введен некорректный возраст"})
                else if (result.value.code === 'incorrect_phone_number')
                    this.setState({message: 'Некорректный номер'});
                else
                    this.setState({message: "Произошла ошибка"})
            },
            (err) => {
                this.setState({message: "Сервис временно недоступен, попробуйте позже"})
            });
        
        window.ym(94253611,'reachGoal','prebuy');
        window.ym(94253611,'reachGoal','createdaccaount');
        window._tmr.push({ type: 'reachGoal', id: 3479354, goal: 'poluchit_kod'})
    }

    render() {

    return (<div className='login-content'>
                <div className='login-form'>
                    <h2 className='login-title'>Создайте аккаунт</h2>
                    <div className='login-info login-info-bold'>
                        <div>Введите ваш номер телефона</div>
                        <div>Отправим SMS с проверочным кодом</div>
                    </div>
{/*}
                    <div className="reg-input-block">
                        <input style={{width: '91%', marginBottom: '0.5rem'}} onChange={(e) => {this.setState({name: e.target.value})}} value={this.state.name} className="field-text" type="text" placeholder="Имя или псевдоним"/>
                        <input style={{width: '91%', marginBottom: '0.5rem'}} onChange={(e) => {this.setState({personAge: e.target.value})}} value={this.state.personAge} min={0} max={100} className="field-text" type="number" placeholder="Возраст"/>
    </div>*/}
                    <div className="login-field field-text">
                        <span style={{marginTop: 'auto', marginBottom: 'auto'}}>
                            <img style={{margin: '0 0.5rem'}} src='images/icons/ru.svg' /> +7
                        </span>
                        <PhoneInput defaultCountry="RU" className="field-text" style={{border: 'none', padding: '0', paddingLeft: '0.4rem', width: '75%'}} placeholder="999 999-99-99" value={this.state.phone} onChange={(event) => { this.setState({phone: event})}}/>
                    </div>
                    <div className="login-inform">
                        <img className="login-inform-icon" src="images/icons/inform.svg"/>
                        <span className="login-inform-text">Ваш номер телефона не будет использоваться для звонков</span>
                    </div>
                    <div className='login-button-container'>
                        <button className='login-button' onClick={() => this.register()}>
                            Получить код
                        </button>
                    </div>
                    {this.state.message ?
                        <div className='login-info'>
                            {this.state.message}
                        </div>
                    :
                        undefined
                    }
                    <div className="login-info" style={{fontSize: '11px'}}>
                    Вводя свой номер, вы принимаете условия <a href="https://uwio.ru/docs/agreement.pdf" target="_blank" rel="noreferrer">пользовательского соглашения</a>, даете <a href="https://uwio.ru/docs/politics.pdf" target="_blank" rel="noreferrer">согласие на обработку персональных данных</a> и на получение SMS-паролей, а также иных информационных и сервисных сообщений на указанный номер телефона
                    </div>
                </div>
            </div>);
    }
}


export default RegisterForm;