import React from "react";

import { BrowserRouter, Link, Navigate, redirect, Route, Routes } from "react-router-dom";
import AboutClient from "./components/AboutClient";
import BillingData from "./components/BillingData";
import ClientsList from "./components/ClientsList";


import Login from "./components/Login";
import Room from "./components/Room";
import Settings from "./components/Settings";
import SpecialistProfile from "./components/SpecialistProfile";
import SpecialistsList from "./components/SpecialistsList";
import Payment from "./components/survey/Payment";
import SelectSpecialst from "./components/survey/SelectSpecialist";
import Timetable from "./components/Timetable";

import Connections from "./scripts/connection"

import { isExpired, decodeToken } from "react-jwt";
import SpecialistRoom from "./components/SpecialistRoom";
import ClientCard from "./components/ClientCard";
import SpecialistCard from "./components/SpecialistCard";
import Loader from "./components/Loader";
import ClientSurvey from "./components/survey/ClientSurvey";
import Entry from "./components/entry/Entry";
import Faq from "./components/Faq";
import MessageModal from "./components/MessageModal";
import BookingSuccess from "./components/BookingSuccess";
import SpecialistBillingData from "./components/SpecialistBillingData";
import CardLinking from "./components/survey/CardLinking";
import FreeClientSurvey from "./components/survey/FreeClientSurvey";
import FreeSelectSpecialist from "./components/survey/FreeSelectSpecialist";
import EmotionsSurvey from "./components/survey/EmotionsSurvey";
import SpecialistsShowcase from "./components/survey/SpecialistsShowcase";
import BaseTemplate from "./components/BaseTemplate";
import MobileEmotionsSurvey from "./components/survey/MobileEmotionsSurvey";
import ScreenSizeWrapper from "./components/survey/ScreenSizeWrapper";
import MobileCheckout from "./components/survey/MobileCheckout";
import TelegramEmotionsSurvey from "./components/survey/TelegramEmotionsSurvey";
import TelegramSpecialistsShowcase from "./components/telegram-app/TelegramSpecialistsShowcase";
import TelegramRoom from "./components/TelegramRoom";
import ClientDiaryView from "./components/ClientDiaryView";


class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      auth: 'checking',
      //auth: 'authed',
     // auth: 'unauthed',

      showMenu: false,
    }
  }

  logout = () => {
    localStorage.removeItem('token');

    this.setState({auth: 'unauthed'})
  }

  checkAuth = () => {
    console.log('test')
    if (!localStorage.getItem('token') || localStorage.getItem('token') === 'undefined')
    {
      this.setState({auth: 'unauthed'});
      return;
    }


    
    Connections.AuthorizePost('/api/v1/auth/status', {
      token: localStorage.getItem('token')
    },
    (result) => {
      console.log(result)
      this.setState({auth: 'authed', page: null, state: result.state});
    },
    (result) => {
      localStorage.setItem('token', undefined)
      this.setState({auth: 'unauthed'});
    },
    (error) => {
      localStorage.setItem('token', undefined);
      this.setState({auth: 'unauthed'});
    })
  }

  componentDidMount() {
    window.Telegram.WebApp.expand();
    // if token exists => save to localstorage
    let url = new URL(window.location.href)
    let query = new URLSearchParams(url.search);
    let token = query.get('token');
    if (token)
    {
      localStorage.setItem('token', token); 
    }

    this.checkAuth();

    let surveyData = localStorage.getItem('survey');
    if (surveyData)
    {
        let survey = JSON.parse(surveyData);
        if (survey.version !== process.env.REACT_APP_SURVEY_VERSION)
          localStorage.removeItem('survey');
    }
    console.log('steup')
  }

  onAuth = () => {
   // this.setState({auth: 'authed'});
    this.checkAuth();
  }


  controlsWindow = () => {

  }

  getUnauthedMenuBar = () => {
    return (
      <div style={{display: 'flex', flexFlow: 'column'}}>
        <a href='/' className="dropdown-content-item body-text-small">Вход</a>
        <a href='/register' className="dropdown-content-item body-text-small">Регистрация</a>
      </div>
    )
  }

  getRegisterMenuBar = () => {
    return (
      <div style={{display: 'flex', flexFlow: 'column'}}>
        <a href='https://t.me/uwio_help' target="_blank" rel="noreferrer" className="dropdown-content-item body-text-small"><img src='images/icons/chatDark.svg'/>Техподдержка</a>
        <span onClick={() => this.setState({showFaq: true})} className="dropdown-content-item body-text-small"><img width={24} src='images/icons/infoDark.svg'/>Вопросы и ответы</span>
        <a href='/' onClick={() => {localStorage.removeItem('token'); this.setState({auth: false})}}  className="dropdown-content-item body-text-small"><img src='images/icons/chevronLeft.svg'/>Выйти</a>
      </div>
    )
  }

  getClientMenuBar = () => {
    return (
      <div style={{display: 'flex', flexFlow: 'column'}}>
        <Link to='/room' className="dropdown-content-item body-text-small"><img src='images/icons/house.svg'/>Главная</Link>
        <Link to='/settings' className="dropdown-content-item body-text-small"><img src='images/icons/settings.svg'/>Мои данные</Link>
        <Link to='/billing' className="dropdown-content-item body-text-small"><img src='images/icons/wb.svg'/>Средства</Link>
        <Link to='/specialists' className="dropdown-content-item body-text-small"><img src='images/icons/usersGroup.svg'/>Специалисты</Link>
        <a href='https://t.me/uwio_help' target="_blank" rel="noreferrer" className="dropdown-content-item body-text-small"><img src='images/icons/chatDark.svg'/>Техподдержка</a>
        <span onClick={() => this.setState({showFaq: true})} className="dropdown-content-item body-text-small"><img width={24} src='images/icons/infoDark.svg'/>Вопросы и ответы</span>
        <a href='/' onClick={() => {localStorage.removeItem('token'); this.setState({auth: false})}}  className="dropdown-content-item body-text-small"><img src='images/icons/chevronLeft.svg'/>Выйти</a>
      </div>
    );
  }

  getSpecialistMenuBar = () => {
    return (
      <div style={{display: 'flex', flexFlow: 'column'}}>
        <Link to='/room' className="dropdown-content-item body-text-small"><img src='images/icons/house.svg'/>Главная</Link>
        <Link to='/profile' className="dropdown-content-item body-text-small"><img src='images/icons/settings.svg'/>Мои данные</Link>
        <Link to='/billing' className="dropdown-content-item body-text-small"><img src='images/icons/wb.svg'/>Средства</Link>
        <Link to='/timetable' className="dropdown-content-item body-text-small"><img src='images/icons/calendar.svg'/>Расписание</Link>
        <Link to='/clients' className="dropdown-content-item body-text-small"><img src='images/icons/usersGroup.svg'/>Клиенты</Link>
        <a href='/' onClick={() => {localStorage.removeItem('token'); this.setState({auth: false})}} className="dropdown-content-item body-text-small"><img src='images/icons/chevronLeft.svg'/>Выйти</a>
      </div>
    )
  }

  getMenuBar = () => {
    let result = decodeToken(localStorage.getItem('token'));
    return result.role === 'Specialist' ? this.getSpecialistMenuBar() : this.getClientMenuBar();
  }

  getSpecialistRoutes = () => {
    return [
      <Route key='roomDef' path="/" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<SpecialistRoom />} />}/>,

      <Route key='profile' path="/profile" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<SpecialistProfile />} />}/>,
      <Route key='clients' path="/clients" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<ClientsList />} />}/>,
      <Route key='about' path="/about" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<AboutClient />} />}/>,
      <Route key='tt' path="/timetable" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<Timetable />} />}/>,
      <Route key='room' path="/room" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<SpecialistRoom />} />}/>,
      <Route key='bill' path="/billing" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<SpecialistBillingData />} />}/>,
      <Route key='clientCard' path="/client" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<ClientCard />} />}/>,
      <Route key='diaryView' path="/diary" element={<ClientDiaryView />}/>
    ]
  }

  getClientRoutes = () => {
    let clientRoutes = [
      <Route key='sett' path="/settings" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<Settings />} />}/>,
      <Route key='spec' path="/specialists" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<SpecialistsList />}/>}/>,
      <Route key='room' path="/room" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<Room />} />}/>,
      <Route key='bill' path="/billing" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<BillingData />} />}/>,
      <Route key='specialist' path='/specialist' element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<SpecialistCard />} />}/>,
      <Route key="faq" path="/faq" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<Faq />}/>}/>,
      <Route key="success" path="/success" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<BookingSuccess />} />}/>,
      <Route key="tgsurvey" path="/tgsurvey" element={<TelegramEmotionsSurvey state={1} auth={this.state.auth === 'authed'}/>} />,
      <Route key="tgselect" path="/tgselect" element={<TelegramSpecialistsShowcase state={1} auth={this.state.auth === 'authed'}/>} />,
      <Route key="tgroom" path="/tgroom" element={<TelegramRoom state={this.state.state} auth={this.state.auth === 'authed'}/>} />
    ];

    //clientRoutes.push(<Route key='1sel' path="/payment" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<CardLinking onExpired={() => this.setState({state: 2})} onPaid={() => this.setState({state: 0})}/>}/>}/>);
    clientRoutes.push(<Route key='1sel' path="/payment" element={
      <ScreenSizeWrapper
        mobile={<MobileCheckout state={this.props.state} onExpired={() => this.setState({state: 2})} onPaid={() => this.setState({state: 0})}/>}
        desktop={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<CardLinking onExpired={() => this.setState({state: 2})} onPaid={() => this.setState({state: 0})}/>}/>}
      />}
    />);
    //clientRoutes.push(<Route key='3sel' path="/select" element={<BaseTemplate auth={this.state.auth}  content={<SelectSpecialst onSelected={() => this.setState({state: 3})} state={this.state.state} />}/>}/>);
    //clientRoutes.push(<Route key='2sel' path="/survey" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<ClientSurvey state={this.state.state} onSurveyComplete={() => this.state.state != 3 ? this.setState({state: 2}) : undefined} />}/>}/>);
    clientRoutes.push(<Route key='survey' path='/survey' element={
      <ScreenSizeWrapper
        mobile={<MobileEmotionsSurvey logout={this.logout} state={this.state.state} auth={this.state.auth === 'authed'}/>}
        desktop={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<EmotionsSurvey logout={this.logout} state={this.state.state} auth={this.state.auth === 'authed'}/>}/>}
        />
    }
    />);

    clientRoutes.push(<Route key='select' path='/select' element={
      <ScreenSizeWrapper 
        desktop={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<FreeSelectSpecialist auth={this.state.auth === 'authed'} state={this.state.state} onAuth={() => this.onAuth()} onSelected={() => this.setState({state: 3, auth: 'authed'})} />}/>}
        mobile={<SpecialistsShowcase state={this.state.state} auth={this.state.auth} onAuth={() => this.onAuth()} onSelected={() => this.setState({state: 3, auth: 'authed'})}/>}
      />
    }
    />);

    if (this.state.state === 3)
    {
      clientRoutes.push(<Route key='7sel' path="/" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<CardLinking onExpired={() => this.setState({state: 2})} onPaid={() => this.setState({state: 0})}/>}/>}/>);

    }
    else if (this.state.state === 0)
      clientRoutes.push(<Route key='8sel' path="/" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<Room />}/>}/>);
    else if (this.state.state === 2)
    {
      clientRoutes.push(<Route key='9sel' path="/" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<SelectSpecialst onSelected={() => this.setState({state: 3})} state={this.state.state} />}/>}/>);

    }
    else
    {
      clientRoutes.push(<Route key='22sel' path="/" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<ClientSurvey state={this.state.state} onSurveyComplete={() => this.setState({state: 2})} />}/>}/>);
    }

    return clientRoutes;
  }

  getRoutes() {
    if (this.state.auth === 'authed')
    {

      let url = new URL(window.location.href)
      let query = new URLSearchParams(url.search);
      let token = query.get('token');

      let result = decodeToken(localStorage.getItem('token') ?? token) ;
      let routes = result.role === 'Specialist' ? this.getSpecialistRoutes() : this.getClientRoutes();

      routes.push(<Route key='l'  path='*' element={<Navigate to='/'/>}/>)
      
      return routes;
    }
    else if (this.state.auth === 'unauthed')
    {
      return [
        <Route key='login4' path="/" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<Entry onAuth={() => this.onAuth()}/>} />}/>,
        /*<Route key='survey' path="/survey" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<EmotionsSurvey />}/>}/>,
        <Route key='select' path="/select" element={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<FreeSelectSpecialist onAuth={() => this.onAuth()} onSelected={() => this.setState({state: 3, auth: 'authed'})} />}/>}/>,
        <Route key='showcase' path="/showcase" element={<SpecialistsShowcase authed={this.state.auth} onAuth={() => this.onAuth()} onSelected={() => this.setState({state: 3, auth: 'authed'})}/>}/>,
        <Route key='newSurvey' path="/surv" element={<MobileEmotionsSurvey />} />,*/

        <Route key='select' path='/select' element={
          <ScreenSizeWrapper 
            desktop={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<FreeSelectSpecialist onAuth={() => this.onAuth()} onSelected={() => this.setState({state: 3, auth: 'authed'})} />}/>}
            mobile={<SpecialistsShowcase authed={this.state.auth} onAuth={() => this.onAuth()} onSelected={() => this.setState({state: 3, auth: 'authed'})}/>}
          />
        }
        />,

        <Route key='survey' path='/survey' element={
          <ScreenSizeWrapper
            mobile={<MobileEmotionsSurvey />}
            desktop={<BaseTemplate state={this.state.state} auth={this.state.auth}  content={<EmotionsSurvey auth={this.state.auth === 'authed'}/>}/>}
            />
        }
        />
      ]
    }
    
    //return this.getClientRoutes();

  }


  render() {

    if (this.state.auth === 'checking')
      return <div style={{height: '100vh', width: '100wh'}}><Loader /></div>;

    if (this.state.auth === 'error')
      return "Error page";
    
    return (
      <Routes>
        {this.getRoutes().map(item => item)}
      </Routes>
    );
    
  }

}

export default App;
