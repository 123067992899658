import React from "react";



class TelegramFaqBlock extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openedQuestion: 'Q0',
            openedSubQuestion: null
        }
    }

    openQuestion = (questionId) => {
        if (this.state.openedQuestion == questionId)
            questionId = null;
            
        this.setState({openedQuestion: questionId});
    }

    openSubQuestion = (subQuestionId) => {
        if (subQuestionId == this.state.openedSubQuestion)
            subQuestionId = null;

        this.setState({openedSubQuestion: subQuestionId});
    }


    render () {
        
        return <div className="showcase-faq-block">
            <div className="showcase-faq-title">Частые вопросы</div>

            <div className="showcase-faq-part">
                <div className={"showcase-faq-part-title " + (this.state.openedQuestion == 'Q0' ? 'active' : '')}>
                    <div className="show-faq-part-title-box">

                        <span className="showcase-faq-part-title-text">О работе с психологом</span>
                    </div>

                </div>

                {this.state.openedQuestion == 'Q0' ?
                <>
                
                    <div onClick={() => this.openSubQuestion('Q1S1')} className="showcase-faq-question">
                        <div style={this.state.openedSubQuestion == 'Q1S1' ? {border: 'none'} : {}} className="showcase-faq-question-title">
                            <div className="showcase-faq-question-title-text">Мой диалог с психологом конфиденциален?</div>
                            <div>
                                {this.state.openedSubQuestion == 'Q1S1' ?
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3334 10L8.00008 4.66667L2.66675 10" stroke="#3A767B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                :
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.66659 6L7.99992 11.3333L13.3333 6" stroke="#C5D0D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                }
                            </div>
                        </div>
                        
                        {this.state.openedSubQuestion === 'Q1S1' ?
                        <div className="showcase-faq-question-content">
                            Ваша информация защищена на 100% и не передается третьим лицам. Только вы и психолог.
                            Специалисты Uwio подписывают соответствующее соглашение
                        </div>
                        :
                        undefined
                        }
                    </div>

                    <div onClick={() => this.openSubQuestion('Q1S2')} className="showcase-faq-question">
                    <div style={this.state.openedSubQuestion == 'Q1S2' ? {border: 'none'} : {}} className="showcase-faq-question-title">
                            <div className="showcase-faq-question-title-text">Как перенести или отменить сессию с психологом?</div>
                            <div>
                                {this.state.openedSubQuestion == 'Q3S1' ?
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3334 10L8.00008 4.66667L2.66675 10" stroke="#3A767B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                :
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.66659 6L7.99992 11.3333L13.3333 6" stroke="#C5D0D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                }
                            </div>
                        </div>
                        
                        {this.state.openedSubQuestion === 'Q1S2' ?
                        <div className="showcase-faq-question-content">
                            Отменить или перенести сессию вы можете в вашем личном кабинете на панели управления сессиями.
                            <br/><br/>
                            Отмена и перенос сессии возможны более чем за 8 часов до ее начала.
                        </div>
                        :
                        undefined
                        }
                    </div>


              
                </>
                :
                undefined
                }

            </div>


        </div>;
    }
}

export default TelegramFaqBlock;