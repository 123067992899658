import React from "react";

class AboutBlock extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            hidden: true
        }
    }

    getShowHideText = () => {
        if (this.state.hidden)
            return <div onClick={() => this.setState({hidden: !this.state.hidden})} className="showcase-about-block-show">Показать больше</div>;

        return <a href="#specialistAbout"><div onClick={() => this.setState({hidden: !this.state.hidden})} className="showcase-about-block-show">Свернуть</div></a>;
    }

    render () {

        return <div id="specialistAbout" className="showcase-about-block">
            <div className={"showcase-about-block-content " + (this.state.hidden ? 'hidden' : '')}>
                {this.props.text}
            </div>
            {this.getShowHideText()}
        </div>;
    }
}

export default AboutBlock;