import React from "react";


class SpecialistsSlider extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            move: 0,

            sliderLength: 5,

            showTooltip: true
        }
    }

    prevSpecialist = () => {
        if (this.state.move > 0)
            this.setState({move: this.state.move - 1});
    }

    nextSpecialist = () => {
        if (this.state.move < this.props.specialists.length - this.props.sliderLength)
            this.setState({move: this.state.move + 1});
    }

    getSpecialists = () => {
        let showSpecs = [];
        for (let i = this.state.move, j = 0; j < this.props.sliderLength && j < this.props.specialists.length; j++, i++)
            showSpecs.push(this.props.specialists[i]);

        return showSpecs.map((spec, index) => {
            let style = {borderRadius: '8px',  cursor: 'pointer', boxSizing: 'border-box'};
            if (spec.id == this.props.selectedSpec)
                style.border = '2px solid #3A767B';
            return <img style={style} 
                        height={48} 
                        width={48} 
                        key={index} 
                        src={spec.photo ? 'avatars/' + spec.photo : "images/avatars/8.png"}
                        onClick={() => this.props.onSelect(spec.id)}
                        className={'slider-image'}
                        />;
        })
    }

    render() {
        return (
            <div className={this.props.mode}>
                <div className="tooltipselect" onClick={() => this.setState({showTooltip: false})}>
                    <div className="person-selector-variants">
                        {this.getSpecialists()}
                        {this.props.specialists.length - this.props.sliderLength - this.state.move > 0 ?
                        <span className="person-selector-last-pic"></span>
                        :
                        undefined
                        }
                        
                    </div>
                    { this.state.showTooltip ?
                    <div className="tooltiptext">Подобрали специалистов<br/> под ваш запрос</div>
                    :
                    undefined
                    }
                </div>

                {this.props.specialists.length - this.props.sliderLength > 0 ?
                <div className="person-selector-controls">
                    <img style={{cursor: 'pointer'}} onClick={this.prevSpecialist} src="images/icons/arrowLeft.svg"/>
                    <img style={{cursor: 'pointer'}} onClick={this.nextSpecialist} src="images/icons/arrowRight.svg"/>
                </div>
                :
                undefined
                }
            </div>
        )
    }
}

export default SpecialistsSlider;