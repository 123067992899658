import React from "react";


class EducationBlock extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            hidden: true
        }
    }

    getContent = (educations) => {
        if (!educations)
            return [];

        let data = educations;
        if (this.state.hidden)
            data = data.slice(0, 3);

        return data;
    }

    getShowHide = () => {
        if (this.state.hidden)
            return 'Показать все';

        return 'Скрыть'
    }

    render () {

        return <div className="showcase-education-block">
            <div className="showcase-education-header">
                <div className="showcase-title">Образование</div>
                {this.props.education.length > 3 ?
                    <div onClick={() => this.setState({hidden: !this.state.hidden})} className="showcase-education-show">{this.getShowHide()}</div>
                :
                    undefined
                }
            </div>
            <ul>
                {this.getContent(this.props.education).map((x, index) => <li key={index}>{x}</li>)}
            </ul>
        </div>;
    }
}

export default EducationBlock;