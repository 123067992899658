import React from "react";

import { Stack, Button } from "react-bootstrap";

class TestCompletedPanel extends React.Component {

    render () {
        return (
            <Stack gap={3} style={{alignItems: 'center', marginTop: '5rem'}}>
                <div style={{fontWeight: '400',fontSize: '48px', lineHeight: '54px', color: '#1E1E1E'}}>Отлично!</div>
                <div style={{fontWeight: '400',fontSize: '18px', lineHeight: '20px', color: '#1E1E1E', textAlign: 'center', margin: '25px 0'}}>Оборудование успешно проверено</div>
                <Button onClick={() => this.props.onCompleted()} variant="device-test-button" style={{width: '287px'}}>Завершить проверку</Button>
            </Stack>
        )
    }
}

export default TestCompletedPanel;