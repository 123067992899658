import React from "react";
import Category from "./Category";


class CategoriesBlock extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            showCategories: [
               // null,   // 0ffset
                true,
                false,
                false,
                false,
                false
            ],

            selectedCategories: []
        }
    }

    categoriesShowHide = (catNum) => {
        let categoriesShowState = this.state.showCategories;
        categoriesShowState[catNum] = !categoriesShowState[catNum];
        this.setState({showCategories: categoriesShowState});
    }

    onCategorySelect = (category) => {
        let selectedCategories = this.props.selectedCategories;
        let catIndex = selectedCategories.findIndex(item => item.categoryId === category.categoryId && item.subCategoryId == category.subCategoryId);
        if (catIndex !== -1)
        {
            selectedCategories.splice(catIndex, 1);
        }
        else
        {
            selectedCategories.push(category);
        }
        //this.setState({selectedCategories: selectedCategories});
        this.props?.onUpdate(selectedCategories);
    }

    render() {
        return( 
            <div className="content-block">
                <div>
                    <h2 className="reg-block-header">Что хотите обсудить с психологом?</h2>
                    {
                        this.props.categories.map((item, index) => 
                            <Category 
                                key={index} 
                                show={this.state.showCategories[index]} 
                                content={item} 
                                onShowHide={() => this.categoriesShowHide(index)}
                                onSelect={(item) => this.onCategorySelect(item)}
                                selectedCategories={this.props.selectedCategories}
                                />
                        )
                    }

                </div>
            </div>
        );
    }
}

export default CategoriesBlock;