import React from "react";

import { Stack } from "react-bootstrap";

class TestPanelRoute extends React.Component {

    render () {

        return (
            <Stack direction="horizontal" style={{display: 'flex', justifyContent: 'center'}}>
            <Stack style={{maxWidth: '40px'}} gap={2}>
                <div className="device-check-step step-block-content step-active">
                    <div>1</div>
                </div>
                <div className="step-block-content">
                    Звук
                </div>
            </Stack>
            
            <div className='device-check-step-border'/>
            
            <Stack style={{maxWidth: '40px'}} gap={2}>
                <div className={"device-check-step step-block-content" + (this.props.step === 1 || this.props.step === 2 ? " step-active" : "")}>
                    <div>2</div>
                </div>
                <div className="step-block-content">
                    Микрофон
                </div>
            </Stack>                        
            <div className='device-check-step-border'/>

            <Stack style={{maxWidth: '40px'}} gap={2}>
            <div className={"device-check-step step-block-content" + (this.props.step === 2 ? " step-active" : "")}>
                    <div>3</div>
                </div>
                <div className="step-block-content">
                    Камера
                </div>
            </Stack>
        </Stack>
        );
    }
}

export default TestPanelRoute;