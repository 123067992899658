import React from "react";
import connection from "../../scripts/connection";
import utils from "../../scripts/utils";
import CodeForm from "./CodeForm";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";


class RegisterModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            codeInput: false
        }
    }

    componentDidMount () {
        document.body.style.overflow = 'hidden'

    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto'
    }

    sendStats = (phone) => {
        connection.SendStats({
            registerEventData: {
                browserId: utils.GetBrowserId(),
                phoneNumber: phone
            }
        })
    }


    render() {

        return <div className="modal-block" onClick={() => this.props.onHide()}>
        <div className="booking-modal body-text-small" onClick={(e) => e.stopPropagation()}>
           <div className="booking-modal-close" style={{position: 'absolute', right: '20px', cursor: 'pointer'}}  onClick={(e) => this.props.onHide()}>X</div>
                {!this.state.codeInput ?
                <RegisterForm 
                    subcategoriesId={this.props.subcategoriesId}
                    preferredGender={this.props.preferredGender}
                    timezoneOffset={this.props.timezoneOffset}
                    timePeriods={this.props.timePeriods}
                    onRegistered={(phone) => this.setState({codeInput: true, phone: phone}, () => this.sendStats(phone))}
                />
                :
                <div className="reg-code-wrapper">
                <CodeForm phone={this.state.phone}
                        onAuth={this.props.onAuth}
                />
                </div>
                }
            </div>
        </div>;
    }

}

export default RegisterModal;