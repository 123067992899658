import React from "react";


class TherapyMethodsBlock extends React.Component {


    render () {

        return <div className="showcase-therapy">
            <div className="showcase-title therapy">Методы терапии</div>
            <div className="showcase-therapy-content">

                {this.props.methods.map((x, index) => <div key={index} className="showcase-therapy-item">
                    <span>{x.text}</span>
                    {x.description ?
                    <img onClick={() => this.props.onShow(x.description)} className="showcase-therapy-info-icon" src="images/icons/info.svg" alt="i"/>
                    :
                    null
                    }
                    
                </div>)}

            </div>
        </div>;
    }

}

export default TherapyMethodsBlock;