import React from "react";
import { decodeToken } from "react-jwt";
import connection from "../scripts/connection";
import Chat from "./Chat";
import Loader from "./Loader";
import { Navigate } from "react-router";


class ClientCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
        }
    }

    componentDidMount() {

        connection.Get('/categories',
        (success) => {

            let subcategoriesArray = success.value.map(i => i.subCategories);
            let subcategories = [];

            for (let i = 0; i < subcategoriesArray.length; i++)
                subcategories = subcategories.concat(subcategoriesArray[i]);
            
            let subcategoriesDictionary = {};
            subcategories.forEach(i => subcategoriesDictionary[i.id] = i.title);

            this.setState({
                categories: subcategoriesDictionary,
                categoriesLoaded: true
            })
        },
        (error) => {
            console.log(error);
        },
        (fatalError) => {
            console.log(fatalError);
        });

        let clientId = localStorage.getItem('clientId');
        connection.AuthorizeGet('/specialist/clientprofile?clientId=' + clientId,
        (success) => {
            console.log(success)
            localStorage.setItem('clientName', success.value.name)
            this.setState({client: success.value, loaded: true})
        },
        (error) => {
            console.log(error)
        },
        (fatal) => {
            console.log(fatal)
        })
    }

    consultationTime = (time) => {
        if (!time)
            return 'Не назначена';

        let dt = new Date(time);
        let day = dt.getUTCDate() < 10 ? '0' + dt.getUTCDate() : dt.getUTCDate();
        let month = (dt.getUTCMonth() + 1) < 10 ? '0' + (dt.getUTCMonth() + 1) : (dt.getUTCMonth() + 1);
        let year = dt.getUTCFullYear();

        let minutes = dt.getUTCMinutes() < 10 ? '0' + dt.getUTCMinutes() : dt.getUTCMinutes();
        let hours = dt.getUTCHours() < 10 ? '0' + dt.getUTCHours() : dt.getUTCHours();

        return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;
    }

    getSpecialistId = () => {
        let tokenData = decodeToken(localStorage.getItem('token'));

        return parseInt(tokenData.id);
    }

    render() {
        if (!this.state.loaded || !this.state.categoriesLoaded)
            return <div style={{height: '600px'}}><Loader /></div>;;

        if (this.state.showDiary)
            return <Navigate to='/diary'/>

        return <div className="big-card body-text-small">
            {this.state.showChat ?
            <Chat specialistId={this.getSpecialistId()} clientId={this.state.client.id} receiverId={this.state.client.id} show={this.state.showChat} onHide={() => this.setState({showChat: false})}/> 
            :
            undefined    
            }
            <h2>{this.state.client.name}</h2>
            <div>Возраст: {this.state.client.age ?? 'Не указан'}</div>
            <div>Дата сеанса: {this.consultationTime(this.state.client.date)}</div>
            <div>Проблемы: {this.state.client.categories.map(item => this.state.categories[item]).join(', ')}</div>
            <div style={{marginTop: '1.5rem'}}>
                <button onClick={() => this.setState({showChat: true})} className="button-white payments-button video-button">
                            <img src="images/icons/chat.svg" />
                            <span>Написать сообщение</span>
                </button>
            </div>
            <div style={{marginTop: '1.5rem'}}>
                <button onClick={() => this.setState({showDiary: true})} className="button-white payments-button video-button">    
                    <span>Дневник</span>
                </button>
            </div>
        </div>;
    }
}

export default ClientCard;