import React from "react";
import SliderCard from "../survey/showcase/SliderCard";


class TelegramSliderMobile extends React.Component {



    render () {

        return <div className="onboarding-slider">
            <div className="onboarding-slider-container">

                {this.props.specialists.map((x, index) => <SliderCard key={index} index={index} onSelect={this.props.onSelect} name={x.name} experience={x.experience} photo={x.photoUrl} selected={index === this.props.selectedId}/>)}

            </div>
        </div>;
    }
}

export default TelegramSliderMobile;