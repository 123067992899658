import React from "react";
import PhoneInput from "react-phone-number-input/input";
import Connections from '../scripts/connection';
import Loader from "./Loader";
import MessageModal from "./MessageModal";


class Settings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            age: '',
            name: '',
            phone: '',
            email: '',

            error: false,
            loading: true,

            allowedTimezone: [ -120, -180, -240, -300, -360, -420, -480, -540, -600]
        }
    }

    onErrorOccured = (err) => {
        console.log(err);
        this.setState({error: true});
    }


    componentDidMount() {
        Connections.AuthorizeGet('/client/settings',
        (result) => {
            console.log(result);
            this.setState({
                name: result.value.name,
                age: result.value.age,
                email: result.value.email,
                phone: result.value.phone,
                news: result.value.news,
                phoneNotify: result.value.phoneNotify,
                messageNotify: result.value.messageNotify,
                emailNotify: result.value.emailNotify,
                timezone: result.value.timezone,
                loading: false
            })
        },
        this.onErrorOccured,
        this.onErrorOccured
        );
    }

    saveSettings = () => {
        let packet = {
            name: this.state.name,
            age: this.state.age ? parseInt(this.state.age) : undefined,
            timezone: this.state.timezone,
        }

        Connections.AuthorizePost('/client/setsettings', packet,
        (result) => {
            console.log(result);
            this.setState({message: 'Изменения сохранены', showMessage: true})
        },
        (error) => {
            console.log(error);
            this.setState({message: error.value, showMessage: true})
        },
        (fatalError) => {
            console.log(fatalError);
            this.setState({message: "Сервис временно недоступен. Повторите попытку позже", showMessage: true})
        })
    }

    render() {
        if (this.state.loading)
            return <div style={{height: '600px'}}><Loader /></div>;

        if (this.state.error)
            return <div>Произошла ошибка, повторите попытку позже</div>

        return <div className="big-card">

            {this.state.showMessage ?
                <MessageModal header="Сообщение" message={this.state.message} onHide={() => this.setState({showMessage: false})} />
                :
                undefined
            }

            <h1 className="reg-block-header">Настройки</h1>
            <div className="reg-columns">
                <div className="reg-column-block" style={{marginRight: '1rem', marginBottom: '1rem'}}>
                    <h2 className="settings-input-label">Как вас зовут?</h2>
                    <input value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} className="settings-input" type="text" placeholder="Ваше имя"/>
                </div>
                <div className="reg-column-block">
                    <h2 className="settings-input-label">Ваш возраст</h2>
                    <input value={this.state.age} onChange={(e) => this.setState({age: e.target.value})} className="settings-input" type="text" placeholder="Не указано"/>
                </div>
            </div>
            <div className="divide-line"></div>
            <div className="reg-columns">
                <div className="reg-column-block settings-items" style={{marginRight: '1rem', marginBottom: '1rem'}}>
                    {/*<div>
                        <h2 className="settings-input-label">Номер телефона</h2>
                        <PhoneInput value={this.state.phone} onChange={(e) => this.setState({phone: e})} className="settings-input" type="text" placeholder="+7 (999) 999-99-99"/>
                    </div>
                    <div>
                        <input checked={this.state.news} onChange={(e) => this.setState({news: !this.state.news})} type="checkbox" id="checkbox1"/>
                        <label htmlFor="checkbox1">Получать новости сервиса</label>
                    </div>
                    <div>
                        <input checked={this.state.phoneNotify} onChange={(e) => this.setState({phoneNotify: !this.state.phoneNotify})} type="checkbox" id="checkbox2"/>
                        <label htmlFor="checkbox2">Отправлять SMS-напоминания за час до сеанса</label>
                    </div>
                    <div>
                        <input checked={this.state.messageNotify} onChange={(e) => this.setState({messageNotify: !this.state.messageNotify})} type="checkbox" id="checkbox3"/>
                        <label htmlFor="checkbox3">Уведомление о сообщениях от терапевта</label>
                    </div>*/}
                    <div>
                        <h2 className="settings-input-label">Часовой пояс</h2>
                        <div className="timezone-selector">
                            <select className="settings-timezone-selector" onChange={(e) => this.setState({timezone: e.target.value})} value={this.state.timezone}>
                                {this.state.allowedTimezone.findIndex(item => item == this.state.timezone) == -1 ?
                                    <option value={this.state.timezone}>GMT{this.state.timezone < 0 ? '+' + (this.state.timezone / 60) : '-' + (this.state.timezone / 60)}</option>    
                                :
                                    undefined
                                }
                                <option value={-120}>GMT+2 Калининград</option>
                                <option value={-180}>GMT+3 Москва</option>
                                <option value={-240}>GMT+4 Самара</option>
                                <option value={-300}>GMT+5 Екатеринбург</option>
                                <option value={-360}>GMT+6 Омск</option>
                                <option value={-420}>GMT+7 Новосибирск</option>
                                <option value={-480}>GMT+8 Иркутск</option>
                                <option value={-540}>GMT+9 Чита</option>
                                <option value={-600}>GMT+10 Владивосток</option>
                            </select>
                            <div className="dropdown-arrow">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.7071 10.7071C17.0976 10.3166 17.0976 9.68342 16.7071 9.29289C16.3166 8.90237 15.6834 8.90237 15.2929 9.29289L16.7071 10.7071ZM12 14L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L12 14ZM8.70711 9.29289C8.31658 8.90237 7.68342 8.90237 7.29289 9.29289C6.90237 9.68342 6.90237 10.3166 7.29289 10.7071L8.70711 9.29289ZM15.2929 9.29289L11.2929 13.2929L12.7071 14.7071L16.7071 10.7071L15.2929 9.29289ZM12.7071 13.2929L8.70711 9.29289L7.29289 10.7071L11.2929 14.7071L12.7071 13.2929Z" fill="#C3C9DB"/>
                                </svg>                            
                            </div>
                        </div>
                    </div>
                </div>
                {/*}
                <div className="reg-column-block settings-items">
                    <div>
                        <h2 className="settings-input-label">Email</h2>
                        <input value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} className="settings-input" type="text" placeholder="example@example.example"/>
                    </div>
                    <div>
                        <input checked={this.state.emailNotify} onChange={(e) => this.setState({emailNotify: !this.state.emailNotify})} type="checkbox" id="checkbox4"/>
                        <label htmlFor="checkbox4">Отправлять SMS-напоминания за час до сеанса</label>
                    </div>
                            </div>*/}
            </div>
            <div className="reg-controls">
                <button onClick={this.saveSettings}>Сохранить</button>
            </div>
        </div>;
    }
}


export default Settings;