import React from "react";
import connection from "../scripts/connection";
import Loader from "./Loader";


class DateTimeSelectorOnboarding extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            month: 0,

            dayIndex: 0,
            selectedMonth: 0,

        }

        // props:
        // availablesDays - list of available months
        // month:
        //  startDayOfWeek: 0-6 (From monday to sunday)
        //  days: 31 (days in month)
        //  daysList: [ 1,2,3,4, 15, 27, 36] (Available  days in the month)
        //  monthId: 5 (month num (0-11))
        //  monthBeforeDays: 31 (days in prev month)
        //  Name
        //  Year
    }

    componentDidMount() {
        connection.AuthorizeGet('/booking/SpecialistAvailableTime?specialistId=' + this.props.specialistId,
        (success) => {
            console.log(success)
            if (success.value.length == 0)
                this.setState({noWorkTime: true})
            else
                this.setState({dates: success.value})
        },
        (error) => {
            console.log(error);
        },
        (fatal) => {
            console.log(fatal)
        })
    }

    getCalendar = () => {
        let calendarMonth = this.getCalendarMonths(this.state.dates);
        
        console.log('Calendar month', calendarMonth)

        let currentCalendar = calendarMonth[this.state.month];

        let prevMonthAvailableDays = 0;
        if (this.state.month !== 0)
        {
            prevMonthAvailableDays = calendarMonth[this.state.month - 1].days.length;
        }

        let days = [];

        for (let i = 0; i < currentCalendar.starts; i++)
            days.push({ type: ' calendar-disabled', value: currentCalendar.prevMonthDays - currentCalendar.starts + i + 1})


        for (let i = 0; i < currentCalendar.daysCount; i++)
            days.push({type: ' calendar-disabled', value: i + 1});


        for (let i = days.length, j = 0; i < 42; i++, j++)
            days.push({type: ' calendar-disabled', value: j + 1});

        currentCalendar.days.forEach( (item, index) => {
            days[item.day + currentCalendar.starts - 1].type = ''
            days[item.day + currentCalendar.starts - 1].func = () => { console.log(item.date, index, this.state.selectedMonth); this.setState({dayIndex: index + prevMonthAvailableDays, value: undefined, selectedMonth: this.state.month})};
            days[item.day + currentCalendar.starts - 1].styles = this.state.month === this.state.selectedMonth && this.state.dayIndex === index + prevMonthAvailableDays ? {background: '#3F67F4', color: 'white'} : undefined;
        })

        let nextMonth = calendarMonth[this.state.month + 1];
        let nextMonthStartCalendarPosition = currentCalendar.daysCount + currentCalendar.starts;
        if (nextMonth && nextMonthStartCalendarPosition < 41)
        {
            for (let nextMonthDay = 1, currentPosition = nextMonthStartCalendarPosition; currentPosition < 42; nextMonthDay++, currentPosition++)
            {
                let dayIndex = nextMonth.days.findIndex(day => day.day == nextMonthDay);
                if (dayIndex !== -1)
                {
                    days[currentPosition].type = '';
                    days[currentPosition].func = () => { console.log(nextMonth.days[dayIndex].date, dayIndex, this.state.month + 1); this.setState({dayIndex: currentCalendar.days.length + dayIndex, value: undefined, selectedMonth: this.state.month + 1})};
                    days[currentPosition].styles = (this.state.month + 1) === this.state.selectedMonth && this.state.dayIndex === (dayIndex + currentCalendar.days.length) ? {background: '#3F67F4', color: 'white'} : undefined;
                }
            }
        }


        return { title: currentCalendar.title, days: days};
    }

    getCalendarMonths = (dates) => { 
        let months = [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

        let availableDates = [];
        dates = dates.map(item => new Date(item.date));

        dates.forEach(item => {
            item = new Date(item)
            if (!availableDates.find(avDt => avDt.year === item.getFullYear() && avDt.month === item.getMonth()))
            {
                availableDates.push({
                    year: item.getFullYear(), 
                    month: item.getMonth(), 
                    starts: (new Date(item.getFullYear(), item.getMonth()).getDay() + 6) % 7,
                    prevMonthDays: new Date(new Date(item.getFullYear(), item.getMonth()) - 1).getDate(),
                    daysCount: new Date(new Date(item.getFullYear(), item.getMonth()+1) - 1).getDate()
                });
            }
        });

        let calendarMonth = availableDates.map((item, index) => {
            let days = dates.filter(dt => dt.getMonth() === item.month && dt.getFullYear() === item.year).map(dt => {
                let month = dt.getMonth() + 1 < 10 ? '0' + (dt.getMonth() + 1): dt.getMonth() + 1;
                let day = dt.getDate() < 10 ? '0' + dt.getDate(): dt.getDate()
                let result = {
                    day: dt.getDate(),
                    date: dt.getFullYear() + '-' + month + '-' + day +  'T00:00:00'
                }
                return result;
            })
            return { title: months[item.month] + ' ' + (item.year % 100), days: days, starts: item.starts, prevMonthDays: item.prevMonthDays, daysCount: item.daysCount};
        })

        return calendarMonth;
    }


    nextMonth = () => {
        if (this.state.month + 1 < this.getCalendarMonths(this.state.dates).length)
            this.setState({month: this.state.month + 1});
    }

    prevMonth = () => {
        if (this.state.month > 0)
            this.setState({month: this.state.month - 1});
    }

    timeSelect = (dayIndex) => {
        let time = this.state.dates[dayIndex].time;
        
        time.sort((a, b) => a > b ? 1 : -1)

        let timeIndex = this.state.value ?? time[0];

        return <select value={timeIndex} onChange={(event) => this.setState({value: event.target.value})}>
            {time.map((item, index) => <option value={item} key={index}>{item < 10 ? '0' + item : item}:00</option>)}
        </select>;
    }

    render() {
        if (!this.props.show)
            return;

        if (this.state.noWorkTime)
            return <div className="modal-block" onClick={() => this.props.onHide()}>
                        <div className="modal-inside body-text-small" onClick={(e) => e.stopPropagation()}>
                            <div className="calendar-header">
                                Сообщение
                            </div>
                            <div className="calendar-content">
                                <div style={{marginBottom: '1rem'}}>К сожалению, специалист сейчас не работает</div>
                                <div style={{display: 'flex', justifyContent: 'right'}}>
                                    <button onClick={() => this.props.onHide()}>Ок</button>
                                </div>
                            </div>
                        </div>
                    </div>;

        let data = null;
        let calendarData = null;
        if (this.state.dates)
        {
            calendarData = this.getCalendar();
            data = calendarData.days;
            console.log(this.state.dayIndex, this.state.month);
        }

        return <div className="modal-block" onClick={() => this.props.onHide()}>
            <div className="modal-inside body-text-small" onClick={(e) => e.stopPropagation()}>
                { !this.state.dates ?
                <Loader />
                :
                <>
            <div className="calendar-header">
                <div>
                    {calendarData.title}
                </div>
                <div className="calendar-controls-block">
                    <span onClick={() => this.prevMonth()} className="calendar-control">
                        {'<'}
                    </span>
                    <span onClick={() => this.nextMonth()} className="calendar-control">
                        {'>'}
                    </span>
                    <span className="calendar-control" onClick={() => this.props.onHide()}>
                        X
                    </span>
                </div>
            </div>
            <div className="calendar-content">
                <div className="calendar-content-row">
                    <span className="calendar-item">Пн</span>
                    <span className="calendar-item">Вт</span>
                    <span className="calendar-item">Ср</span>
                    <span className="calendar-item">Чт</span>
                    <span className="calendar-item">Пт</span>
                    <span className="calendar-item">Сб</span>
                    <span className="calendar-item">Вс</span>
                </div>
                <div className="calendar-content-row">
                    <span onClick={data[0].func} style={data[0].styles} className={"calendar-item calendar-border" + data[0].type}>{data[0].value}</span>
                    <span onClick={data[1].func} style={data[1].styles} className={"calendar-item calendar-border" + data[1].type}>{data[1].value}</span>
                    <span onClick={data[2].func} style={data[2].styles} className={"calendar-item calendar-border" + data[2].type}>{data[2].value}</span>
                    <span onClick={data[3].func} style={data[3].styles} className={"calendar-item calendar-border" + data[3].type}>{data[3].value}</span>
                    <span onClick={data[4].func} style={data[4].styles} className={"calendar-item calendar-border" + data[4].type}>{data[4].value}</span>
                    <span onClick={data[5].func} style={data[5].styles} className={"calendar-item calendar-border" + data[5].type}>{data[5].value}</span>
                    <span onClick={data[6].func} style={data[6].styles} className={"calendar-item calendar-border" + data[6].type}>{data[6].value}</span>
                </div>
                <div className="calendar-content-row">
                    <span onClick={data[7].func} style={data[7].styles} className={"calendar-item calendar-border" + data[7].type}>{data[7].value}</span>
                    <span onClick={data[8].func} style={data[8].styles} className={"calendar-item calendar-border" + data[8].type}>{data[8].value}</span>
                    <span onClick={data[9].func} style={data[9].styles} className={"calendar-item calendar-border" + data[9].type}>{data[9].value}</span>
                    <span onClick={data[10].func} style={data[10].styles} className={"calendar-item calendar-border" + data[10].type}>{data[10].value}</span>
                    <span onClick={data[11].func} style={data[11].styles} className={"calendar-item calendar-border" + data[11].type}>{data[11].value}</span>
                    <span onClick={data[12].func} style={data[12].styles} className={"calendar-item calendar-border" + data[12].type}>{data[12].value}</span>
                    <span onClick={data[13].func} style={data[13].styles} className={"calendar-item calendar-border" + data[13].type}>{data[13].value}</span>
                </div><div className="calendar-content-row">
                    <span onClick={data[14].func} style={data[14].styles} className={"calendar-item calendar-border" + data[14].type}>{data[14].value}</span>
                    <span onClick={data[15].func} style={data[15].styles} className={"calendar-item calendar-border" + data[15].type}>{data[15].value}</span>
                    <span onClick={data[16].func} style={data[16].styles} className={"calendar-item calendar-border" + data[16].type}>{data[16].value}</span>
                    <span onClick={data[17].func} style={data[17].styles} className={"calendar-item calendar-border" + data[17].type}>{data[17].value}</span>
                    <span onClick={data[18].func} style={data[18].styles} className={"calendar-item calendar-border" + data[18].type}>{data[18].value}</span>
                    <span onClick={data[19].func} style={data[19].styles} className={"calendar-item calendar-border" + data[19].type}>{data[19].value}</span>
                    <span onClick={data[20].func} style={data[20].styles} className={"calendar-item calendar-border" + data[20].type}>{data[20].value}</span>
                </div>
                <div className="calendar-content-row">
                    <span onClick={data[21].func} style={data[21].styles} className={"calendar-item calendar-border" + data[21].type}>{data[21].value}</span>
                    <span onClick={data[22].func} style={data[22].styles} className={"calendar-item calendar-border" + data[22].type}>{data[22].value}</span>
                    <span onClick={data[23].func} style={data[23].styles} className={"calendar-item calendar-border" + data[23].type}>{data[23].value}</span>
                    <span onClick={data[24].func} style={data[24].styles} className={"calendar-item calendar-border" + data[24].type}>{data[24].value}</span>
                    <span onClick={data[25].func} style={data[25].styles} className={"calendar-item calendar-border" + data[25].type}>{data[25].value}</span>
                    <span onClick={data[26].func} style={data[26].styles} className={"calendar-item calendar-border" + data[26].type}>{data[26].value}</span>
                    <span onClick={data[27].func} style={data[27].styles} className={"calendar-item calendar-border" + data[27].type}>{data[27].value}</span>
                </div>
                <div className="calendar-content-row">
                    <span onClick={data[28].func} style={data[28].styles} className={"calendar-item calendar-border" + data[28].type}>{data[28].value}</span>
                    <span onClick={data[29].func} style={data[29].styles} className={"calendar-item calendar-border" + data[29].type}>{data[29].value}</span>
                    <span onClick={data[30].func} style={data[30].styles} className={"calendar-item calendar-border" + data[30].type}>{data[30].value}</span>
                    <span onClick={data[31].func} style={data[31].styles} className={"calendar-item calendar-border" + data[31].type}>{data[31].value}</span>
                    <span onClick={data[32].func} style={data[32].styles} className={"calendar-item calendar-border" + data[32].type}>{data[32].value}</span>
                    <span onClick={data[33].func} style={data[33].styles} className={"calendar-item calendar-border" + data[33].type}>{data[33].value}</span>
                    <span onClick={data[34].func} style={data[34].styles} className={"calendar-item calendar-border" + data[34].type}>{data[34].value}</span>
                </div>
                <div className="calendar-content-row">
                    <span onClick={data[35].func} style={data[35].styles} className={"calendar-item calendar-border" + data[35].type}>{data[35].value}</span>
                    <span onClick={data[36].func} style={data[36].styles} className={"calendar-item calendar-border" + data[36].type}>{data[36].value}</span>
                    <span onClick={data[37].func} style={data[37].styles} className={"calendar-item calendar-border" + data[37].type}>{data[37].value}</span>
                    <span onClick={data[38].func} style={data[38].styles} className={"calendar-item calendar-border" + data[38].type}>{data[38].value}</span>
                    <span onClick={data[39].func} style={data[39].styles} className={"calendar-item calendar-border" + data[39].type}>{data[39].value}</span>
                    <span onClick={data[40].func} style={data[40].styles} className={"calendar-item calendar-border" + data[40].type}>{data[40].value}</span>
                    <span onClick={data[41].func} style={data[41].styles} className={"calendar-item calendar-border" + data[41].type}>{data[41].value}</span>
                </div>
            </div>
            <div style={{marginTop: '2rem', marginBottom: '2rem'}}>
                <span>Время: </span>
                {this.timeSelect(this.state.dayIndex)}
            </div>
            <button onClick={() => { console.log(this.state.dayIndex); this.props.onSelect({date: this.state.dates[this.state.dayIndex].date, time: this.state.value ?? this.state.dates[this.state.dayIndex].time[0]})}}>Выбрать</button>
            </>
            }
            </div>
        </div>
    }

}


export default DateTimeSelectorOnboarding;