import React from 'react';
import CodeForm from './CodeForm';
import LoginForm from './LoginForm';


class Entry extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            stage: 0,
        }
    }


    render() {
        console.log(this.props.authed);
        return <div>
        <main className="body-text-small">
            {this.state.stage === 0 ?        
            <LoginForm onCodeSended={(phone) => this.setState({phone: phone, stage: 1})} />
            :
            <CodeForm phone={this.state.phone} onAuth={() => this.props.onAuth()}/>
            }
        </main>
        <footer>

        </footer>
      </div>
    }

}

export default Entry;