import React from "react";


class ShowcaseMenu extends React.Component {

    render () {
        if (!this.props.show)
            return;

        return  <div className="showcase-menu">
                    <a href="#general">
                        <div onClick={this.props.onHide} className="showcase-menu-item">
                            <span>Общая информация</span>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.25 11.6666L9.91667 6.99998L5.25 2.33331" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </a>
                    <a href="#methods">
                        <div onClick={this.props.onHide} className="showcase-menu-item">
                            <span>Методы терапии</span>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.25 11.6666L9.91667 6.99998L5.25 2.33331" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </a>
                    <a href="#education">
                        <div onClick={this.props.onHide} className="showcase-menu-item">
                            <span>Образование</span>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.25 11.6666L9.91667 6.99998L5.25 2.33331" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </a>
                    <a href="#advantages">
                        <div onClick={this.props.onHide} className="showcase-menu-item">
                            <span>Преимущества Uwio</span>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.25 11.6666L9.91667 6.99998L5.25 2.33331" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </a>
                    <a href="#faq">
                        <div onClick={this.props.onHide} className="showcase-menu-item noborder">
                            <span>Частые вопросы</span>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.25 11.6666L9.91667 6.99998L5.25 2.33331" stroke="#BDBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </a>
                </div>;
    }
}

export default ShowcaseMenu;