import React from "react";


class RegisterDecisionModal extends React.Component {


    render() {

        return  <div className="modal-block" onClick={() => this.props.onHide()}>
        <div style={{minHeight: '300px'}} className="booking-modal body-text-small" onClick={(e) => e.stopPropagation()}>
            <div className="register-decision-container">
                <div className="register-decision-inner">
                    <div className="register-decision-title">
                        Вы уже авторизованы. Хотите создать новый аккаунт?
                    </div>

                    <div className="register-decision-block" onClick={() => this.props.onContinue()}>
                        Продолжить как {this.props.name}
                    </div>

                    <div className="register-decision-block" onClick={() => this.props.onCreate()}>
                        Создать новый аккаунт
                    </div>
                </div>
            </div>
        </div>
    </div>;;
    }
}


export default RegisterDecisionModal;