import React from "react";
import Connections from "../../scripts/connection";
import CategoriesBlock from "./CategoriesBlock";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Calendar from "../Calendar"
import TimeSelector from "./TimeSelector";
import PhoneInput from "react-phone-number-input/input";
import Loader from "../Loader";
import { Navigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { decodeToken } from "react-jwt";
import TimezoneSelector from "../TimezoneSelector";


class FreeClientSurvey extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            age: [
                25, 60
            ],
            sex: null,
            lgtqExp: false,

            selectedCategories: [],
            toggle: 2,
            timeType: 1,
            timePeriods: [],

            timezone: new Date().getTimezoneOffset()
        }
           
    }

    errorOccured = (error) => {
        this.setState({error: true});
    }

    async componentDidMount() {
        // Parse the shit
        let searchParams = new URLSearchParams(window.location.search);
        
        let from = searchParams.get("surveyType");
        if (from)
            localStorage.setItem('from', from);


        let cluster = from ?? localStorage.getItem('from');
        console.log('Cluster', cluster);
        let listResult = Connections.Get('/categories/' + (cluster ?? "common"),
            (result) => {
                this.setState({categories: result.value});
            },
            this.errorOccured,
            this.errorOccured
        );
        

        let surveyData = localStorage.getItem('survey');
        if (surveyData)
        {
            let survey = JSON.parse(surveyData);
            this.setState({
                selectedCategories: survey.subcategoriesId,
                sex: survey.preferredGender,
                timezone: survey.timezoneOffset,
                timePeriods: survey.timePeriods
            })
        }
    

        Promise.all([listResult]).then(res => {

            this.setState({loadingComplete: true}, () => {
                let ts = localStorage.getItem('timeSelector');
                if (ts)
                {
                    const element = document.getElementById('time');

                    if (element) {
                        element.scrollIntoView();
                    }
                    localStorage.removeItem('timeSelector');
                }

            });
        });
    }

    validateData = () => {
        let messages = [];


        if (!this.state.selectedCategories || this.state.selectedCategories.length === 0)
            messages.push('Не выбраны категории');

        this.setState({messages: messages})
        return messages.length === 0;
    }


    register = () => {

        let surveyData = {
            subcategoriesId: this.state.selectedCategories,
            preferredGender: this.state.sex,
            timezoneOffset: this.state.timezone,
            timePeriods: this.state.timePeriods
        }

        localStorage.setItem('survey', JSON.stringify(surveyData));

        this.setState({next: true});
    }

    getTimezoneDisplay = (timezone) => {
        return timezone >= 0 ? "UTC-" + timezone / 60 : "UTC+" + (-(timezone / 60));
    }

    toggleTimePeriod = (timePeriod) => {

        if (!this.isTimePeriodActive(timePeriod))
        {
            let timePeriods = this.state.timePeriods;
            timePeriods.push(timePeriod);
            this.setState({timePeriods: timePeriods})
        }
        else
        {
            let timePeriods = this.state.timePeriods.filter(x => x != timePeriod);
            this.setState({timePeriods: timePeriods})
        }
    }

    isTimePeriodActive = (timePeriod) => {
        return this.state.timePeriods.findIndex(x => x == timePeriod) !== -1;
    }


    render() {
        if (this.state.next)
            return <Navigate to='/select'/>

        if (this.state.error)
            return <div>Произошла ошибка, попробуйте позже</div>;

        if (!this.state.loadingComplete)
            return <div style={{height: '600px'}}><Loader /></div>;
     
        return <>
            <div className="reg-steps">
                <div className="reg-steps-item">
                    <span className="step-num step-num-current step-num-active">1</span>
                    <span className="body-text-small reg-num-title">Общие вопросы</span>
                </div>
                
                
                {this.props.state == 2 || this.props.state == 3 ?
                <>
                    <div className="reg-steps-divide reg-steps-divide-type1"></div>

                <Link to='/select' style={{textDecoration: 'none', cursor: 'pointer'}}>

                <div className="reg-steps-item">
                    <span className="step-num step-num-current">2</span>
                    <span className="body-text-small reg-num-title">Выбор специалиста</span>
                </div>
                </Link>
                </>
                :
                <>
                    <div className="reg-steps-divide reg-steps-divide-type2"></div>

                    <div className="reg-steps-item">
                        <span className="step-num">2</span>
                        <span className="body-text-small reg-num-title">Выбор специалиста</span>
                    </div>
                </>
                }   


                {this.props.state === 3 ?
                <>
                    <div className="reg-steps-divide reg-steps-divide-type1"></div>

                    <Link to='/payment'>
                    <div className="reg-steps-item">
                        <span className="step-num step-num-current">3</span>
                        <span className="body-text-small reg-num-title">Запись и оплата</span>
                    </div>
                </Link>
                </>
                :
                <>
                <div className="reg-steps-divide reg-steps-divide-type1"></div>
                    <div className="reg-steps-item">
                        <span className="step-num">3</span>
                        <span className="body-text-small reg-num-title">Запись и оплата</span>
                    </div>
                    </>
                }
            </div>
            <div className="big-card">

                <CategoriesBlock selectedCategories={this.state.selectedCategories} onUpdate={(selectedCategories) => this.setState({selectedCategories: selectedCategories})} categories={this.state.categories} />              

                <div className="divide-line"></div>

                <div>
                    <h2 className="reg-block-header">Что важно в психологе?</h2>
                    
                    <div className="reg-columns">
                        <div className="reg-column-block" style={{marginRight: '1rem'}}>
                            <span className="body-text-large reg-block-header">С кем вам было бы комфортнее работать</span>
                            <span className="body-text-small reg-block-item">
                                <input checked={this.state.sex == null} onChange={() => this.setState({sex: null})} type="checkbox" id="anySex"/> 
                                <label htmlFor="anySex">Пол неважен</label>
                            </span>
                            <span className="body-text-small reg-block-item">
                                <input checked={this.state.sex === "Male"} onChange={() => this.setState({sex: "Male"})} type="checkbox" id="male"/> 
                                <label htmlFor="male">С мужчиной</label>
                            </span>
                            <span className="body-text-small reg-block-item">
                                <input checked={this.state.sex === "Female"} onChange={() => this.setState({sex: "Female"})} type="checkbox" id="female"/> 
                                <label htmlFor="female">С женщиной</label>
                            </span>
                        </div>

                    </div>
                </div>
                
                <div className="divide-line"></div>
                <div>
                        <h2 className="reg-block-header">Выберите время сессии</h2>
                        {<TimezoneSelector timezone={this.state.timezone} onChange={(tz) => this.setState({timezone: tz})}/>}
                        <div className="reg-block-toggle">
                            <div onClick={() => this.setState({timeType: 1, timePeriods: []})} className={"reg-toggle toggle-left" + (this.state.timeType === 1 ? ' toggle-active' : '')}>Ближайшее</div>
                            <div onClick={() => this.setState({timeType: 2})} className={"reg-toggle toggle-right" + (this.state.timeType === 2 ? ' toggle-active' : '')}>Конкретное</div>
                        </div>
                        {this.state.timeType === 1 ?
                            <div style={{marginTop: '18px'}}>Сначала покажем психологов, свободных в ближайшее время</div>
                        :
                        <>
                            <div className="concrete-time-title">Сначала покажем психологов, свободных в указанные вами интервалы времени</div>
                            <div className="concrete-time">
                                <div>
                                    <div className="timeblock-title">Понедельник — пятница:</div>
                                    <div className="timeblock-container">
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekdayMorning")} onChange={() => this.toggleTimePeriod("WeekdayMorning")} type="checkbox" id="time1"/>
                                            <label htmlFor="time1">До 10:00</label>
                                        </div>
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekdayAfternoon")} onChange={() => this.toggleTimePeriod("WeekdayAfternoon")} type="checkbox" id="time2"/>
                                            <label htmlFor="time2">10:00 — 18:00</label>
                                        </div>
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekdayEvening")} onChange={() => this.toggleTimePeriod("WeekdayEvening")} type="checkbox" id="time3"/>
                                            <label htmlFor="time3">После 18:00</label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="timeblock-title">Суббота и воскресенье:</div>
                                    <div className="timeblock-container">
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekendMorning")} onChange={() => this.toggleTimePeriod("WeekendMorning")} type="checkbox" id="time4"/>
                                            <label htmlFor="time4">До 10:00</label>
                                        </div>
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekendAfternoon")} onChange={() => this.toggleTimePeriod("WeekendAfternoon")} type="checkbox" id="time5"/>
                                            <label htmlFor="time5">10:00 — 18:00</label>
                                        </div>
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekendEvening")} onChange={() => this.toggleTimePeriod("WeekendEvening")} type="checkbox" id="time6"/>
                                            <label htmlFor="time6">После 18:00</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                </div>
  
                <div className="divide-line"></div>

                <div className="reg-controls">
                    <button onClick={this.register} className="reg-button">
                        <span className="button-text">Вперед</span>
                        <img src="images/icons/right.svg"/>
                    </button>
                </div>
            </div>
        </>
    }
}

export default FreeClientSurvey;