import React from "react";


class ShowcaseTextModal extends React.Component {

    componentDidMount () {
        document.body.style.overflow = 'hidden'

    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto'
    }

    render () {


        return <div onScroll={e => e.preventDefault()} className="showcase-text-modal">
            <svg onClick={this.props.onClose} className="specialist-video-modal-close" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="35" height="35" rx="4.5" fill="white"/>
                <rect x="0.5" y="0.5" width="35" height="35" rx="4.5" stroke="#F0F0F0"/>
                <path d="M16.6862 18.1L13.1512 21.636C13.0557 21.7282 12.9795 21.8386 12.9271 21.9606C12.8747 22.0826 12.8471 22.2138 12.8459 22.3466C12.8448 22.4794 12.8701 22.6111 12.9204 22.734C12.9706 22.8568 13.0449 22.9685 13.1388 23.0624C13.2327 23.1563 13.3443 23.2305 13.4672 23.2808C13.5901 23.3311 13.7218 23.3564 13.8546 23.3552C13.9874 23.3541 14.1186 23.3265 14.2406 23.2741C14.3626 23.2217 14.4729 23.1455 14.5652 23.05L18.1012 19.515L21.6362 23.05C21.7284 23.1455 21.8388 23.2217 21.9608 23.2741C22.0828 23.3265 22.214 23.3541 22.3468 23.3552C22.4796 23.3564 22.6112 23.3311 22.7341 23.2808C22.857 23.2305 22.9687 23.1563 23.0626 23.0624C23.1565 22.9685 23.2307 22.8568 23.281 22.734C23.3313 22.6111 23.3566 22.4794 23.3554 22.3466C23.3543 22.2138 23.3267 22.0826 23.2743 21.9606C23.2219 21.8386 23.1457 21.7282 23.0502 21.636L19.5152 18.101L23.0502 14.565C23.1457 14.4727 23.2219 14.3624 23.2743 14.2404C23.3267 14.1184 23.3543 13.9872 23.3554 13.8544C23.3566 13.7216 23.3313 13.5899 23.281 13.467C23.2307 13.3441 23.1565 13.2325 23.0626 13.1386C22.9687 13.0447 22.857 12.9705 22.7341 12.9202C22.6112 12.8699 22.4796 12.8446 22.3468 12.8457C22.214 12.8469 22.0828 12.8745 21.9608 12.9269C21.8388 12.9793 21.7284 13.0555 21.6362 13.151L18.1012 16.686L14.5652 13.151C14.4729 13.0555 14.3626 12.9793 14.2406 12.9269C14.1186 12.8745 13.9874 12.8469 13.8546 12.8457C13.7218 12.8446 13.5901 12.8699 13.4672 12.9202C13.3443 12.9705 13.2327 13.0447 13.1388 13.1386C13.0449 13.2325 12.9706 13.3441 12.9204 13.467C12.8701 13.5899 12.8448 13.7216 12.8459 13.8544C12.8471 13.9872 12.8747 14.1184 12.9271 14.2404C12.9795 14.3624 13.0557 14.4727 13.1512 14.565L16.6862 18.101L16.6862 18.1Z" fill="#BDBDBD"/>
            </svg>

            <div className="showcase-text-modal-content">
                {this.props.text}
            </div>
        </div>;
    }
}

export default ShowcaseTextModal;