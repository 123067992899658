import React from "react";
import connection from "../scripts/connection";

import {HttpTransportType, HubConnectionBuilder} from "@microsoft/signalr";
import Chat from "./Chat";
import { OpenVidu } from "openvidu-browser";
import UserVideoComponent from "./video/UserVideoComponent";
import MessageModal from "./MessageModal";
import DateTimeSelector from "./DateTimeSelector";
import Loader from "./Loader";
import ConsultationBookingModal from "./ConsultationBookingModal";
import { decodeToken } from "react-jwt";
import VideoBlockContent from "./VideoBlockContent";
import SelectRoomModal from "./SelectRoomModal";

class Room extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            subscribers: [],

            camShow: true,
            micEnabled: true,
        }
    }

    componentDidMount() {
        this.connectSignalR();

        // Получить данные по специалисту
    }

    componentWillUnmount() {
        this.state.hubConnection?.stop();

        this.state.session?.disconnect()
        this.OV = null;
    }

    connectChatPipeline = () => {
        const chatHubConnection = new HubConnectionBuilder().withUrl(process.env.REACT_APP_API_URL + '/chat', {
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => localStorage.getItem('token')
        }).withAutomaticReconnect().build();

        console.log("mount")

        this.setState({ chatHubConnection }, () => {
            this.state.chatHubConnection
                .start()
                .then(() => {
                    console.log('Connection started!');
                    console.log(this.props.specialistId, this.props.clientId);

                    //this.state.chatHubConnection.invoke('ReceiveChatMessages', this.props.specialistId, this.props.clientId)
                    //.catch(err => console.log(err));

                    // Receive Info about unread messages
                    this.state.chatHubConnection.invoke('ClientUnreadMessage', this.state.specialist?.id, this.getClientId())
                    .catch(err => console.log(err));
                })
                .catch(err => console.log('Error while establishing connection :(', err));


            this.state.chatHubConnection.onreconnecting(error => {

                console.error('Connection lost: ', error);
            });

           
            this.state.chatHubConnection.on('ReceiveMessage', (message) => {

                console.log('Received message');
                if (message.specialistId != this.state.specialist?.id || message.clientId != this.getClientId())
                    return;


                if (!this.state.showChat)
                    this.setState({hasUnreadmessages: true});

                // Chat Id
                // 
            });
        });

    }
    
    connectSignalR = () => {
        const hubConnection = new HubConnectionBuilder().withUrl(process.env.REACT_APP_API_URL + '/room/stream', {
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => localStorage.getItem('token')
        }).withAutomaticReconnect().build();

        this.setState({ hubConnection }, () => {
            this.state.hubConnection
                .start()
                .then(() => {
                    console.log('Connection started!');
                    this.state.hubConnection.invoke('SendAgenda');
                    this.state.hubConnection.invoke('SendActiveConsultations')
                })
                .catch(err => console.log('Error while establishing connection :(', err));


            this.state.hubConnection.onreconnecting(error => {

                console.error('Connection lost: ', error);
            })

            this.state.hubConnection.on('ReceiveToken', (token, expiresIn) => {
                console.log('Received token', token);
                this.connectVideo(token);
            })

            this.state.hubConnection.on('BookingErrorOccured', (errMessage) => {
                this.setState({errorMessage: errMessage, showError: true});
            })

            this.state.hubConnection.on('ReceiveAgenda', (agenda) => {
                console.log("received agenda", agenda)

                this.setState({timeLeft: undefined});
                let roomStartTimer = null;
                if (agenda)
                {
                    let expireDate = new Date();
                    expireDate.setSeconds(expireDate.getSeconds() + agenda.timeLeft)

                    roomStartTimer = setInterval(() => {
                        //let timeLeft = this.state.timeLeft ?? agenda.timeLeft;
                        let timeLeft = (expireDate.getTime() - new Date().getTime()) / 1000;

                        if (timeLeft > 0)
                        {
                            timeLeft--;
                            console.log(timeLeft)
                        }
                        else
                        {
                            clearInterval(this.state.roomStartTimer);
                            this.setState({timeLeft: 0});

                        }
                        
                    }, 1000);
                }

                this.setState({openRoom: true, agenda: agenda, roomStartTimer: roomStartTimer});
            });

            this.state.hubConnection.on('SessionErrorOccured', (error) => {
                console.log("Received session error:", error);
            })

            this.state.hubConnection.on('ReceiveBookingOffer', (offer) => {
                console.log("Received offer:", offer);

                this.setState({offerTime: offer.offerTime, consultationType: offer.consultationType})
            })

            this.state.hubConnection.on('BookingApproved', (offer) => {
                console.log("Booking approved:", offer);

                this.setState({message: "Консультация забронирована"})
            })

            this.state.hubConnection.on('ReceiveActiveConsultations', (cons) => {
                this.setState({consultations: cons})
            })
        });
    
        connection.AuthorizeGet('/client/specialist', 
        (success) => {
            console.log(success)
            this.setState({specialist: success.value}, () => {
                this.connectChatPipeline();
            });

        },
        (error) => {
            console.log(error)
        },
        (fatalError) => {
            console.log(fatalError)
        });

    }

    consultationTime = (time) => {
        let dt = new Date(time);
        let day = dt.getUTCDate() < 10 ? '0' + dt.getUTCDate() : dt.getUTCDate();
        let month = (dt.getUTCMonth() + 1) < 10 ? '0' + (dt.getUTCMonth() + 1) : (dt.getUTCMonth() + 1);
        let year = dt.getUTCFullYear() % 100;

        let minutes = dt.getUTCMinutes() < 10 ? '0' + dt.getUTCMinutes() : dt.getUTCMinutes();
        let hours = dt.getUTCHours() < 10 ? '0' + dt.getUTCHours() : dt.getUTCHours();

        return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;
    }



    roomOpen = (roomId) => {
        this.state.hubConnection.invoke('ConnectConsultation', roomId);
        this.setState({consultationStarted: true});
    }

    deleteSubscriber(streamManager) {
        let subscribers = this.state.subscribers;
        let index = subscribers.indexOf(streamManager, 0);
        if (index > -1) {
            subscribers.splice(index, 1);
            this.setState({
                subscribers: subscribers,
            });
        }
    }


    connectVideo(token) {
        // --- 1) Get an OpenVidu object ---

        this.OV = new OpenVidu();

        // --- 2) Init a session ---

        this.setState(
            {
                session: this.OV.initSession(),
            },
            () => {
                var mySession = this.state.session;

                // --- 3) Specify the actions when events take place in the session ---

                // On every new Stream received...
                mySession.on('streamCreated', (event) => {
                    // Subscribe to the Stream to receive it. Second parameter is undefined
                    // so OpenVidu doesn't create an HTML video by its own
                    var subscriber = mySession.subscribe(event.stream, undefined);
                    var subscribers = this.state.subscribers;
                    //subscribers = [];
                    subscribers.push(subscriber);

                    // Update the state with the new subscribers
                    this.setState({
                        subscribers: subscribers,
                    });
                });

                mySession.on('sessionDisconnected', (event) => {
                    this.OV = null;
                    this.setState({
                        session: undefined,
                        subscribers: [],
                        mySessionId: 'SessionA',
                        myUserName: 'Participant' + Math.floor(Math.random() * 100),
                        mainStreamManager: undefined,
                        publisher: undefined,
                        consultationStarted: false,
                        openRoom: false,
                        agenda: undefined
                    });
            
            
                    this.state.hubConnection.invoke('SendAgenda');
                });


                // On every Stream destroyed...
                mySession.on('streamDestroyed', (event) => {

                    // Remove the stream from 'subscribers' array
                    this.deleteSubscriber(event.stream.streamManager);
                });

                // On every asynchronous exception...
                mySession.on('exception', (exception) => {
                    console.warn(exception);
                });

                // --- 4) Connect to the session with a valid user token ---

                // Get a token from the OpenVidu deployment
 
                    // First param is the token got from the OpenVidu deployment. Second param can be retrieved by every user on event
                    // 'streamCreated' (property Stream.connection.data), and will be appended to DOM as the user's nickname
                mySession.connect(token, {})
                    .then(async () => {

                        // --- 5) Get your own camera stream ---
                        
                        let camId = localStorage.getItem('camId');
                        let micId = localStorage.getItem('micId');
                        
                        // Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
                        // element: we will manage it on our own) and with the desired properties
                        let publisher = await this.OV.initPublisherAsync(undefined, {
                            audioSource: micId ?? undefined, // The source of audio. If undefined default microphone
                            videoSource: camId ?? undefined, // The source of video. If undefined default webcam
                            publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
                            publishVideo: true, // Whether you want to start publishing with your video enabled or not
                            resolution: "640x480", // Считать исходя из разрешения экрана
                            frameRate: 30, // The frame rate of your video
                            insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                            mirror: true, // Whether to mirror your local video or not
                        });

                        // --- 6) Publish your stream ---

                        mySession.publish(publisher);

                        // Obtain the current video device in use
                        var devices = await this.OV.getDevices();
                        var videoDevices = devices.filter(device => device.kind === 'videoinput');
                        var currentVideoDeviceId = publisher.stream.getMediaStream().getVideoTracks()[0].getSettings().deviceId;
                        var currentVideoDevice = videoDevices.find(device => device.deviceId === currentVideoDeviceId);

                        // Set the main video in the page to display our webcam and store our Publisher
                        this.setState({
                            currentVideoDevice: currentVideoDevice,
                            mainStreamManager: publisher,
                            publisher: publisher,
                        });
                    })
                    .catch((error) => {
                        console.log('There was an error connecting to the session:', error.code, error.message);
                    });
            });
    }


    changeVidState = () => {
        let vidState = !this.state.camShow;
        this.state.publisher.publishVideo(vidState);
        this.setState({camShow: vidState});
    }

    changeMicState = () => {
        let micState = !this.state.micEnabled;
        this.state.publisher.publishAudio(micState);
        this.setState({micEnabled: micState});
    }

    acceptOffer = () => {
        const offerTime = this.state.offerTime;
        this.setState({offerTime: undefined});

        console.log(offerTime, this.state.consultationType, this.state);
        this.state.hubConnection.invoke('Booking', offerTime, this.state.agenda.specialistId, this.state.consultationType);
    }

    offerModal = () => {
        return <div className="modal-block">
            <div className="modal-inside body-text-small" onClick={(e) => e.stopPropagation()}>
                <h1>Бронирование консультации</h1>
                <div style={{marginTop: '1rem', marginBottom: '1rem'}}>
                    Специалист предлагает забронировать {this.state.consultationType === 'Double' ? 'парную' : 'индивидуальную'} консультацию на {this.consultationTime(this.state.offerTime)}. Вы согласны?
                </div>
                <div style={{display: 'flex', justifyContent: 'end', gap: '1rem'}}>
                    <button onClick={() => this.setState({offerTime: undefined})}>Нет</button>
                    <button onClick={() => this.acceptOffer()}>Да</button>
                </div>
            </div>
        </div>;
    }

    bookNextConsultation = async (time, consultationType) => {

        let dt = new Date(time.date);

        let selectedTime = new Date(dt.getTime() - dt.getTimezoneOffset() * 60000 + parseInt(time.time) * 3600 * 1000);
        //console.log('SelectedTime: ', selectedTime.toJSON());

        await this.state.hubConnection.invoke('Booking', selectedTime, consultationType);
        await this.state.hubConnection.invoke('SendAgenda');

        this.setState({showBooking: false});
    }

    cancelConsultation = async () => {

        connection.AuthorizePost('/booking/cancelConsultation', {},
        (success) => {
            this.state.hubConnection.invoke('SendAgenda');
        },
        (error) => {
            console.log('Consultation cancelation error:', error);
        },
        (fatalError) => {
            console.log('Consultation cancelation error:', fatalError);
        });
    }

    rescheduleConsultation = async (time, consultationType) => {


        connection.AuthorizePost('/booking/changeSpecialist', 
        { specialistId: this.state.specialist?.id, selectedTime: time , consultationType: consultationType },
        (success) => {
            this.state.hubConnection.invoke('SendAgenda');
        },
        (error) => {
            console.log('Change error occured:', error)
        },
        (fatalError) => {
            console.log('Change error occured:', fatalError)
        });


        this.setState({showReschedule: false});
    }

    leaveSession = () => {
        this.state.session.disconnect();
    }

    getClientId = () => {
        let tokenData = decodeToken(localStorage.getItem('token'));

        return parseInt(tokenData.id);
    }

    bookConsultation = (time) => {
        let tokenData = decodeToken(localStorage.getItem('token'));


        connection.AuthorizePost('/booking/bookconsultation',
        {
            clientId: tokenData.id,
            specialistId: this.state.specialist.id,
            time: time,
            consultationType: this.state.consultationType
        },
        (success) => {
            this.state.hubConnection.invoke('SendAgenda');
            this.setState({showBooking: false})
        },
        (error) => {
            console.log(error)
            this.setState({message: error.value});
        },
        (fatalError) => {
            this.setState({error: true})
        })
    }

    render() {
        if (!this.state.specialist)
            return <div style={{height: '600px'}}><Loader /></div>;

        return <div className="big-card">
                    <div className="dropdown-overlay" onClick={() => this.setState({showMenu: false})} style={this.state.showMenu ? {display: 'block'} : undefined}/>

            {/*this.state.showBooking && this.state.specialist ?
                <DateTimeSelector show={true} specialistId={this.state.specialist?.id} onSelect={(time, consultationType) => this.bookNextConsultation(time, consultationType)} onHide={() => this.setState({showBooking: false})}/>
            :
                undefined
    */}


            {this.state.showBooking && this.state.specialist ?
                <ConsultationBookingModal 
                    specialistId={this.state.specialist.id}
                    //price={this.state.specialist.price}
                    specialistName={this.state.specialist.name}
                    specialistPhotoUrl={this.state.specialist.photo}
                    //onBooked={() => this.setState({payment: true})} 
                    onHide={() => this.setState({showBooking: false})}
                    onSelectedTime={(selectedTime) => this.bookConsultation(selectedTime)}
                    consultationType={this.state.consultationType}
                    consultationTypeSelector={true}
                    onConsultationTypeSelected={(consultationType) => this.setState({consultationType: consultationType})}
                />
            :    
                undefined
            }

            {this.state.showOpenConsultation ?
                <SelectRoomModal 
                    onHide={() => this.setState({showOpenConsultation: false})} 
                    consultations={this.state.consultations} 
                    onConsEnter={(roomId) => {this.setState({showOpenConsultation: false}, () => this.roomOpen(roomId))}}
                />
            :
                undefined
            }


            {this.state.showReschedule ?
                <DateTimeSelector show={true} specialistId={this.state.specialist?.id} onSelect={(time, consultationType) => this.rescheduleConsultation(time, consultationType)} onHide={() => this.setState({showReschedule: false})}/>
            :
                undefined
            }

            { this.state.showChat ?
            <Chat specialistId={this.state.specialist?.id} clientId={this.getClientId()} receiverId={this.state.specialist?.id} show={this.state.showChat} onHide={() => this.setState({showChat: false})}/> 
            :
            undefined
            }

            {this.state.message ?
                <MessageModal onHide={() => this.setState({message: undefined})} message={this.state.message} header={'Информация'}/>
            :
                undefined
            }

            {this.state.offerTime ?
                this.offerModal()
            :
                undefined
            }

            {
                this.state.showError ?
                <MessageModal header="Ошибка" message={this.state.errorMessage} onHide={() => this.setState({errorMessage: undefined, showError: false})}/>
                :
                undefined
            }

            <div className="video-about">
            <div className="video-master">
                <img height={90} width={90} style={{borderRadius: '10px'}} src={this.state.specialist?.photo ? 'avatars/' + this.state.specialist?.photo : "images/avatars/selected.png"}/>
                <div className="video-master-details">
                    <h1>Психолог:<br/> {this.state.specialist?.name} 
                    {this.state.specialist?.telegramLogin ? <a style={{verticalAlign: 'middle', margin: '0 3px'}} href={"https://t.me/" + this.state.specialist.telegramLogin}><img src="images/icons/chatDark.svg"/></a> : undefined } 
                    <img className="mobile-chat-button" src="images/icons/chat.svg" onClick={() => this.setState({showChat: true, hasUnreadmessages: false})}/>
                    </h1>
                    <button onClick={() => this.setState({showChat: true, hasUnreadmessages: false})} className="button-white payments-button video-button top-video-button">
                        <img src="images/icons/chat.svg" />
                        <span>Сообщения</span>
                        {this.state.hasUnreadmessages ? <span className="unread-message-mark"></span> : undefined }
                    </button>
                </div>
            </div>
            
            <div>
            {this.state.agenda === null || this.state.consultationStarted ?
            <button onClick={() => this.setState({showBooking: true})} className="button-white payments-button video-button top-video-button">
                <img src="images/icons/blueCalendar.svg"/>
                <span>Назначить сеанс</span>
            </button>
            :
            <div className="top-video-button">
            <button onClick={() => this.setState({showMenu: true})} className="button-white payments-button video-button ">
                <span>Управление консультацией</span>
            </button>
                <div className="dropdown-content" style={this.state.showMenu ? {display: 'block', right: 'auto'} : undefined} onMouseLeave={() => this.setState({showMenu: false})}>
                    <div style={{display: 'flex', flexFlow: 'column'}}>
                        <span style={{cursor: 'pointer'}} onClick={() => this.setState({showBooking: true})} className="dropdown-content-item body-text-small">Перенести</span>
                        <span style={{cursor: 'pointer'}} onClick={this.cancelConsultation} className="dropdown-content-item body-text-small">Отменить</span>
                    </div>
                </div>
                </div>
            }
            </div>
            
             
            
            </div>

            {this.state.mainStreamManager ? 
                <UserVideoComponent streamManager={this.state.subscribers[this.state.subscribers.length - 1]}
                                    changeCamState={this.changeVidState}
                                    changeMicState={this.changeMicState}
                                    camShow={this.state.camShow}
                                    micEnabled={this.state.micEnabled}
                                    myVideoManager={this.state.publisher}
                                    leaveConsultation={this.leaveSession}
                />
                :
                undefined
            }

            {!this.state.consultationStarted ?
            <VideoBlockContent specialistTelegram={this.state.specialist.telegramLogin} agenda={this.state?.agenda} timeLeft={this.state.timeLeft} roomOpen={() => this.setState({showOpenConsultation: true})}/>
            :
            undefined
            }

            
            <button onClick={() => this.setState({showChat: true})} className="button-white payments-button video-button bottom-video-button">
                <img src="images/icons/chat.svg" />
                <span>Написать сообщение</span>
            </button>
            {this.state.agenda === null || this.state.consultationStarted ?
            <button onClick={() => this.setState({showBooking: true})} className="button-white payments-button video-button bottom-video-button">
                <img src="images/icons/blueCalendar.svg"/>
                <span>Назначить сеанс</span>
            </button>
            :
            <div className="bottom-video-button">
                
                <button style={{width: '100%'}} onClick={() => this.setState({showMenu: true})} className="button-white payments-button video-button">
                    <span>Управление консультацией</span>
                </button>
                <div className="dropdown-content" style={this.state.showMenu ? {display: 'block', right: 'auto'} : undefined} onMouseLeave={() => this.setState({showMenu: false})}>
                    <div style={{display: 'flex', flexFlow: 'column'}}>
                        <span style={{cursor: 'pointer'}} onClick={() => this.setState({showBooking: true})} className="dropdown-content-item body-text-small">Перенести</span>
                        <span style={{cursor: 'pointer'}} onClick={this.cancelConsultation} className="dropdown-content-item body-text-small">Отменить</span>
                    </div>
                </div>
                
            </div>
            }
        </div>;
    }
}

export default Room;