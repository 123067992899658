import React from "react";

import { Stack, Button } from "react-bootstrap";

import DeviceSelector from "../components/DeviceSelector";

class VideoPanelContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            stream: undefined,
            streamInitializing: false,
            failed: false
        }
    }

    componentWillUnmount() {
        this.stopVideoStream();
    }

    startVideoStream = () => {
        if (this.state.streamInitializing)
            return;
        this.setState({streamInitializing: true}, () =>
{
        navigator.mediaDevices?.getUserMedia({video: { deviceId: this.props.selectedDevice.deviceId } }).then(res => {
            this.setState({stream: res, streamInitializing: false, failed: false});

            const video = document.querySelector('video');
            console.log("Video: ", video);

            console.log(res);

            if ("srcObject" in video) {
                video.srcObject = res;
            } else {

                video.src = window.URL.createObjectURL(res);
            }
            video.onloadedmetadata = function(e) {
              video.play();
            };

        }).catch(err => {

            this.setState({failed: true, streamInitializing: false});

            console.log(err);
        });
        });
    }

    stopVideoStream = () => {
        this.state.stream?.getTracks().forEach(track => track.stop());
        this.setState({stream: undefined});
    }


    render() {
        return (
            <Stack direction="horizontal" className="devices-video-check">

                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <div style={{overflow: 'hidden', width: '287px', height: '230px', border: '1px solid #CACACA', 
                                    borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                        
                        <video style={this.state.stream ? {width: '317px', height: '230px'} : {width: '317px', height: '230px', display: 'none'}} playsInline={true}/>
                        
                        <img style={this.state.stream ? {display: 'none'} : null} src="images/icons/Cam.svg" />
                        
                    </div>
                    
                    
                </div>

                <div className="devices-check-cam-block" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', marginTop: '1rem'}}>
                    <Stack gap={3}>

                        {this.state.stream ?
                        <Button onClick={() => { this.stopVideoStream(); }} 
                                variant="device-test-button" 
                                >
                                    <img src="images/icons/power.svg" /> Выключить камеру
                        </Button>
                        :
                        <Button onClick={() => { this.startVideoStream(); this.props.onTestResult(); }} 
                                variant="device-test-button" 
                                >
                                    <img src="images/icons/power.svg" /> Включить камеру
                        </Button>
                        }

                        <div style={{textAlign: 'center'}}>Изображение будет видно только вам</div>
                    </Stack>

                <DeviceSelector iconPath="images/icons/Cam.svg"
                                devices={this.props.cams}
                                selectedDevice={this.props.selectedDevice}
                                onSelectDevice={(device) => {this.stopVideoStream(); this.props.onSelectDevice(device)}}
                                failed={this.state.failed || this.props.failed}
                />

                </div>
            </Stack>
        );
    }

}

export default VideoPanelContent;