import React from "react";
import AboutBlock from "../survey/showcase/AboutBlock";
import AdvantagesBlock from "../survey/showcase/AdvantagesBlock";
import DiscountBannerMobile from "../survey/showcase/DiscountBannerMobile";
import EducationBlock from "../survey/showcase/EducationBlock";
import FaqBlock from "../survey/showcase/FaqBlock";
import OnboardingHeaderMobile from "../survey/showcase/OnboardingHeaderMobile";
import ShowcaseMenu from "../survey/showcase/ShowcaseMenu";
import ShowcaseMenuButton from "../survey/showcase/ShowcaseMenuButton";
import SliderMobile from "../survey/showcase/SliderMobile";
import SpecialistBlock from "../survey/showcase/SpecialistBlock";
import TherapyMethodsBlock from "../survey/showcase/TherapyMethodsBlock";
import connection from "../../scripts/connection";
import Loader from "../Loader";
import { Navigate } from "react-router";
import { decodeToken } from "react-jwt";
import SpecialistVideoModal from "../survey/showcase/SpecialistVideoModal";
import ShowcaseTextModal from "../survey/showcase/ShowcaseTextModal";
import ShowcaseTimeSelectModal from "../survey/showcase/ShowcaseTimeSelectModal";
import MessageModal from "../MessageModal";
import TelegramOnboardingHeaderMobile from "./TelegramOnboardingHeaderMobile";
import TelegramSliderMobile from "./TelegramSliderMobile";
import TelegramSpecialistBlock from "./TelegramSpecialistBlock";
import TelegramAdvantagesBlock from "./TelegramAdvantagesBlock";
import TelegramFaqBlock from "./TelegramFaqBlock";



class TelegramSpecialistsShowcase extends React.Component {

    constructor (props) {
        super(props);
        
        this.state = {

            showMenu: false
        }
    }


    componentDidMount () {
        let interval = setInterval(() => {
            let count = this.state.timeCount ?? -1;
            count++;

            if (count == 1 && !this.state.analyzer)
                this.setState({analyzer: true});

            if (count == 3 && !this.state.finder)
                this.setState({finder: true});

            if (count > 5)
            {
                clearInterval(interval);
            }

            this.setState({timeCount: count});

        }, 1000);

        connection.Get('/api/v1/showcase?timezoneOffset=' + new Date().getTimezoneOffset(),
        (success) => {
            this.setState({
                specialists: success,
                selectedSpecialist: success[0],
                loaded: true
            })
        },
        (error) => {
            console.log(error);
            if (error.value.noSpecialists)
            {
                this.setState({loaded: true, noSpecialists: true});
            }
        },
        (requestError) => {
            console.log(requestError);
        })

    }

    bookConsultation = (time) => {
        let tokenData = decodeToken(localStorage.getItem('token'));

        console.log(JSON.stringify({
            clientId: tokenData.id,
            specialistId: this.state.selectedSpecialist.specialistId,
            time: time,
        }))

        connection.AuthorizePost('/api/v1/clients/' + tokenData.id + '/consultations',
        {
            //clientId: tokenData.id,
            specialistId: this.state.selectedSpecialist.specialistId,
            startTime: time,
        },
        (success) => {
            this.setState({tgClose: true})

            //console.log('zaebis opl4en0');
            // close window
        },
        (error) => {


            // consultation_create_time_gap_expired
            // there_is_no_schedule_unit

            this.setState({booking: false, message: 'Выбранное время более недоступно. Пожалуйста, обновите страницу и выберите другое.'});
        },
        (fatalError) => {

            this.setState({booking: false, message: 'Не удалось забронировать время. Сервис временно недоступен'});
        })
    }

    getSpecialistName = (fullName) => {
        
        return fullName.split(' ')[0];
    }

    specialistSelected = (index) => {
        let specialistCurr = this.state.selectedSpecialist.specialistId;
        this.setState({selectedSpecialist: this.state.specialists[index]})

    }


    render () {
        if (this.state.toSurvey)
            return <Navigate to={"/tgsurvey" + window.location.search} />

        if (this.state.tgClose)
        {
            window.Telegram.WebApp.close()
            return;
        }


        if (!this.state.analyzer)
            return <div style={{height: '600px', position: 'relative'}}><Loader /><div style={{position: 'absolute', top: '65%', width: '100%'}}><div style={{textAlign: 'center'}}>Анализируем ваш запрос</div></div></div>;

    
        if (!this.state.finder)
            return <div style={{height: '600px', position: 'relative'}}><Loader /><div style={{position: 'absolute', top: '65%', width: '100%'}}><div style={{textAlign: 'center'}}>Подбираем подходящих специалистов</div></div></div>;

        if (!this.state.loaded)
            return <div style={{height: '600px'}}><Loader /></div>;


        if (this.state.video)
            return <SpecialistVideoModal url={this.state.selectedSpecialist.videoUrl} onClose={() => this.setState({video: false})}/>;

        if (this.state.booking)
            return <div style={{height: '600px'}}><Loader /></div>;

        return <div className="showcase">


            {this.state.message ?
                <MessageModal show={true} message={this.state.message} header={"Информация"} onHide={() => this.setState({message: null})}/>
                :
                undefined
            }

            {this.state.aboutTherapy ?
                <ShowcaseTextModal text={this.state.aboutTherapy} onClose={() => this.setState({aboutTherapy: null})}/>
                :
                undefined
            }


            {this.state.showTimeSelect   ?
                <ShowcaseTimeSelectModal 
                        specialist={this.state.selectedSpecialist} 
                        timezoneOffset={this.state.timezoneOffset ?? new Date().getTimezoneOffset()} 
                        onTimeSelected={(time) => this.bookConsultation(time)} 
                        onHide={() => {this.setState({showTimeSelect: false}) }}
                />
            :
                undefined
            }

            <TelegramOnboardingHeaderMobile step={2} state={this.props.state}/>

            <TelegramSliderMobile 
                onSelect={(index) => this.specialistSelected(index)} 
                specialists={this.state.specialists} 
                selectedId={this.state.specialists.findIndex(x => x == this.state.selectedSpecialist)}
            />

            <div id="general"/>
            <TelegramSpecialistBlock 
                photo={this.state.selectedSpecialist.photoUrl}
                name={this.state.selectedSpecialist.name}
                experience={this.state.selectedSpecialist.experience}
                price={this.state.selectedSpecialist.price}
                closestTime={this.state.selectedSpecialist.closestTime}
            />

            <div className="problems-block">
                <div style={{marginBottom: '0.5rem'}}>Когда обращаются</div>
                <ul>
                    <li>Проблемы со сном</li>
                    <li>Панические атаки</li>
                    <li>Стресс и депрессивные состояния</li>
                    <li>Тревога и страхи</li>
                </ul>

                {this.state.selectedSpecialist.videoUrl ?
                <button onClick={() => this.setState({video: true})} className="problems-button">
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 7.0622V1L6.25 4.0311L11.5 7.0622L6.25 10.0933L1 13.1244V7.0622Z" stroke="white" strokeWidth="2" strokeLinejoin="round"/>
                    </svg>
                    Смотреть видеопрезентацию
                </button>
                :
                undefined
                }
            </div>

            <AboutBlock text={this.state.selectedSpecialist.about}/>

            <div id="education"/>
            <EducationBlock education={this.state.selectedSpecialist.education.map(x => x.description)}/>

            <div id="advantages"/>
            <TelegramAdvantagesBlock />

            <div id="faq"/>
            <TelegramFaqBlock />

            <div className="showcase-filler"></div>

            <div className="showcase-controls">
                <div className="showcase-controls-content">
                    <button onClick={() => this.setState({showTimeSelect: true}, () => { console.log('hello')})} className="showcase-booking-button">Выбрать время</button>
                </div>
            </div>
        </div>;
    }
}

export default TelegramSpecialistsShowcase;