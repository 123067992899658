import React from "react";
import { isExpired, decodeToken } from "react-jwt";
import connection from "../scripts/connection";
import Loader from "./Loader";
import OkCancelModal from "./OkCancelModal";



class SpecialistBillingData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        connection.AuthorizeGet('/billing/specialistdata',
        (success) => {
            console.log(success)

            this.setState({payments: success.value.payments, loaded: true})
        },
        (error) => console.log(error),
        (fatalError) => console.log(fatalError));
    }


    getStatusDisplay = (status) => {
        if (status === "Booked" || status === "Ongoing")
            return <div className="payments-result payment-status-booked">Запланировано</div>;
        if (status === "Planned")
            return <div className="payments-result payment-status-inprocess">В процессе</div>;
        if (status === "Cancelled")
            return <div className="payments-result payment-status-cancelled">Отменена</div>;
        if (status === "Completed")
            return <div className="payments-result payment-status-success">Проведена</div>;
    }

    getDateDisplay = (date) => {
        date = new Date(date);

        let months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        let days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

        // Время
        // День с месяцем
        // день недели
        let time = date.getUTCHours() + ':' + (date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes());
        let day = date.getUTCDate() + ' ' + months[date.getUTCMonth()];
        let dayOfWeek = days[date.getUTCDay()];


        return day + ' ' + date.getUTCFullYear() + ', ' + time;
    }

    render() {
        if (!this.state.loaded)
            return <Loader />
            

        return <div className="big-card">

            <div>
                <h3 style={{fontSize: '20px'}}>История </h3>
                <table className="payments-list">
                    <thead>
                        <tr>
                            <td className="payments-list-header">Клиент</td>
                            <td className="payments-list-header">Дата сессии</td>
                            <td className="payments-list-header">Сумма</td>
                            <td className="payments-list-header">Статус</td>
                        </tr>
                    </thead>
                    <tbody>

                        {this.state.payments?.map( (item, index) => {
                            return <tr key={index} className="payments-list-item">
                                        <td>
                                            {item.client}
                                        </td>
                                        <td>
                                            {this.getDateDisplay(item.date)}
                                        </td>
                                        <td>{item.price} ₽</td>
                                        <td>
                                            {this.getStatusDisplay(item.paymentStatus)}
                                        </td>
                                    </tr>
                        })
                        }
                   
                    </tbody>
                </table>
            </div>
        </div>;
    }
}

export default SpecialistBillingData;