import React, { Component } from 'react';
import OpenViduVideoComponent from './OvVideo';

export default class UserVideoComponent extends Component {


    render() {
        return this.props.streamManager !== undefined ? (
                    <div className="my-video" style={{position: 'absolute', borderRadius: '14px', right: '15px', top: '5px', zIndex: '1'}}>
                        <OpenViduVideoComponent streamManager={this.props.streamManager} />
                    </div>
                ) 
                : 
            null;
    }
}
