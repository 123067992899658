import React from "react";
import connection from "../scripts/connection";
import { Navigate } from "react-router";
import Loader from "./Loader";


class SpecialistsList extends React.Component {


    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        connection.AuthorizeGet('/booking/specialistlist',
        (success) => {
            console.log(success)
            this.setState({specialists: success.value})
        },
        (error) => {
            console.log(error)
        },
        (fatal) => {
            console.log(fatal)
        })
    }

    showSpecialistProfile = (specialistId) => {
        localStorage.setItem('specialistId', specialistId);
        this.setState({redirect: true})
    }

    render() {
        if (!this.state.specialists)
            return <div style={{height: '600px'}}><Loader /></div>;

        if (this.state.redirect)
            return <Navigate to="/specialist"/>

        return <div className="big-card">
            <h1 className="person-list-header">Наши психологи</h1>
            <div className="person-list">
                {this.state.specialists.map((item, index) => {
                    return <div key={index} className="specialist-info">
                        <img className="person-pic"  width="80" height="80" src={item.photo ? 'avatars/' + item.photo : "images/avatars/selected.png"} />
                        <div className="specialist-info-details">
                            <h2>{item.name}</h2>
                            <p className="person-about specialist-about">
                                {item.about}
                            </p>
                            <span onClick={() => this.showSpecialistProfile(item.id)} className="personlist-details">
                                Перейти к психологу  
                            </span>
                        </div>
                    </div>
                })}

            </div>
        </div>;
    }
}

export default SpecialistsList;