import React from "react";
import SliderCard from "./SliderCard";


class SliderMobile extends React.Component {

    getSpecialistCount = (specs) => {
        if (specs.length == 11 || specs.length == 12 || specs.length == 13 || specs.length == 14)
            return specs.length + ' специалистов';

        let last = specs.length % 10;

        if (last === 1)
            return specs.length + ' специалист';

        if (last < 5 && last != 0)
            return specs.length + ' специалиста';

        return specs.length + ' специалистов';
    }

    render () {


        return <div className="onboarding-slider">
            <div className="onboarding-slider-header">Подобрали <b>{this.getSpecialistCount(this.props.specialists)}</b> под ваш запрос</div>
            <div className="onboarding-slider-container">

                {this.props.specialists.map((x, index) => <SliderCard key={index} index={index} onSelect={this.props.onSelect} name={x.name} experience={x.experience} photo={x.photoUrl} selected={index === this.props.selectedId}/>)}

            </div>
        </div>;
    }
}

export default SliderMobile;