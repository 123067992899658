import React from "react";


class TimezoneSelector extends React.Component {

    // Вывод времени
    // При нажатии показывать селектор
    // при выборе вызывать onUpdate

    constructor(props) {
        super(props);

        let timezonesList = {};
        timezonesList[-120] = "Европа/Калининград (UTC+02:00)";
        timezonesList[-180] = "Европа/Москва (UTC+03:00)";
        timezonesList[-240] = "Европа/Самара (UTC+04:00)";
        timezonesList[-300] = "Азия/Екатеринбург (UTC+05:00)";
        timezonesList[-360] = "Азия/Омск (UTC+06:00)";
        timezonesList[-420] = "Азия/Новосибирск (UTC+07:00)";
        timezonesList[-480] = "Азия/Иркутск (UTC+08:00)";
        timezonesList[-540] = "Азия/Якутск (UTC+09:00)";
        timezonesList[-600] = "Азия/Владивосток (UTC+10:00)";
        timezonesList[-660] = "Азия/Сахалин (UTC+11:00)";
        timezonesList[-720] = "Азия/Камчатка (UTC+12:00)";

        this.state = {
            mode: 0, // 0 - display, 1 - select
            timezones: timezonesList
        }
    }

    getTimezoneDisplay = (timezone) => {
        return timezone >= 0 ? "UTC-" + timezone / 60 : "UTC+" + (-(timezone / 60));
    }


    getDisplay = () => {
        let currentTimezone = this.props.timezone ?? new Date().getTimezoneOffset();

        // Получить наименование часового пояса из справочника
        //let timezoneDisplay = this.state.timezones[currentTimezone] ?? this.getTimezoneDisplay(currentTimezone);

        return <div>Ваш часовой пояс: <div className="timezone">{this.getSelector(currentTimezone)}</div></div>;
    }

    getSelector = (currentTimezone) => {

        let timezones = [];
        Object.keys(this.state.timezones).forEach((tz, index) => {

            timezones.push(<option key={index} value={tz}>{this.state.timezones[tz]}</option>)
        });

        if (!this.state.timezones[currentTimezone])
            timezones.push(<option key={-1} value={currentTimezone}>{this.getTimezoneDisplay(currentTimezone)}</option>);

        return <select value={currentTimezone} className="timezone-select body-text-small" onChange={(tz) => this.props.onChange(tz.target.value)}>
            {timezones}
        </select>
    }

    render() {
        return this.getDisplay();
    }
}

export default TimezoneSelector;