import React from "react";


class Calendar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            month: 0
        }

        // props:
        // availablesDays - list of available months
        // month:
        //  startDayOfWeek: 0-6 (From monday to sunday)
        //  days: 31 (days in month)
        //  daysList: [ 1,2,3,4, 15, 27, 36] (Available  days in the month)
        //  monthId: 5 (month num (0-11))
        //  monthBeforeDays: 31 (days in prev month)
        //  Name
        //  Year
    }

    getCalendar = () => {
        let calendarMonth = this.getCalendarMonths(this.props.dates);
        
        let currentCalendar = calendarMonth[this.state.month];

        console.log(calendarMonth);
        console.log(currentCalendar)

        let days = [];

        for (let i = 0; i < currentCalendar.starts; i++)
            days.push({ type: ' calendar-disabled', value: currentCalendar.prevMonthDays - currentCalendar.starts + i + 1})


        for (let i = 0; i < currentCalendar.daysCount; i++)
            days.push({type: ' calendar-disabled', value: i + 1});


        for (let i = days.length, j = 0; i < 42; i++, j++)
            days.push({type: ' calendar-disabled', value: j + 1});

        currentCalendar.days.forEach(item => {
            days[item.day + currentCalendar.starts - 1].type = ''
            days[item.day + currentCalendar.starts - 1].func = () => { console.log(item.date); this.props.onSelect(item.date)};
        })

        // add next month dates
        let nextMonth = calendarMonth[this.state.month + 1];
        let nextMonthStartCalendarPosition = currentCalendar.daysCount + currentCalendar.starts;
        if (nextMonth && nextMonthStartCalendarPosition < 41)
        {
            for (let nextMonthDay = 1, currentPosition = nextMonthStartCalendarPosition; currentPosition < 42; nextMonthDay++, currentPosition++)
            {
                let day = nextMonth.days.find(day => day.day == nextMonthDay);
                if (day)
                {
                    days[currentPosition].type = '';
                    days[currentPosition].func = () => { console.log(day.date); this.props.onSelect(day.date)};
                }
            }
        }


        return { title: currentCalendar.title, days: days};
    }

    getCalendarMonths = (dates) => { 
        let months = [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

        let availableDates = [];
        dates = dates.map(item => new Date(item.date));

        dates.forEach(item => {
            item = new Date(item)
            if (!availableDates.find(avDt => avDt.year === item.getFullYear() && avDt.month === item.getMonth()))
            {
                availableDates.push({
                    year: item.getFullYear(), 
                    month: item.getMonth(), 
                    starts: (new Date(item.getFullYear(), item.getMonth()).getDay() + 6) % 7,
                    prevMonthDays: new Date(new Date(item.getFullYear(), item.getMonth()) - 1).getDate(),
                    daysCount: new Date(new Date(item.getFullYear(), item.getMonth()+1) - 1).getDate()
                });
            }
        });

        let calendarMonth = availableDates.map((item, index) => {
            let days = dates.filter(dt => dt.getMonth() === item.month && dt.getFullYear() === item.year).map(dt => {
                let month = dt.getMonth() + 1 < 10 ? '0' + (dt.getMonth() + 1): dt.getMonth() + 1;
                let day = dt.getDate() < 10 ? '0' + dt.getDate(): dt.getDate()
                let result = {
                    day: dt.getDate(),
                    date: dt.getFullYear() + '-' + month + '-' + day +  'T00:00:00'
                }
                return result;
            })
            return { title: months[item.month] + ' ' + (item.year % 100), days: days, starts: item.starts, prevMonthDays: item.prevMonthDays, daysCount: item.daysCount};
        })

        return calendarMonth;
    }


    nextMonth = () => {
        if (this.state.month + 1 < this.getCalendarMonths(this.props.dates).length)
            this.setState({month: this.state.month + 1});
    }

    prevMonth = () => {
        if (this.state.month > 0)
            this.setState({month: this.state.month - 1});
    }

    render() {
        if (!this.props.show)
            return;

        let calendarData = this.getCalendar();
        let data = calendarData.days;

        return <div className="modal-block" onClick={() => this.props.onHide()}>
            <div className="modal-inside body-text-small" onClick={(e) => e.stopPropagation()}>
            <div className="calendar-header">
                <div>
                    {calendarData.title}
                </div>
                <div className="calendar-controls-block">
                    <span onClick={() => this.prevMonth()} className="calendar-control">
                        {'<'}
                    </span>
                    <span onClick={() => this.nextMonth()} className="calendar-control">
                        {'>'}
                    </span>
                    <span className="calendar-control" onClick={() => this.props.onHide()}>
                        X
                    </span>
                </div>
            </div>
            <div className="calendar-content">
                <div className="calendar-content-row">
                    <span className="calendar-item">Пн</span>
                    <span className="calendar-item">Вт</span>
                    <span className="calendar-item">Ср</span>
                    <span className="calendar-item">Чт</span>
                    <span className="calendar-item">Пт</span>
                    <span className="calendar-item">Сб</span>
                    <span className="calendar-item">Вс</span>
                </div>
                <div className="calendar-content-row">
                    <span onClick={data[0].func} className={"calendar-item calendar-border" + data[0].type}>{data[0].value}</span>
                    <span onClick={data[1].func} className={"calendar-item calendar-border" + data[1].type}>{data[1].value}</span>
                    <span onClick={data[2].func} className={"calendar-item calendar-border" + data[2].type}>{data[2].value}</span>
                    <span onClick={data[3].func} className={"calendar-item calendar-border" + data[3].type}>{data[3].value}</span>
                    <span onClick={data[4].func} className={"calendar-item calendar-border" + data[4].type}>{data[4].value}</span>
                    <span onClick={data[5].func} className={"calendar-item calendar-border" + data[5].type}>{data[5].value}</span>
                    <span onClick={data[6].func} className={"calendar-item calendar-border" + data[6].type}>{data[6].value}</span>
                </div>
                <div className="calendar-content-row">
                    <span onClick={data[7].func} className={"calendar-item calendar-border" + data[7].type}>{data[7].value}</span>
                    <span onClick={data[8].func} className={"calendar-item calendar-border" + data[8].type}>{data[8].value}</span>
                    <span onClick={data[9].func} className={"calendar-item calendar-border" + data[9].type}>{data[9].value}</span>
                    <span onClick={data[10].func} className={"calendar-item calendar-border" + data[10].type}>{data[10].value}</span>
                    <span onClick={data[11].func} className={"calendar-item calendar-border" + data[11].type}>{data[11].value}</span>
                    <span onClick={data[12].func} className={"calendar-item calendar-border" + data[12].type}>{data[12].value}</span>
                    <span onClick={data[13].func} className={"calendar-item calendar-border" + data[13].type}>{data[13].value}</span>
                </div><div className="calendar-content-row">
                    <span onClick={data[14].func} className={"calendar-item calendar-border" + data[14].type}>{data[14].value}</span>
                    <span onClick={data[15].func} className={"calendar-item calendar-border" + data[15].type}>{data[15].value}</span>
                    <span onClick={data[16].func} className={"calendar-item calendar-border" + data[16].type}>{data[16].value}</span>
                    <span onClick={data[17].func} className={"calendar-item calendar-border" + data[17].type}>{data[17].value}</span>
                    <span onClick={data[18].func} className={"calendar-item calendar-border" + data[18].type}>{data[18].value}</span>
                    <span onClick={data[19].func} className={"calendar-item calendar-border" + data[19].type}>{data[19].value}</span>
                    <span onClick={data[20].func} className={"calendar-item calendar-border" + data[20].type}>{data[20].value}</span>
                </div>
                <div className="calendar-content-row">
                    <span onClick={data[21].func} className={"calendar-item calendar-border" + data[21].type}>{data[21].value}</span>
                    <span onClick={data[22].func} className={"calendar-item calendar-border" + data[22].type}>{data[22].value}</span>
                    <span onClick={data[23].func} className={"calendar-item calendar-border" + data[23].type}>{data[23].value}</span>
                    <span onClick={data[24].func} className={"calendar-item calendar-border" + data[24].type}>{data[24].value}</span>
                    <span onClick={data[25].func} className={"calendar-item calendar-border" + data[25].type}>{data[25].value}</span>
                    <span onClick={data[26].func} className={"calendar-item calendar-border" + data[26].type}>{data[26].value}</span>
                    <span onClick={data[27].func} className={"calendar-item calendar-border" + data[27].type}>{data[27].value}</span>
                </div>
                <div className="calendar-content-row">
                    <span onClick={data[28].func} className={"calendar-item calendar-border" + data[28].type}>{data[28].value}</span>
                    <span onClick={data[29].func} className={"calendar-item calendar-border" + data[29].type}>{data[29].value}</span>
                    <span onClick={data[30].func} className={"calendar-item calendar-border" + data[30].type}>{data[30].value}</span>
                    <span onClick={data[31].func} className={"calendar-item calendar-border" + data[31].type}>{data[31].value}</span>
                    <span onClick={data[32].func} className={"calendar-item calendar-border" + data[32].type}>{data[32].value}</span>
                    <span onClick={data[33].func} className={"calendar-item calendar-border" + data[33].type}>{data[33].value}</span>
                    <span onClick={data[34].func} className={"calendar-item calendar-border" + data[34].type}>{data[34].value}</span>
                </div>
                <div className="calendar-content-row">
                    <span onClick={data[35].func} className={"calendar-item calendar-border" + data[35].type}>{data[35].value}</span>
                    <span onClick={data[36].func} className={"calendar-item calendar-border" + data[36].type}>{data[36].value}</span>
                    <span onClick={data[37].func} className={"calendar-item calendar-border" + data[37].type}>{data[37].value}</span>
                    <span onClick={data[38].func} className={"calendar-item calendar-border" + data[38].type}>{data[38].value}</span>
                    <span onClick={data[39].func} className={"calendar-item calendar-border" + data[39].type}>{data[39].value}</span>
                    <span onClick={data[40].func} className={"calendar-item calendar-border" + data[40].type}>{data[40].value}</span>
                    <span onClick={data[41].func} className={"calendar-item calendar-border" + data[41].type}>{data[41].value}</span>
                </div>
            </div>
            </div>
        </div>
    }

}


export default Calendar;