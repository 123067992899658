import React from "react";


class ShowcaseMenuButton extends React.Component {

    close = () => {

        return <svg style={{padding: '6px'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.31382 5.9L10.8488 2.364C10.9443 2.27176 11.0205 2.16141 11.0729 2.03941C11.1253 1.9174 11.1529 1.78618 11.1541 1.6534C11.1552 1.52062 11.1299 1.38894 11.0796 1.26605C11.0294 1.14315 10.9551 1.0315 10.8612 0.937608C10.7673 0.843715 10.6557 0.769462 10.5328 0.719181C10.4099 0.6689 10.2782 0.643598 10.1454 0.644752C10.0126 0.645906 9.88142 0.673492 9.75942 0.725901C9.63741 0.77831 9.52707 0.854492 9.43482 0.950003L5.89882 4.485L2.36382 0.950003C2.27157 0.854492 2.16123 0.77831 2.03922 0.725901C1.91722 0.673492 1.786 0.645906 1.65322 0.644752C1.52044 0.643598 1.38876 0.6689 1.26587 0.719181C1.14297 0.769462 1.03132 0.843715 0.937425 0.937608C0.843532 1.0315 0.769279 1.14315 0.718998 1.26605C0.668717 1.38894 0.643415 1.52062 0.644569 1.6534C0.645723 1.78618 0.673309 1.9174 0.725718 2.03941C0.778127 2.16141 0.854309 2.27176 0.949819 2.364L4.48482 5.899L0.949819 9.435C0.854309 9.52725 0.778127 9.6376 0.725718 9.7596C0.673309 9.8816 0.645723 10.0128 0.644569 10.1456C0.643415 10.2784 0.668717 10.4101 0.718998 10.533C0.769279 10.6559 0.843532 10.7675 0.937425 10.8614C1.03132 10.9553 1.14297 11.0295 1.26587 11.0798C1.38876 11.1301 1.52044 11.1554 1.65322 11.1543C1.786 11.1531 1.91722 11.1255 2.03922 11.0731C2.16123 11.0207 2.27157 10.9445 2.36382 10.849L5.89882 7.314L9.43482 10.849C9.52707 10.9445 9.63741 11.0207 9.75942 11.0731C9.88142 11.1255 10.0126 11.1531 10.1454 11.1543C10.2782 11.1554 10.4099 11.1301 10.5328 11.0798C10.6557 11.0295 10.7673 10.9553 10.8612 10.8614C10.9551 10.7675 11.0294 10.6559 11.0796 10.533C11.1299 10.4101 11.1552 10.2784 11.1541 10.1456C11.1529 10.0128 11.1253 9.8816 11.0729 9.7596C11.0205 9.6376 10.9443 9.52725 10.8488 9.435L7.31382 5.899V5.9Z" fill="#474747"/>
        </svg>;        
    }

    open = () => {
        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1095_4679)">
                        <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01"/>
                        <path d="M3.97485 5.97485H19.9749" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3.97485 11.9749H19.9749" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3.97485 17.9749H19.9749" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1095_4679">
                    <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>;
    }


    render () {

        return <div onClick={this.props.onClick} className="showcase-controls-menu">
            { this.props.closed ? this.open() : this.close()}
        </div>;
    }
}

export default ShowcaseMenuButton;