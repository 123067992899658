import React from "react";
import { Link } from "react-router-dom";
import Faq from "./Faq";
import MessageModal from "./MessageModal";
import { decodeToken } from "react-jwt";



class BaseTemplate extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            showMenu: false
        }
    }

    getUnauthedMenuBar = () => {
      return (
        <div style={{display: 'flex', flexFlow: 'column'}}>
          <a href='/' className="dropdown-content-item body-text-small">Вход</a>
          <a href='/register' className="dropdown-content-item body-text-small">Регистрация</a>
        </div>
      )
    }
  
    getRegisterMenuBar = () => {
      return (
        <div style={{display: 'flex', flexFlow: 'column'}}>
          <a href='https://t.me/uwio_help' target="_blank" rel="noreferrer" className="dropdown-content-item body-text-small"><img src='images/icons/chatDark.svg'/>Техподдержка</a>
          <span onClick={() => this.setState({showFaq: true})} className="dropdown-content-item body-text-small"><img width={24} src='images/icons/infoDark.svg'/>Вопросы и ответы</span>
          <a href='/' onClick={() => {localStorage.removeItem('token'); this.setState({auth: false})}}  className="dropdown-content-item body-text-small"><img src='images/icons/chevronLeft.svg'/>Выйти</a>
        </div>
      )
    }
  
    getClientMenuBar = () => {
      return (
        <div style={{display: 'flex', flexFlow: 'column'}}>
          <Link to='/room' className="dropdown-content-item body-text-small"><img src='images/icons/house.svg'/>Главная</Link>
          <Link to='/settings' className="dropdown-content-item body-text-small"><img src='images/icons/settings.svg'/>Мои данные</Link>
          <Link to='/billing' className="dropdown-content-item body-text-small"><img src='images/icons/wb.svg'/>Средства</Link>
          <Link to='/specialists' className="dropdown-content-item body-text-small"><img src='images/icons/usersGroup.svg'/>Специалисты</Link>
          <a href='https://t.me/uwio_help' target="_blank" rel="noreferrer" className="dropdown-content-item body-text-small"><img src='images/icons/chatDark.svg'/>Техподдержка</a>
          <span onClick={() => this.setState({showFaq: true})} className="dropdown-content-item body-text-small"><img width={24} src='images/icons/infoDark.svg'/>Вопросы и ответы</span>
          <a href='/' onClick={() => {localStorage.removeItem('token'); this.setState({auth: false})}}  className="dropdown-content-item body-text-small"><img src='images/icons/chevronLeft.svg'/>Выйти</a>
        </div>
      );
    }

    getSpecialistMenuBar = () => {
      return (
        <div style={{display: 'flex', flexFlow: 'column'}}>
          <Link to='/room' className="dropdown-content-item body-text-small"><img src='images/icons/house.svg'/>Главная</Link>
          <Link to='/profile' className="dropdown-content-item body-text-small"><img src='images/icons/settings.svg'/>Мои данные</Link>
          <Link to='/billing' className="dropdown-content-item body-text-small"><img src='images/icons/wb.svg'/>Средства</Link>
          <Link to='/timetable' className="dropdown-content-item body-text-small"><img src='images/icons/calendar.svg'/>Расписание</Link>
          <Link to='/clients' className="dropdown-content-item body-text-small"><img src='images/icons/usersGroup.svg'/>Клиенты</Link>
          <a href='/' onClick={() => {localStorage.removeItem('token'); this.setState({auth: false})}} className="dropdown-content-item body-text-small"><img src='images/icons/chevronLeft.svg'/>Выйти</a>
        </div>
      )
    }
  
    getMenuBar = () => {
      let result = decodeToken(localStorage.getItem('token'));
      return result.role === 'Specialist' ? this.getSpecialistMenuBar() : this.getClientMenuBar();
    }

    render () {

        return <div className="base">
        <div className="dropdown-overlay" onClick={() => this.setState({showMenu: false})} style={this.state.showMenu ? {display: 'block'} : undefined}/>
        <header>
          <div className="top-bar" style={{margin: '0 1rem'}}>
              <a href="https://uwio.ru/"><img src="images/logo.svg"/></a>
              {this.props.auth != 'authed' ?
               <a href='/platform'>
               <div className='entry'>
                   <img src='images/icons/unlock.svg'/>
                   <span>Войти</span>
               </div>
             </a>
             :
              <div className="dropdown" onClick={() => this.setState({showMenu: !this.state.showMenu})}>
                  <img src="images/icons/user.svg"/>
                  <img src="images/icons/dropDown.svg"/>
                  <div className="dropdown-content" style={this.state.showMenu ? {display: 'block'} : undefined} onMouseLeave={() => this.setState({showMenu: false})}>
                    {this.props.state === 0 ?
                      this.props.auth === 'authed' ? this.getMenuBar() : this.getUnauthedMenuBar()
                    :
                      this.props.auth === 'authed' ? this.getRegisterMenuBar(): this.getUnauthedMenuBar()
                    }
                  </div>
              </div>
              }
          </div>
        </header>
        <main className="body-text-small">
            {this.state.showFaq ? <MessageModal className="modal-big" message={<Faq />} header={"Вопросы и ответы"} onHide={() => this.setState({showFaq: false})} /> : undefined }
            {this.props.content}
        </main>
        <footer>

        </footer>
      </div>;
    }
}

export default BaseTemplate;