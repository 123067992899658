import React from "react";

import { Stack} from "react-bootstrap";
import MicroPanelContent from "./MicroPanelContent";
import AlreadyUseContent from "../errorPanels/AlreadyUseContent";
import GetPermissionContent from "../errorPanels/GetPermissionContent";
import NoDeviceContent from "../errorPanels/NoDeviceContent";
import PermissionDeniedContent from "../errorPanels/PermissionDeniedContent";
import UnknownErrorContent from "../errorPanels/UnknowErrorContent";
import TestPanelRoute from "../components/TestPanelRoute";


const VIEW = {
    WaitPermission: 0,
    Test: 1,
    NotFound: 2,
    NotReadable: 3,
    NotAllowed: 4,
    Unknown: 5
}

class MicroTestPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            view: VIEW.WaitPermission
        }
    }

    componentDidMount() {
        this.initMicro();
    }

    initMicro = () => {
        navigator.mediaDevices?.getUserMedia({audio: true }).then(res => 
        {
            res.getTracks().forEach(function(track) {
                track.stop();
            });


            this.getDevices();

            navigator.mediaDevices.ondevicechange = this.getDevices;
            
        }).catch(err => {
            // Нет девайса
            // Не удалось получить доступ
            // Доступ запрещен

            if (err.name == 'NotFoundError')
            {
                // Нет девайса
                this.setState({view: VIEW.NotFound})
            }
            else if (err.name == 'NotReadableError')
            {
                // Already use
                this.setState({view: VIEW.NotReadable})

            }
            else if (err.name == 'NotAllowedError')
            {
                // Проверьте разрешение
                this.setState({view: VIEW.NotAllowed})
            }
            else
            {
                this.setState({view: VIEW.Unknown})
            }

            console.log(err.toString());
        });
    }


    getDevices = () => {
        navigator.mediaDevices?.enumerateDevices().then(result =>
        {
            let audioDevices = result.filter(item => item.kind === 'audioinput');

            let deviceId = localStorage.getItem('micId');
            let selectedDevice = audioDevices.find(x => x.deviceId == deviceId);
            console.log('Saved device:', selectedDevice);

            this.setState({mics: audioDevices, view: VIEW.Test, selectedDevice: selectedDevice ?? audioDevices[0] });

        });
    }

    deviceSelected = (device) => {
        localStorage.setItem('micId', device.deviceId);
        this.setState({selectedDevice: device});

        console.log('Selected device:', device);
    }


    getCurrentView = () => {
        if (this.state.view === VIEW.WaitPermission)
        {
            // Загрузочный экран
            return <GetPermissionContent />
            
        }
        else if (this.state.view === VIEW.Test)
        {
            // Экран проверки
            return <MicroPanelContent mics={this.state.mics}
                                        selectedDevice={this.state.selectedDevice}
                                        onSelectDevice={(device) => this.deviceSelected(device)}
                                        onTestResult={this.props.onTestResult}
                                        failed={this.props.failed}/>;

        }
        else if (this.state.view === VIEW.NotFound)
        {
            // Не найдено устройств
            return <NoDeviceContent onDevicesUpdate={this.initMicro}/>

        }
        else if (this.state.view === VIEW.NotReadable)
        {
            // Устройство уже используется
            return <AlreadyUseContent onDevicesUpdate={this.initMicro}/>
        }
        else if (this.state.view === VIEW.NotAllowed)
        {
            // Доступ запрещен
            return <PermissionDeniedContent onDevicesUpdate={this.initMicro}/>
        }

        return <UnknownErrorContent />
    }

    render() {

        return (
            <Stack gap={5}>
                <TestPanelRoute step={1}/>

                {this.getCurrentView()}

            </Stack>
        );
    }
}

export default MicroTestPanel;