import React, { Component } from 'react';
import OpenViduVideoComponent from './OvVideo';
import MyVideoComponent from './MyVideoComponent';

export default class UserVideoComponent extends Component {


    render() {
       // console.log(this.props.streamManager?.stream.videoDimensions)
       // console.log(this.props.streamManager)
        return  (
                    <div className="streamcomponent" style={{position: 'relative', width: '100%', minHeight: '600px'}}>
                        <div>
                            <MyVideoComponent streamManager={this.props.myVideoManager} />
                        </div>
                        {this.props.streamManager !== undefined ? 
                        <OpenViduVideoComponent streamManager={this.props.streamManager} />
                        :
                        <div className='video-wait-message-block'>
                            <div className='video-message'>Ожидание собеседника...</div>
                        </div>
                        }   
                        <div style={{position: 'absolute', bottom: 0, width: '100%'}}>
                            <div className='consultation-controls'>
                                {!this.props.camShow ?
                                    <img onClick={this.props.changeCamState} style={{cursor: 'pointer'}} src="images/icons/disableCam.svg"/>
                                    :
                                    <img onClick={this.props.changeCamState} style={{cursor: 'pointer'}} src="images/icons/enableCam.svg"/>
                                }
                                <img onClick={this.props.leaveConsultation} style={{cursor: 'pointer'}} src="images/icons/consultationComplete.svg"/>
                                {!this.props.micEnabled ?
                                    <img onClick={this.props.changeMicState} style={{cursor: 'pointer'}} src="images/icons/disableMic.svg"/>
                                    :
                                    <img onClick={this.props.changeMicState} style={{cursor: 'pointer'}} src="images/icons/enableMic.svg"/>
                                }
                            </div>
                        </div>
                    </div>
                ) 
               
    }
}
