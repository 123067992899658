import React from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import connection from "../../scripts/connection";
import { isExpired, decodeToken } from "react-jwt";
import { Navigate } from "react-router";




class CardLinking extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            specialist: {
                photo: null,
                name: 'Гузель Махмедов'
            },
            consultationType: 'type',

            questions: [
                false,
                false,
                false,
                false,
                false,
                false
            ]
        }
    }

    startOvserve = () => {
        let booking = document.getElementById('booking');
        
        let observer = new IntersectionObserver((entries, observer) => {

            entries.forEach(entry => {

                if (entry.isIntersecting)
                {
                    if (this.state.showFloating)
                        this.setState({showFloating: false})
                }
                else
                {
                    if (!this.state.showFloating)
                        this.setState({showFloating: true})
                }
            })
        });

        if (booking != null)
            observer.observe(booking);

        this.setState({observer: observer});
    }


    getBookingInfo = () => {
        connection.AuthorizeGet('/booking/booked',
        (success) => {
            console.log(success);

            let bookingTimer;

            if (success.value.bookExpires >= 0)
            {
                let expireDate = new Date();
                expireDate.setSeconds(expireDate.getSeconds() + success.value.bookExpires)

                bookingTimer = setInterval(() => {
                    //let bookingExpires = this.state.expTime ?? success.value.bookExpires;
                    let bookingExpires = (expireDate.getTime() - new Date().getTime()) / 1000;
                    if (bookingExpires > 0)
                    {
                        bookingExpires--;
                        this.setState({expTime: bookingExpires})
                    }
                    else
                    {
                        clearInterval(bookingTimer);
                        this.setState({bookingTimer: undefined, bookingExpires: 0, bookAlreadyExpired: true});
                    }
                }, 1000);
            }
            

            this.setState({specialist: { name: success.value.specialistName, photo: success.value.specialistPhoto}, 
                expires: success.value.bookExpires,
                consultationDate: success.value.consultationDate,
                payment: success.value.payment,
                expTime: success.value.bookExpires,
                id: success.value.bookId,
                bookingTimer: bookingTimer,
                consultationType: success.value.consultationType
            });

            
        },
        (error) => {
            console.log(error)
            this.setState({bookAlreadyExpired: true, loaded: true})
        },
        (fatalError) => {
            console.log(fatalError)
            this.setState({error: true})
        });
    }

    async componentDidMount() {

        let booking = connection.AuthorizeGet('/booking/booked',
        (success) => {
            console.log(success);

            let bookingTimer;

            if (success.value.bookExpires >= 0)
            {
                let expireDate = new Date();
                expireDate.setSeconds(expireDate.getSeconds() + success.value.bookExpires)

                bookingTimer = setInterval(() => {
                    //let bookingExpires = this.state.expTime ?? success.value.bookExpires;
                    let bookingExpires = (expireDate.getTime() - new Date().getTime()) / 1000;
                    if (bookingExpires > 0)
                    {
                        bookingExpires--;
                        this.setState({expTime: bookingExpires})
                    }
                    else
                    {
                        clearInterval(bookingTimer);
                        this.setState({bookingTimer: undefined, bookingExpires: 0, bookAlreadyExpired: true});
                    }
                }, 1000);
            }
            

            this.setState({specialist: { name: success.value.specialistName, photo: success.value.specialistPhoto}, 
                expires: success.value.bookExpires,
                consultationDate: success.value.consultationDate,
                payment: success.value.payment,
                expTime: success.value.bookExpires,
                id: success.value.bookId,
                bookingTimer: bookingTimer,
                consultationType: success.value.consultationType
            });

            
        },
        (error) => {
            console.log(error)
            this.setState({bookAlreadyExpired: true, loaded: true})
        },
        (fatalError) => {
            console.log(fatalError)
            this.setState({error: true})
        });

        let promo = connection.AuthorizeGet('/promo/activepromo',
        (success) => {
            if (success.value?.code)
                this.setState({promoApplied: true, promo: success.value.code});
        },
        this.errorOccured,
        this.errorOccured
        )

        Promise.all([booking, promo]).then(res => {
            this.setState({loaded: true}, () => this.startOvserve())
        });
    }

    applyPromo = () => {

        connection.AuthorizePost('/promo/applypromo', this.state.promo,
        (success) => {
            console.log(success)
            if (success.value?.success)
            {
                this.setState({promoApplied: true, promoError: false, promoInput: false}, () => this.getBookingInfo())
            }
            else
            {
                this.setState({promoError: true})
            }
        },
        this.errorOccured,
        this.errorOccured
        );
    }

    removePromo = () => {

        connection.AuthorizePost('/promo/removepromo', null,
        (success) => {
            this.setState({promoApplied: false, promo: null}, () => this.getBookingInfo())

        },
        this.errorOccured,
        this.errorOccured);
    }


    parseDate = (date) => {
        let months = ['янв.', 'фев.', 'мар.', 'апр.', 'мая', 'июня', 'июля', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'];
        let days = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];

        let dt = new Date(date);
        let minutes = dt.getUTCMinutes() < 10 ? '0' + dt.getUTCMinutes() : dt.getUTCMinutes();
        
        return dt.getUTCDate() + ' ' + months[dt.getUTCMonth()] + ', ' + days[dt.getUTCDay()] + ', ' +  dt.getUTCHours() + ':' + minutes;
    }

    pay = () => {
        console.log("Pay executed")
        connection.AuthorizePost('/client/CardLinked', {
        },
        (success) => {
            this.setState({paid: true});
        },
        (error) => {
            this.setState({error: true});
        },
        (fatalError) => {
            this.setState({error: true});
        });
    }

    payWidget = () => {
        window._tmr.push({ type: 'reachGoal', id: 3479354, goal: 'payments'});


        let result = decodeToken(localStorage.getItem('token'));
        console.log({
            type: 0, // Card Privyazka
            bookId: this.state.id 
        })
        console.log(this.state)

        var widget = new window.cp.CloudPayments();
        widget.pay('charge', // или 'charge'
            { //options
                publicId: 'pk_fc4a2c2b11763311e816f257f3031', //id из личного кабинета
                description: 'Привязка карты', //назначение
                amount: 1, //сумма
                currency: 'RUB', //валюта
                accountId: result.id, //идентификатор плательщика (необязательно)
                //invoiceId: '1234567', //номер заказа  (необязательно)
                //email: 'user@example.com', //email плательщика (необязательно)
                skin: "mini", //дизайн виджета (необязательно)
                autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
                data: {
                    type: 0, // Card Privyazka
                    bookId: this.state.id 
                }
            },
            {
                onSuccess: this.pay,
                onFail: function (reason, options) { // fail
                    //действие при неуспешной оплате
                },
                onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
                    //например вызов вашей аналитики Facebook Pixel
                }
            }
        )
    }


    calcExpireTime = () => {

        return null;
    }

    toggleQuestion = (questionIndex) => {
        let questions = this.state.questions;
        questions[questionIndex] = !questions[questionIndex];
        this.setState({questions: questions});
    }

    getQuestionActionButton = (questionIndex) => {

        return this.state.questions[questionIndex] ? 
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <path d="M1 1L16 16M16 1L1 16" stroke="#8497A6"/>
                </svg> 
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <path d="M0.786865 10.6064H22.0001M11.3935 -0.000156224V21.213" stroke="#8497A6"/>
                </svg>;
    }

    render() {
        if (!this.state.loaded)
            return <div style={{height: '600px'}}><Loader /></div>;

        if (this.state.paid)
        {
            this.props.onPaid();
            return <Navigate to='/success'/>
        }

        if (this.state.error)
            return <div>Прозишла ошибка, попробуйте позже</div>;

        if (this.state.bookAlreadyExpired)
        {
            console.log('expired')
            this.props.onExpired();
            return <Navigate to='/select'/>
        }

        return <div className="reg-complete-content">
            <div className="reg-steps">
                <Link to='/survey'>
                <div className="reg-steps-item">
                        <span className="step-num step-num-current step-num-active">1</span>
                        <span className="body-text-small reg-num-title">Общие вопросы</span>
                    </div>
                </Link>
                <div className="reg-steps-divide reg-steps-divide-type1"></div>
                <Link to='/select' style={{textDecoration: 'none', cursor: 'pointer'}}>
                <div className="reg-steps-item">
                    <span className="step-num step-num-current step-num-active">2</span>
                    <span className="body-text-small reg-num-title">Выбор специалиста</span>
                </div>
                </Link>
                <div className="reg-steps-divide reg-steps-divide-type1"></div>
                <div className="reg-steps-item">
                    <span className="step-num step-num-current step-num-active">3</span>
                    <span className="body-text-small reg-num-title">Запись и оплата</span>
                </div>
            </div>

            <div className="big-card medium-card payment-card booking-text">
                <div className="booking-content">
                    <div className="booking-about booking-block">
                        <img className="booking-person-pic" width="80" height="80" src={this.state.specialist.photo ? 'avatars/' + this.state.specialist.photo : "images/avatars/selected.png"}/>
                        <div className="booking-about-details">
                            <span className="booking-person-name">{this.state.specialist.name}</span>
                            <span className="booking-selected-date">{this.parseDate(this.state.consultationDate)}</span>
                        </div>
                    </div>
                    <div className="booking-block">
                        <div className="booking-details">
                            <span>Сессия {this.state.consultationType === "Single" ? '55' : '100'} мин</span>
                            <span>{this.state.payment} ₽</span>
                        </div>
                        <div className="booking-details">
                            <span>Промокод</span>
                            {this.state.promoApplied ? <div><span>{this.state.promo}</span><span onClick={this.removePromo} style={{marginLeft: '10px', color: '#3A767B', cursor: 'pointer'}}>Удалить</span></div> : <span onClick={() => this.setState({promoInput: true})} style={{color: '#8497A6', cursor: 'pointer'}}>Добавить</span>}
                        </div>
                        {this.state.promoInput ?<>
                        <div className="booking-details">
                            <input onChange={(e) => {this.setState({promo: e.target.value})}} value={this.state.promo} className="booking-promo-field" type="text" placeholder="Промокод"/>
                            <button onClick={this.applyPromo} className="booking-promo-approve">Ок</button>
                        </div>
                        {this.state.promoError ? <div>Не удалось применить промокод</div> : undefined}
                        </>
                        :
                        undefined
                        }
                        

                        <hr style={{margin: '1rem 0'}} />
                        <div className="booking-details">
                            <span>Итого</span>
                            <span style={{fontWeight: 600}}>{this.state.payment} ₽</span>
                        </div>
                    </div>
                    <div className="booking-block">
                        <div>
                            <div onClick={() => this.toggleQuestion(1)} className="booking-faq-header">
                                <div className="booking-faq-question">Что произойдет после добавления карты?</div>
                                <div>
                                    {this.getQuestionActionButton(1)}
                                </div>
                            </div>
                            {this.state.questions[1] ?
                            <div>
                                После нажатия на кнопку "Добавить карту и записаться", вы будете перенаправлены на защищенную страницу оплаты. После добавления информации о карте и подтверждения смс-кода с вашей карты будет списан и возвращен 1 рубль - так мы проверим работоспособность вашей карты.
                            </div>
                            :
                            undefined
                            }
                        </div>
                        <hr />

                        <div>
                            <div onClick={() => this.toggleQuestion(2)} className="booking-faq-header">
                                <div className="booking-faq-question">Когда будет списана стоимость сессии?</div>
                                <div>
                                    {this.getQuestionActionButton(2)}
                                </div>
                            </div>
                            {this.state.questions[2] ?
                            <div>
                                Стоимость сессии будет списана с вашей карты за 8 часов до ее начала
                            </div>
                            :
                            undefined
                            }
                        </div>
                        <hr />

                        <div>
                            <div onClick={() => this.toggleQuestion(3)} className="booking-faq-header">
                                <div className="booking-faq-question">Могу ли я отменить или перенести сессию?</div>
                                <div>
                                    {this.getQuestionActionButton(3)}
                                </div>
                            </div>
                            {this.state.questions[3] ?
                            <div>
                                Забронированную сессию можно перенести или отменить за 8 часов до ее начала.
                            </div>
                            :
                            undefined
                            }
                        </div>
                        <hr />

                        <div>
                            <div onClick={() => this.toggleQuestion(4)} className="booking-faq-header">
                                <div className="booking-faq-question">Смогу ли я удалить или изменить карту после ее добавления?</div>
                                <div>
                                    {this.getQuestionActionButton(4)}
                                </div>
                            </div>
                            {this.state.questions[4] ?
                            <div>
                                Изменить или удалить добавленную карту Вы всегда можете в личном кабинете.
                            </div>
                            :
                            undefined
                            }
                        </div>
                        <hr />

                       
                    </div>

                    <div id="booking">
                        <button onClick={this.payWidget} className="booking-btn" style={{fontSize: '15px'}}>
                            <img src="images/icons/creditCard.svg"/>
                            <span>Добавить карту и записаться</span>
                        </button>
                       {/* <div style={{marginTop: '15px', textAlign: 'center'}}>
                            Записываясь на сессию, вы соглашаетесь<br /> с договором оказания услуг
                        </div>*/}
                    </div>

                </div>
            </div>

            {this.state.showFloating ?
            <div className="booking-floating">
                <div className="booking-floating-content">
                        <button onClick={this.payWidget} className="booking-btn">
                            <img src="images/icons/creditCard.svg"/>
                            <span>Добавить карту и записаться</span>
                        </button>
                        {/*<div style={{marginTop: '15px', textAlign: 'center'}}>
                            Записываясь на сессию, вы соглашаетесь<br /> с договором оказания услуг
                        </div>*/}
                </div>
            </div>
            :
            undefined
            }

        </div>;
    }

}

export default CardLinking;