import React from "react";

import { Button, Modal, Stack } from "react-bootstrap";

import MicroTestPanel from "./micPanel/MicroTestPanel";
import SoundTestPanel from "./SoundTestPanel";
import TestCompletedPanel from "./TestCompletedPanel";
import VideoTestPanel from "./videoPanel/VideoTestPanel";

class DevicesCheckModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            stage: 0,

            footerHeight: 0,
            showFooter: false,
        }; 
    }

    // TODO Обработка подключение/отключения устройств

    componentDidMount() {
        /*navigator.mediaDevices?.getUserMedia({audio: true, video: true}).then(res => 
        {
            console.log(res.getTracks());
            navigator.mediaDevices?.enumerateDevices().then(result =>
            {
                console.log(result)
                let audioDevices = result.filter(item => item.kind === 'audiooutput');
                console.log(audioDevices);
            });
        });*/

    }

    footerAnimation = () => {
        let maxSize = window.innerWidth > 768 ? 70 : 130;
        let timer = setInterval(() => {
        
            if (this.state.footerHeight < maxSize)
            {
                this.setState({footerHeight: this.state.footerHeight + 5});   
            }
            else
            {
                clearInterval(timer);

                this.setState({showFooter: true})
            }

        }, 10);
    }

    getDesktopFooterButtons = () => {
        if (!this.state.showFooter)
            return;

        let result = this.getStageResult();
        console.log(result);

        return (
            <Stack direction="horizontal" style={{justifyContent: 'space-between', width: '100%'}}>
                <div style={{fontSize: '18px', lineHeight: '20px', color: 'white'}}>
                    {result.message}
                </div>
                <Stack direction="horizontal" gap={3}>
                    <Button variant="devices-test-fail" onClick={() => { this.setState({failed: true})}}>{result.failed}</Button>
                    <Button variant="devices-test-ok" onClick={() => this.setState({showFooter: false, footerHeight: 0, stage: this.state.stage + 1, failed: false})}>{result.success}</Button>
                </Stack>
            </Stack>
        );
    }

    getMobileFooterButtons = () => {
        if (!this.state.showFooter)
            return;

        let result = this.getStageResult();
        console.log(result);

        return (
            <Stack style={{width: '100%'}}>
                <div style={{fontSize: '18px', lineHeight: '20px', color: 'white'}}>
                    {result.message}
                </div>
                <Stack direction="horizontal" gap={3} style={{margin: '1rem 0'}}>
                    <Button variant="devices-test-fail" onClick={() => { this.setState({failed: true})}}>{result.failed}</Button>
                    <Button variant="devices-test-ok" onClick={() => this.setState({showFooter: false, footerHeight: 0, stage: this.state.stage + 1, failed: false})}>{result.success}</Button>
                </Stack>
            </Stack>
        );
    }

    getStageResult = () => {
        if (this.state.stage === 0)
            return {message: 'Вы слышите тестовое сообщение?', failed: 'Нет, не слышу', success: 'Да, слышу'};

        if (this.state.stage === 1)
            return {message: 'Вы слышите записанное сообщение?', failed: 'Нет, не слышу', success: 'Да, слышу'};

        if (this.state.stage === 2)
            return {message: 'Вы видите себя?', failed: 'Нет, не вижу', success: 'Да, вижу'};

        return undefined;
    }


    getTestPanel = () => {

        if (this.state.stage === 0)
            return <SoundTestPanel onTestResult={() => this.footerAnimation()} failed={this.state.failed}/>

        if (this.state.stage === 1)
            return <MicroTestPanel onTestResult={() => this.footerAnimation()} failed={this.state.failed}/>

        if (this.state.stage === 2)
            return <VideoTestPanel onTestResult={() => this.footerAnimation()} failed={this.state.failed}/>

        if (this.state.stage === 3)
            return <TestCompletedPanel onCompleted={() => {this.setState({stage: 0}); this.props.onCompleted()}}/>
    }


    render() {
        return (
            <Modal show={this.props.showDevicesCheck} dialogClassName="devices-modal" contentClassName="devices-content" onHide={() => { this.setState({failed: false, stage: 0, footerHeight: 0, showFooter: false}); this.props.onCompleted()}}>
                <Modal.Header closeButton>
                    <Modal.Title style={{fontSize: '18px', color: '#929292'}}>Проверка оборудования</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div style={{padding: '1rem', minHeight: '429px'}}>
                        
                        {this.getTestPanel()}
                    </div>
                </Modal.Body>

                <Modal.Footer className="devices-footer" style={{height: this.state.footerHeight}}>
                    {window.innerWidth > 768 ? this.getDesktopFooterButtons() : this.getMobileFooterButtons()}
                </Modal.Footer>
            </Modal>
        );
    }

}


export default DevicesCheckModal;