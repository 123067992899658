import React from "react";
import { decodeToken } from "react-jwt";
import connection from "../scripts/connection";
import MessageModal from "./MessageModal";


class Timetable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            workTime: [
                [],
                [],
                [],
                [],
                [],
                [],
                []
            ],
            selectedStartTime: 0,
            selectedEndTime: 0,

            showModal: false,

            active: false,


            weekStartDate: new Date(),
            weekOffset: 0,
        }
    }


    componentDidMount() {
       this.receiveTimetable(0);

    }

    

    receiveTimetable = (weekOffset) => {
        connection.AuthorizeGet('/specialist/worktime?weekOffset=' + weekOffset,
        (result) => {
            console.log(result)

            let weekDays = [[],[],[],[],[],[],[]];

            function addDays(date, days) {
                var result = new Date(date);
                result.setDate(result.getDate() + days);
                return result;
              }

            let dt = result.value.time;
            let res = [];

            for (let i = 0; i < dt.length; i++)
            {
                let current = new Date(dt[i].startTime.replace('Z', ''));
                let endTime = current.getHours() + dt[i].duration;
                res.push({startTime: current, endHours: endTime > 24 ? 24 : endTime})
                
                endTime -= 24;
                let counter = 1;
                while (endTime > 0)
                {
                    res.push({startTime: new Date(addDays(current, counter++).setHours(0)), endHours: endTime > 24 ? 24 : endTime});
                    endTime -= 24;
                }
            }

            console.log(res)

            
            let startDate = new Date(result.value.weekStart.replace('Z', ''));
            let endDate = addDays(startDate, 7);
            for (let i = 0; i < res.length; i++)
            {
                
                let current = res[i].startTime;

                if (current >= startDate && current < endDate)
                {
                    let endTime = res[i].endHours//current.getHours() + dt[i].duration;
                    weekDays[(current.getDay() + 6) % 7].push({startTime: current.getHours(), endTime: endTime});
                   
                }

            }

                      //  this.setState({workTime: result.value.time, active: result.value.active})

            // Разбивать полученный список дат на дни
            // Отсечь по 00:00
            // Раскидать по дням

            // Массив на 168 элементов
            // Отметить все элементы на нем
            // Собрать по 24 штуки
           /* let items = [];
            for (let i = 0; i < 168; i++)
                items.push(false);

            function dateDiffInDays(a, b) {
                const _MS_PER_DAY = 1000 * 60 * 60 * 24;
                // Discard the time and time-zone information.
                const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
                const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
                
                return Math.floor((utc2 - utc1) / _MS_PER_DAY);
            }

            result.value.time.forEach(item => {
                let dt = new Date(item.startTime);
                let index = dateDiffInDays(new Date(result.value.weekStart), dt);
                console.log(dt)
                console.log(result.value.weekStart)
                console.log(index)
                let timeStart = dt.getUTCHours();
                console.log(timeStart);
                for(let i = 0; i < item.duration; i++)
                {
                    items[index * 24 + timeStart + i] = true;
                }
            })

            let weekDays = [[],[],[],[],[],[],[]];

            let onLine = false;
            let lastTrueIndex = 0;
            for(let i = 0; i < 168; i++)
            {
                if (onLine && i % 24 === 0)
                {
                    weekDays[parseInt(lastTrueIndex / 24)].push({startTime: lastTrueIndex % 24, endTime: (i % 24) === 0 ? 24 : i % 24});
                    lastTrueIndex = i;
                    continue;
                }

                if (items[i] && !onLine)
                {
                    lastTrueIndex = i;
                    onLine = true;
                }
                else if (!items[i] && onLine)
                {
                    onLine = false;
                    weekDays[parseInt(i / 24)].push({startTime: lastTrueIndex % 24, endTime: (i % 24) === 0 ? 24 : i % 24});
                    lastTrueIndex = 0;
                }
            }
            if (onLine)
            {
                weekDays[6].push({startTime: lastTrueIndex % 24, endTime: 24});
            }*/

            console.log(weekDays)

            this.setState({workTime: weekDays, weekStartDate: result.value.weekStart});
        },
        (error) => {
            console.log(error)
        },
        (requestError) => {
            console.log(requestError)
        })
    }

    sendWorkTime = () => {
        // Собрать время в хуйню
        console.log(this.state.weekStartDate);
        let wt = this.state.workTime;

        let items = [];
        for (let i = 0; i < 7; i++)
        {
            wt[i].forEach(item => {
                let dt = new Date(this.state.weekStartDate);
                dt.setUTCFullYear(dt.getFullYear());
                dt.setUTCMonth(dt.getMonth())
                dt.setUTCDate(dt.getDate() + i);
                dt.setUTCHours(item.startTime);
                items.push({
                    startTime: dt,
                    durationInHours: item.endTime - item.startTime
                });
                console.log(dt.toJSON())
            })
        }
        let endDate = new Date(this.state.weekStartDate);
        endDate.setDate(endDate.getDate() + 7);
        console.log(endDate)


        console.log(items)
        let token = decodeToken(localStorage.getItem('token'));

        connection.AuthorizePost('/specialist/setworktime',
        { specialistId: token.id, startTime: this.state.weekStartDate, endTime: endDate, schedule: items},
        (result) => {
            console.log('success')
            this.setState({messageModal: { header: 'Успех', message: 'Расписание установлено'}});
        },
        (error) => {
            console.log('validation err', error);
            this.setState({messageModal: { header: 'Ошибка', message: 'Произошла ошибка'}});
        },
        (requestError) => {
            console.log('failed', requestError)
            this.setState({messageModal: { header: 'Ошибка', message: 'Произошла ошибка'}});
        })
    }
    

    saveWorkTime = () => {
        let wt = this.state.workTime;

        console.log(this.state)
        if (parseInt(this.state.selectedStartTime) >= parseInt(this.state.selectedEndTime))
            return;

        wt[this.state.dayIndex].push({startTime: this.state.selectedStartTime, endTime: this.state.selectedEndTime});

        this.setState({workTime: wt, selectedStartTime: 0, selectedEndTime: 0, showModal: false});
        console.log(this.state)
    }


    addWorkModal = () => {
        return <div onClick={() => this.setState({showModal: false})} style={!this.state.showModal ? {display: 'none'} : {}}  className="modal-block">
            <div className="body-text-small modal-inside" onClick={(e) => e.stopPropagation()}>
                <h2 style={{marginBottom: '1rem'}}>Выберите время работы</h2>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '30%', marginBottom: '1rem'}}>
                    <span>С </span>
                    <select value={this.state.selectedStartTime} onChange={(e) => this.setState({selectedStartTime: e.target.value})}>
                        <option value="0">0:00</option>
                        <option value="1">1:00</option>
                        <option value="2">2:00</option>
                        <option value="3">3:00</option>
                        <option value="4">4:00</option>
                        <option value="5">5:00</option>
                        <option value="6">6:00</option>
                        <option value="7">7:00</option>
                        <option value="8">8:00</option>
                        <option value="9">9:00</option>
                        <option value="10">10:00</option>
                        <option value="11">11:00</option>
                        <option value="12">12:00</option>
                        <option value="13">13:00</option>
                        <option value="14">14:00</option>
                        <option value="15">15:00</option>
                        <option value="16">16:00</option>
                        <option value="17">17:00</option>
                        <option value="18">18:00</option>
                        <option value="19">19:00</option>
                        <option value="20">20:00</option>
                        <option value="21">21:00</option>
                        <option value="22">22:00</option>
                        <option value="23">23:00</option>
                        <option value="24">24:00</option>
                    </select>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '30%', marginBottom: '1rem'}}>
                    <span>До </span>
                    <select value={this.state.selectedEndTime} onChange={(e) => this.setState({selectedEndTime: e.target.value})}>
                        <option value="0">0:00</option>
                        <option value="1">1:00</option>
                        <option value="2">2:00</option>
                        <option value="3">3:00</option>
                        <option value="4">4:00</option>
                        <option value="5">5:00</option>
                        <option value="6">6:00</option>
                        <option value="7">7:00</option>
                        <option value="8">8:00</option>
                        <option value="9">9:00</option>
                        <option value="10">10:00</option>
                        <option value="11">11:00</option>
                        <option value="12">12:00</option>
                        <option value="13">13:00</option>
                        <option value="14">14:00</option>
                        <option value="15">15:00</option>
                        <option value="16">16:00</option>
                        <option value="17">17:00</option>
                        <option value="18">18:00</option>
                        <option value="19">19:00</option>
                        <option value="20">20:00</option>
                        <option value="21">21:00</option>
                        <option value="22">22:00</option>
                        <option value="23">23:00</option>
                        <option value="24">24:00</option>
                    </select>
                </div>
                <button onClick={() => this.saveWorkTime()}>Сохранить</button>
            </div>
        </div>
    }

    removeItem = (day, index) => {
        let wt = this.state.workTime;

        wt[day].splice(index, 1);

        this.setState({workTime: wt});
    }

    parseWeekDates = () => {
        let dt = new Date(this.state.weekStartDate);

        const options = {  year: 'numeric', month: 'numeric', day: 'numeric' };

        let startDate = dt.toLocaleDateString(undefined, options);
        dt.setDate(dt.getDate() + 7);
        let endDate = dt.toLocaleDateString(undefined, options);

        return "С " + startDate + " по " + endDate;
    }

    prevWeek = () => {
        this.setState({weekOffset: this.state.weekOffset - 1}, () =>
            this.receiveTimetable(this.state.weekOffset)
        );
    }

    nextWeek = () => {
        this.setState({weekOffset: this.state.weekOffset + 1}, () =>
            this.receiveTimetable(this.state.weekOffset)
        );
    }

    render() {
        
        return <div className="big-card">
                {this.addWorkModal()}
                {
                    this.state.messageModal ?
                    <MessageModal header={this.state.messageModal.header} message={this.state.messageModal.message} onHide={() => this.setState({messageModal: undefined})}/>
                    :
                    undefined
                }
                <h1 className="reg-block-header">Настройки расписания</h1>
                <span className="body-text-small reg-block-item">
                    <img style={{cursor: 'pointer'}} onClick={this.prevWeek} src="images/icons/arrowLeft.svg"/>
                    <span>{this.parseWeekDates()}</span>
                    <img style={{cursor: 'pointer'}} onClick={this.nextWeek} src="images/icons/arrowRight.svg"/>
                </span>
                <h2 className="timetable-header">Время работы</h2>
                <div className="timetable">
                    <div className="timetable-item">
                        <input type="radio" checked={this.state.workTime[0].length} onChange={() => undefined}/>
                        <span className="timetable-day"> Пн</span>
                        <span className="timetable-work">
                            {this.state.workTime[0].map((item, index) => (
                                <span onClick={() => this.removeItem(0, index)} key={index} style={{marginLeft: (16 * index) + '%'}} className="work-item">{item.startTime}:00-{item.endTime}:00</span>    
                                ))}
                        </span>
                        <button onClick={() => this.setState({showModal: true, dayIndex: 0})} className="work-add-button">
                            <img src="images/icons/Close_MD.svg" />
                        </button>
                    </div>

                        <div className="timetable-item">
                        <input type="radio" checked={this.state.workTime[1].length} onChange={() => undefined}/>
                            <span className="timetable-day"> Вт</span>
                            <span className="timetable-work">
                            {this.state.workTime[1].map((item, index) => (
                                <span onClick={() => this.removeItem(1, index)} key={index} style={{marginLeft: (16 * index) + '%'}} className="work-item">{item.startTime}:00-{item.endTime}:00</span>    
                                ))}
                            </span>
                            <button onClick={() => this.setState({showModal: true, dayIndex: 1})} className="work-add-button">
                                <img src="images/icons/Close_MD.svg" />
                            </button>
                    </div>

                    <div className="timetable-item">
                    <input type="radio" checked={this.state.workTime[2].length} onChange={() => undefined}/>
                        <span className="timetable-day"> Ср</span>
                        <span className="timetable-work">
                        {this.state.workTime[2].map((item, index) => (
                                <span onClick={() => this.removeItem(2, index)} key={index} style={{marginLeft: (16 * index) + '%'}} className="work-item">{item.startTime}:00-{item.endTime}:00</span>    
                                ))}
                        </span>
                        <button onClick={() => this.setState({showModal: true, dayIndex: 2})} className="work-add-button">
                            <img src="images/icons/Close_MD.svg" />
                        </button>
                    </div>

                    <div className="timetable-item">
                        <input type="radio" checked={this.state.workTime[3].length} onChange={() => undefined}/>
                        <span className="timetable-day"> Чт</span>
                        <span className="timetable-work">
                        {this.state.workTime[3].map((item, index) => (
                                <span onClick={() => this.removeItem(3, index)} key={index} style={{marginLeft: (16 * index) + '%'}} className="work-item">{item.startTime}:00-{item.endTime}:00</span>    
                                ))}
                        </span>
                        <button onClick={() => this.setState({showModal: true, dayIndex: 3})} className="work-add-button">
                            <img src="images/icons/Close_MD.svg" />
                        </button>
                    </div>

                    <div className="timetable-item">
                    <input type="radio" checked={this.state.workTime[4].length} onChange={() => undefined}/>
                        <span className="timetable-day"> Пт</span>
                        <span className="timetable-work">
                        {this.state.workTime[4].map((item, index) => (
                                <span onClick={() => this.removeItem(4, index)} key={index} style={{marginLeft: (16 * index) + '%'}} className="work-item">{item.startTime}:00-{item.endTime}:00</span>    
                            ))}             
                        </span>
                        <button onClick={() => this.setState({showModal: true, dayIndex: 4})} className="work-add-button">
                            <img src="images/icons/Close_MD.svg" />
                        </button>
                    </div>

                    <div className="timetable-item">
                        <input type="radio" checked={this.state.workTime[5].length} onChange={() => undefined}/>
                        <span className="timetable-day"> Сб</span>
                        <span className="timetable-work">
                        {this.state.workTime[5].map((item, index) => (
                                <span onClick={() => this.removeItem(5, index)} key={index} style={{marginLeft: (16 * index) + '%'}} className="work-item">{item.startTime}:00-{item.endTime}:00</span>    
                                ))}           
                        </span>
                        <button onClick={() => this.setState({showModal: true, dayIndex: 5})} className="work-add-button">
                            <img src="images/icons/Close_MD.svg" />
                        </button>
                    </div>

                    <div className="timetable-item">
                        <input type="radio" checked={this.state.workTime[6].length} onChange={() => undefined}/>
                        <span className="timetable-day"> Вс</span>
                        <span className="timetable-work">
                        {this.state.workTime[6].map((item, index) => (
                                <span onClick={() => this.removeItem(6, index)} key={index} style={{marginLeft: (16 * index) + '%'}} className="work-item">{item.startTime}:00-{item.endTime}:00</span>    
                                ))}                   
                        </span>
                        <button onClick={() => this.setState({showModal: true, dayIndex: 6})} className="work-add-button">
                            <img src="images/icons/Close_MD.svg" />
                        </button>
                    </div>

                </div>
                <div className="timetable-controls">
                    <button onClick={() => this.sendWorkTime()}>Сохранить</button>
                </div>
        </div>;
    }
}

export default Timetable;