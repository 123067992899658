import React from "react";



class OkCancelModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {

        return <div className="modal-block" onClick={() => this.props.onHide()}>
            <div className="modal-inside body-text-small" onClick={(e) => e.stopPropagation()}>
                <h1>{this.props.header}</h1>
                <div style={{marginTop: '1rem', marginBottom: '1rem'}}>
                    {this.props.message}
                </div>
                <div style={{display: 'flex', justifyContent: 'end'}}>
                    <div style={{marginRight: '1rem'}}>
                        <button onClick={() => this.props.onHide()}>Отмена</button>
                    </div>
                    <div>
                        <button onClick={() => this.props.onAccept()}>Ок</button>
                    </div>
                </div>
            </div>
        </div>;
    }

}

export default OkCancelModal;