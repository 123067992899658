import React from "react";

import Connections from "../../scripts/connection"

import PhoneInput from "react-phone-number-input/input";

class LoginForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            phone: '+7',
        }
    }

    receiveCode = () => {
        if (this.state.phone.trim() == '+7')
            return;

        Connections.Post('/api/v1/auth/code', 
            {phoneNumber: this.state.phone},
            (result) => {

                //this.setState({message: 'Код отправлен!'});
                this.props.onCodeSended(this.state.phone);
            },
            (result) => {
                this.setState({message: "Произошла ошибка"})
            },
            (err) => {
                this.setState({message: "Сервис временно недоступен, попробуйте позже"})
            });
        
        window.ym(94253611,'reachGoal','createdaccaount');
        window._tmr.push({ type: 'reachGoal', id: 3373837, goal: 'putphone'});
    }

    render() {

    return (<div className='login-content'>
                <div className='login-form'>
                    <h2 className='login-title'>Создайте аккаунт или войдите</h2>
                    <div className='login-info login-info-bold'>
                        <div>Введите ваш номер телефона</div>
                        <div>Отправим SMS с проверочным кодом</div>
                    </div>
                    <div className="login-field field-text">
                        <span style={{marginTop: 'auto', marginBottom: 'auto'}}>
                            <img style={{margin: '0 0.5rem'}} src='images/icons/ru.svg' /> +7
                        </span>
                        <PhoneInput defaultCountry="RU" className="field-text" style={{border: 'none', padding: '0', paddingLeft: '0.4rem', width: '75%'}} placeholder="999 999-99-99" value={this.state.phone} onChange={(event) => { this.setState({phone: event})}}/>
                    </div>
                    <div className="login-inform">
                        <img className="login-inform-icon" src="images/icons/inform.svg"/>
                        <span className="login-inform-text">Ваш номер телефона не будет использоваться для звонков</span>
                    </div>
                    <div className='login-button-container'>
                        <button className='login-button' onClick={() => this.receiveCode()}>
                            Получить код
                        </button>
                    </div>
                    {this.state.message ?
                        <div className='login-info'>
                            {this.state.message}
                        </div>
                    :
                        undefined
                    }
                    <div className="login-info" style={{fontSize: '11px'}}>
                    Вводя свой номер, вы принимаете условия <a href="https://uwio.ru/docs/agreement.pdf" target="_blank" rel="noreferrer">пользовательского соглашения</a>, даете <a href="https://uwio.ru/docs/politics.pdf" target="_blank" rel="noreferrer">согласие на обработку персональных данных</a> и на получение SMS-паролей, а также иных информационных и сервисных сообщений на указанный номер телефона
                    </div>
                </div>
            </div>);
    }
}


export default LoginForm;