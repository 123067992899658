function drawCell(ctx, x, y, width, height, fillColor)
{ 
    ctx.fillStyle = fillColor
    ctx.beginPath();
    ctx.roundRect(x - width * 0.5, y - height, width, height, 10);
    ctx.closePath();
    ctx.fill();
}


function drawBacklines(ctx, points)
{
    ctx.lineWidth = 0.2
    for (let i = 0; i < points.length; i++)
    {
        //ctx.lineWidth = 0.066;
        ctx.strokeStyle = '#D5E2E3'
        ctx.beginPath();
        ctx.moveTo(40, points[i]);
        ctx.lineTo(360, points[i]);
        ctx.closePath();
        ctx.stroke();
    }
}

function drawBack(ctx, points, grd, backlinesCount)
{
    ctx.globalAlpha = 0.4
    ctx.lineWidth = 2;
    ctx.fillStyle = grd;

    let bottomY = 64 + (backlinesCount - 1) * 22.5;

    ctx.beginPath();

    //let startX = 55;
    let startPoint = 0;
    while (startPoint < points.length && points[startPoint][1] === null)
        startPoint++;

    if (startPoint == points.length)
        return;

    ctx.moveTo(points[startPoint][0], bottomY); // move to start bottom
    ctx.lineTo(points[startPoint][0], points[startPoint][1]);   // draw vertical line from bottom to first point (Y only)

    let moveBeforeNext = false;
    let lastPoint = null;
    for (let i = startPoint; i < points.length; i++)
    {
        if (points[i][1] === null)
        {
            ctx.lineTo(lastPoint[0], bottomY) // draw lines
            moveBeforeNext = true;
            continue;
        }

        if (moveBeforeNext)
        {
            moveBeforeNext = false;
            ctx.moveTo(points[i][0], bottomY);
            ctx.lineTo(points[i][0], points[i][1])
        }

        ctx.lineTo(points[i][0], points[i][1]) // draw lines
        lastPoint = points[i];
    }

    if (lastPoint != null)
    ctx.lineTo(lastPoint[0], bottomY); // draw to bottom (Y only)

    ctx.closePath();
    ctx.fill();
}

function drawLine(ctx, points, color)
{
    ctx.globalAlpha = 1
    ctx.strokeStyle = color;

    ctx.beginPath();
    let startPoint = 0;
    while (startPoint < points.length && points[startPoint][1] === null)
        startPoint++

        
    if (startPoint == points.length)
        return;

    ctx.moveTo(points[startPoint][0], points[startPoint][1]);

    let moveBeforeNext = false;
    for (let i = startPoint; i < points.length; i++)
    {
        if (points[i][1] === null)
        {
            moveBeforeNext = true;
            continue;
        }

        if (moveBeforeNext)
        {
            moveBeforeNext = false;
            ctx.moveTo(points[i][0], points[i][1]);
        }

        ctx.lineTo(points[i][0], points[i][1]);
    }
    
    ctx.stroke();
    ctx.closePath();
}




function drawPoints(ctx, points, color)
{

    for (let i = 0; i < points.length; i++)
    {

        let point = points[i];
        if (point[1] === null)
            continue;

        ctx.beginPath();
        ctx.fillStyle = '#000';
        ctx.arc(point[0], point[1], 4, 0, 2 * Math.PI, false);
        ctx.fill();
        ctx.lineWidth = 2;
        ctx.strokeStyle = color;
        ctx.stroke();
    }

}





function prepareCanvas(canvas, ctx, horizontalLinesCount)
{
    ctx.fillStyle = "#292928";
    ctx.fillRect(0, 0, canvas.width, canvas.height);


    let backlines = [];
    for (let offset = 64, i = 0; i < horizontalLinesCount; i++, offset += 22.5)
    {
        backlines.push(offset);
    }

    drawBacklines(ctx, backlines);
}

function drawBackCells(ctx, backlinesCount)
{
    let cellColor = 'rgba(213, 226, 227, 0.01)'
    let height = (backlinesCount - 1) * 22.5;

    for (let i = 0, offsetX = 55; i < 9; i++, offsetX += 35)
    {
        drawCell(ctx, offsetX, 64 + height, 30, height, cellColor);
    }
}

function drawFilledCells(ctx, cells, backlinesCount)
{
    //var cellgrd = ctx.createLinearGradient(0, 0, 315, 630);
    //cellgrd.addColorStop(0,'#FF783D');
    //cellgrd.addColorStop(1, '#EB4903');

  //  drawCell(ctx, 20, 200, 30, 100, cellgrd);
  //  drawCell(ctx, 55, 200, 30, 125, cellgrd);
  //  drawCell(ctx, 90, 200, 30, 25, cellgrd);
    let maxHeight = (backlinesCount - 1) * 22.5;
    for (let i = 0, offsetX = 55; i < cells.length; i++, offsetX += 35)
    {
        if (!cells[i])
            continue;
  

        let height = cells[i] * 22.5;
        height = Math.min(height, maxHeight);


        let cellgrd = ctx.createLinearGradient(offsetX - 15, 64 + maxHeight - height, offsetX + 15, 64 + maxHeight);
        cellgrd.addColorStop(0,'#FF783D');
        cellgrd.addColorStop(1, '#EB4903');
        drawCell(ctx, offsetX, 64 + maxHeight, 30, height, cellgrd);
    }
}


function drawWellness(wellness, productivity)
{
    var canvas=document.getElementById("wellness");
    var ctx=canvas.getContext("2d");

    var ctx = canvas.getContext("2d");
    prepareCanvas(canvas, ctx, 10);


   // drawBackCells(ctx, 10);

    var orangePoints = [
    [15, null],
    [50, null],
    [85, null],
    [120, null],
    [155, null],
    [190, null],
    [225, null],
    [260, null],
    [295, null],
    ]
    
    var bluePoints = [
    [15, null],
    [50, null],
    [85, null],
    [120, null],
    [155, null],
    [190, null],
    [225, null],
    [260, null],
    [295, null]
    ]
    
    for (let i = 0; i < wellness.length; i++)
    {
        if (wellness[i] === null)
            bluePoints[i][1] = null;
        else
            bluePoints[8 - i][1] = (10 - wellness[i]) * 22.5;
    }

    /*for (let i = 0; i < productivity.length; i++)
    {
        if (productivity[i] === null)
            orangePoints[i][1] = null;
        else
            orangePoints[8 - i][1] = (10 - productivity[i]) * 22.5;
    }*/

    for (let i = 0; i < bluePoints.length; i++)
    {
      /*  orangePoints[i][0] += 40;
        if (orangePoints[i][1] !== null)
            orangePoints[i][1] += 64;*/

        bluePoints[i][0] += 40;
        if (bluePoints[i][1] !== null)
            bluePoints[i][1] += 64;
    }
    


    var grd = ctx.createLinearGradient(100, 100, 100, 800);
    grd.addColorStop(0,'rgba(226, 104, 51, 0.3)');
    grd.addColorStop(0.3, 'rgba(226, 104, 51, 0)');

    var grd2 = ctx.createLinearGradient(100, 100, 100, 800);
    grd2.addColorStop(0,'rgba(58, 118, 123, 0.3)');
    grd2.addColorStop(0.3, 'rgba(58, 118, 123, 0)');


    drawBack(ctx, bluePoints, grd2, 10)


    drawLine(ctx, bluePoints, '#0BC6D6')

    drawPoints(ctx, bluePoints, '#0BC6D6');
}


function drawSleep(asleepTime, sleepQuality) {
    var canvas=document.getElementById("sleep");
    var ctx=canvas.getContext("2d");

    var ctx = canvas.getContext("2d");
    prepareCanvas(canvas, ctx, 12);


    drawBackCells(ctx, 12);


    let cells = [null, null, null, null, null, null, null, null, null];

    for (let i = 0; i < sleepQuality.length; i++)
    {
        if (sleepQuality[i] !== null)
            cells[8 - i] = (5 - sleepQuality[i]) * 3;
    }

    drawFilledCells(ctx, cells, 12);

    // draw pictures;

    let awakeIdeal = document.getElementById('awake-ideal-img');
    let awakeBad = document.getElementById('awake-bad-img');
    let awakeWell = document.getElementById('awake-well-img');
    let awakeMid = document.getElementById('awake-mid-img');




    


    try
    {
        for (let i = 0; i < cells.length; i++)
        {
            if (cells[i] === 3)   
                ctx.drawImage(awakeBad, 45 + 35 * i, 64 + 22.5 * 9 + 3);
            else if (cells[i] === 6)
                ctx.drawImage(awakeMid, 45 + 35 * i, 64 + 22.5 * 7 + 15);
            else if (cells[i] === 9)
                ctx.drawImage(awakeWell, 45 + 35 * i, 64 + 22.5 * 6 + 4);
            else if (cells[i] === 12)
                ctx.drawImage(awakeIdeal, 45 + 35 * i, 64 + 22.5 * 5);
        }
    }
    catch (ex) { }
    


    // convert asleep time
    let setTime = 
    {
        '18:00': 0,
        '18:30': 1,
        '19:00': 2,
        '19:30': 3,
        '20:00': 4,
        '20:30': 5,
        '21:00': 6,
        '21:30': 7,
        '22:00': 8,
        '22:30': 9,
        '23:00': 10,
        '23:30': 11,
        '00:00': 12,
        '00:30': 13,
        '01:00': 14,
        '01:30': 15,
        '02:00': 16,
        '02:30': 17,
        '03:00': 18,
        '03:30': 19,
        '04:00': 20,
        '04:30': 21,
        '05:00': 22
    }

    let asleepTimeDecoded = asleepTime.map(x => 
    {
        let time = x.split(':');
        let hours = parseInt(time[0]);

        let minutes = time[1] == '30' ? 1 : 0;

        let timeValue = time[0] + ':' + time[1];

        return setTime[timeValue];
    });

    
    var bluePoints = [
    [15, null],
    [50, null],
    [85, null],
    [120, null],
    [155, null],
    [190, null],
    [225, null],
    [260, null],
    [295, null]
    ]
    

    for (let i = 0; i < asleepTimeDecoded.length; i++)
    {
        if (asleepTimeDecoded[i] === null)
            bluePoints[i][1] = null;
        else
            bluePoints[8 - i][1] = (22 - asleepTimeDecoded[i]) * 11.25;
    }

    for (let i = 0; i < bluePoints.length; i++)
    {
        bluePoints[i][0] += 40;
        if (bluePoints[i][1] !== null)
            bluePoints[i][1] += 64;
    }

    var grd2 = ctx.createLinearGradient(100, 500, 100, 900);
    grd2.addColorStop(0,'rgba(58, 118, 123, 0.3)');
    grd2.addColorStop(0.3, 'rgba(58, 118, 123, 0)');


    drawBack(ctx, bluePoints, grd2, 12)


    drawLine(ctx, bluePoints, '#0BC6D6')

    drawPoints(ctx, bluePoints, '#0BC6D6');
}


function drawSelfTime(selfTime) {
    var canvas = document.getElementById("self-time");
    var ctx = canvas.getContext("2d");

    var ctx = canvas.getContext("2d");
    prepareCanvas(canvas, ctx, 6);


    drawBackCells(ctx, 6);


    let cells = [null, null, null, null, null, null, null, null, null];

    for (let i = 0; i < selfTime.length; i++)
    {
        if (selfTime[i] !== null)
            cells[8 - i] = selfTime[i];
    }

    drawFilledCells(ctx, cells, 6);


}

function setupAxe(axeElementId, axeValues)
{
    let months = ['янв.', 'фев.', 'мар.', 'апр.', 'май', 'июн.', 'июл.', 'авг.', 'сен.', 'окт.', 'ноя.', 'дек.'];
    let innerNodes = document.getElementById(axeElementId).getElementsByTagName('div');



    let j = 0;
    for (let i = innerNodes.length - 1; i >= 0; i--)
    {
        innerNodes[i].innerHTML = '';
        if (j < axeValues.length)
        {
            if (axeValues[j] === '-')
                innerNodes[i].innerHTML = '-';
            else
                innerNodes[i].innerHTML = axeValues[j].getDate() + '<br/>' + months[axeValues[j].getMonth()];
            j++;
        }
    }
}

const InitAnalysis = (clientId, wellness, sleep, selfTime) => {

    loadData(clientId, () => { this.showContent()});

    wellness.addEventListener('mousedown', function(e) {
        showCard(wellness, e, (record) => showWellnessCard(record), 10)
    })

    sleep.addEventListener('mousedown', function(e) {
        showCard(sleep, e, (record) => showSleepCard(record), 12)
    })

    selfTime.addEventListener('mousedown', function(e) {
        showCard(selfTime, e, (record) => showSelfCard(record), 6)
    })

}


function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}


function showCard(canvas, event, handler, horizontalLinesCount) {
    const rect = canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    let maxY = (horizontalLinesCount - 1) * 22.5 + 69;
    
    if (x > 40 && x < 355 && y > 69 && y < maxY)
    {
        let cell = 8 - parseInt((x - 40) / 35);

        if (monthsRecords.length > cell)
        {
            handler(monthsRecords[cell]);
        }
    }
    
}





function drawEmotions(emotions) {

    let dict = {
        'Страх': -1,
        'Гнев': -1,
        'Печаль': -1,
        'Спокойствие': 1,
        'Радость': 1,
        'Растерянность': -1,
        'Радость/Удовлетворение': 1,
        'Апатия/Безразличие': -1,
        'Нейтральные': 0
    }

    let happyCount = 0;
    let sadCount = 0;
    let neutralCount = 0;

    for (let i = 0; i < emotions.length; i++)
    {
        let emotionsItems = emotions[i].split(';');
        let element = document.getElementById('emotions-item-' + (9 - i));
        element.innerHTML = '';
        
        let state = 0;
        for (let j = 0; j < emotionsItems.length; j++)
        {
            if (dict[emotionsItems[j]] !== undefined)
            {
                state += dict[emotionsItems[j]];
            }
        }

        if (state > 0)
        {
            let happy = document.getElementById('happy-smile').cloneNode(true);
            happy.id = undefined;
            happy.style = '';
            element.appendChild(happy);
            element.style = 'background: #60E25D';
            happyCount++;
        }
        else if (state < 0)
        {
            let sad = document.getElementById('sad-smile').cloneNode(true);
            sad.id = undefined;
            sad.style = '';
            element.appendChild(sad);
            element.style = 'background: #F93434';
            sadCount++;
        }
        else
        {
            let neutral = document.getElementById('neutral-smile').cloneNode(true);
            neutral.id = undefined;
            neutral.style = '';
            element.appendChild(neutral);
            element.style = 'background: #AFCCCE';
            neutralCount++;
        }
    }


    let goodPercentElement = document.getElementById('good-days-percent');
    let badPercentElement = document.getElementById('bad-days-percent');
    let neutralPercentElement = document.getElementById('neutral-days-percent');

    goodPercentElement.innerHTML = '0%';
    badPercentElement.innerHTML = '0%';
    neutralPercentElement.innerHTML = '0%';

    console.log(emotions.length)

    if (emotions.length > 0)
    {
        goodPercentElement.innerHTML = parseInt(happyCount / emotions.length * 100) + '%';
        badPercentElement.innerHTML = parseInt(sadCount / emotions.length * 100) + '%';
        neutralPercentElement.innerHTML = parseInt(neutralCount / emotions.length * 100) + '%';
    }
}


var monthsRecords = null;
function loadData(clientId, showContent) {

    let token = localStorage.getItem('token');

    fetch(process.env.REACT_APP_API_URL + '/wellness/users/' + clientId + '/records', 
    {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token},
        method: 'GET'
    }).then((res) => res.json().then((data) => {
        if (data.statusCode === 200)
        {

            
            monthsRecords = data.value;
            monthsRecords.forEach(x => x.createDate = new Date(x.createDate))

            console.log(monthsRecords)

            let productivity = [];
            let wellness = [];

            let sleepQuality = [];
            let sleepTime = [];

            let selfTime = [];

            let axes = [];

            let emotions = [];

            for (let i = 0; i < monthsRecords.length && i < 9; i++)
            {
                productivity.push(monthsRecords[i].productivityRate);
                wellness.push(monthsRecords[i].wellnessRate);

                sleepTime.push(monthsRecords[i].asleepTime);
                sleepQuality.push(parseInt(monthsRecords[i].awakeStatus));

                selfTime.push(monthsRecords[i].selfTime);

                axes.push(monthsRecords[i].createDate);

                emotions.push(monthsRecords[i].emotions)
            }

            drawWellness(wellness, productivity)

            drawSelfTime(selfTime);

            drawSleep(sleepTime, sleepQuality);

            drawEmotions(emotions)

            while (axes.length < 9)
                axes.push('-');
            

            setupAxe('wellness-down-axe', axes);
            setupAxe('asleep-down-axe', axes);
            setupAxe('self-down-axe', axes);
            setupAxe('emotions-down-axe', axes);


            showContent();
        }
    }));
}


function dateToString(date, $1, $2, $3, separator='') {
    const dateObj = {
        date: String(date.getDate()).padStart(2, '0'),
        month: String(date.getMonth() + 1).padStart(2, '0'),
        year: date.getFullYear()
    };

    return dateObj[$1] + separator + dateObj[$2] + separator + dateObj[$3];
}

function closeWellnessCard()
{
    let wellnessCard = document.getElementById('wellness-card');

    wellnessCard.style = 'display: none';
}



function showWellnessCard(wellness)
{
    closeSleepCard();
    closeSelfCard();

    let wellnessCard = document.getElementById('wellness-card');
    let wellnessCardDate = document.getElementById('wellness-card-date');
    let wellnessCardEmotions = document.getElementById('wellness-card-emotions');
    let wellnessCardContent = document.getElementById('wellness-card-content');

    wellnessCardDate.innerHTML = dateToString(wellness.createDate, 'date', 'month', 'year', '.');

    if (wellness.emotions)
    {
        wellnessCardEmotions.innerHTML = wellness.emotions.replaceAll(";", ", ");
    }
    else
    {
        wellnessCardEmotions.innerHTML = '';
    }

    wellnessCardContent.innerHTML = wellness.context;

    wellnessCard.style = 'display: block';

}

function closeSleepCard()
{
    let wellnessCard = document.getElementById('sleep-card');

    wellnessCard.style = 'display: none';
}



function showSleepCard(record)
{
    closeWellnessCard();
    closeSelfCard();

    let fallingAsleepDecode = [ '', 'Быстро', 'Средне', 'Долго', 'Очень долго'];
    let fallingAsleepIcons = [ '', 'asleep-fast.svg', 'asleep-mid.svg', 'asleep-mid.svg', 'asleep-long.svg']

    let sleepCard = document.getElementById('sleep-card');
    let sleepCardDate = document.getElementById('sleep-card-date');
    let sleepCardAsleep = document.getElementById('sleep-card-asleep');
    let sleepCardAwakeCount = document.getElementById('sleep-card-awake-count');
    let sleepCardAwakeComment = document.getElementById('sleep-card-awake-comment');
    let sleepCardAwakeCountIcon = document.getElementById('sleep-card-awake-count-icon');
    let sleepCardAsleepIcon = document.getElementById('asleep-card-asleep-icon');

    let asleepBlockContent = document.getElementById('asleep-block-content');
    let awakeBlockContent = document.getElementById('awake-block-content');

    sleepCardDate.innerHTML = dateToString(record.createDate, 'date', 'month', 'year', '.');

    
    
    if (record.fallingAsleep)
    {
        record.fallingAsleep = parseInt(record.fallingAsleep)
        asleepBlockContent.style = 'display: flex';
        sleepCardAsleep.innerHTML = fallingAsleepDecode[record.fallingAsleep];
        sleepCardAsleepIcon.src = 'images/icons/' + fallingAsleepIcons[record.fallingAsleep];
    }
    else
    {
        asleepBlockContent.style = 'display: none'
    }

    if (record.nightAwakeCount)
    {
        awakeBlockContent.style = 'display: flex';
        sleepCardAwakeCount.innerHTML = record.nightAwakeCount;
        sleepCardAwakeCountIcon.innerHTML = record.nightAwakeCount;
    }
    else
    {
        awakeBlockContent.style = 'display: none';
    }

    sleepCardAwakeComment.innerHTML = record.nightAwakeComment;

    sleepCard.style = 'display: block';

}


const closeSelfCard = () =>
{
    let selfCard = document.getElementById('self-card');
    selfCard.style = 'display: none';
}



const showSelfCard = (record) =>
{
    closeWellnessCard();
    closeSleepCard();

    let selfCard = document.getElementById('self-card');
    let selfCardDate = document.getElementById('self-card-date');
    let selfCardEmotions = document.getElementById('self-card-emotions');
    let selfCardContent = document.getElementById('self-card-content');

    selfCardDate.innerHTML = dateToString(record.createDate, 'date', 'month', 'year', '.');

    selfCardEmotions.innerHTML = 'Время на себя';

    selfCardContent.innerHTML = record.pleasure;

    selfCard.style = 'display: block';

}


var wellnessFaqShow = false;
const showWellnessFaq = () =>
{
    let card = document.getElementById('wellness-faq');
    if (wellnessFaqShow)
    {
        card.style = 'display: none';
    }
    else
    {
        card.style = '';
    }

    wellnessFaqShow = !wellnessFaqShow;
    console.log(wellnessFaqShow)
}


var sleepFaqShow = false;
const showSleepFaq = () =>
{
    let card = document.getElementById('sleep-faq');
    if (sleepFaqShow)
    {
        card.style = 'display: none';
    }
    else
    {
        card.style = '';
    }

    sleepFaqShow = !sleepFaqShow;
}


var selfFaqShow = false;
const showSelfFaq = () =>
{
    let card = document.getElementById('self-faq');
    if (selfFaqShow)
    {
        card.style = 'display: none';
    }
    else
    {
        card.style = '';
    }

    selfFaqShow = !selfFaqShow;
}


export default { InitAnalysis, showSelfFaq, showWellnessFaq, showSleepFaq, closeSelfCard, closeSleepCard, closeWellnessCard };