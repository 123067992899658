import React from "react";
import Connections from "../../scripts/connection";
import 'react-range-slider-input/dist/style.css';
import Loader from "../Loader";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import TimezoneSelector from "../TimezoneSelector";
import Utils from "../../scripts/utils";
import OnboardingHeaderMobile from "./showcase/OnboardingHeaderMobile";
import RegisterDecisionModal from "./RegisterDecisionModal";
import { decodeToken } from "react-jwt";


class MobileEmotionsSurvey extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            questionsAnswers: [],
            ownInputShow: {},
            sex: null,
            lgtqExp: false,

            selectedCategories: [],
            toggle: 2,
            timeType: 1,
            timePeriods: [],

            timezone: new Date().getTimezoneOffset(),

            rate: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            selectedJobs: [],
            selectedProblems: [],
            selectedFactors: [],
            selectedPhysical: [],
            selectedValuables: [],
            selectedTargets: [],



            problemsList: [
                {
                    id: 0,
                    title: 'Страхи и фобии'
                },
                {
                    id: 1,
                    title: 'Компульсивные повторяющиеся действия'
                },
                {
                    id: 2,
                    title: 'Компульсивное переедание'
                },
                {
                    id: 3,
                    title: 'Ревность'
                },
                {
                    id: 4,
                    title: 'Навязчивые мысли'
                },
                {
                    id: 5,
                    title: 'Трудности самоконтроля'
                },
                {
                    id: 6,
                    title: 'Прокрастинация'
                },
                {
                    id: 7,
                    title: 'Эмоциональная/любовная зависимость'
                },
            ],
            factors: [
                {
                    id: 0,
                    title: 'Сложности с работой/самореализацией'
                },
                {
                    id: 1,
                    title: 'Проблема в принятии решения'
                },
                {
                    id: 2,
                    title: 'Проблемы в отношениях'
                },
                {
                    id: 3,
                    title: 'Утрата близкого человека'
                },
                {
                    id: 4,
                    title: 'Болезнь своя или близких'
                }
            ],
            physical: [
                {
                    id: 0,
                    title: 'Трудности со сном'
                },
                {
                    id: 1,
                    title: 'Напряжение в мышцах'
                },
                {
                    id: 2,
                    title: 'Постоянная усталость'
                },
                {
                    id: 3,
                    title: 'Плохая концентрация внимания'
                },
                {
                    id: 4,
                    title: 'Сексуальные проблемы'
                }
            ],
            jobs: [
                {
                    id: 0,
                    title: 'Программирование и инженерия'
                },
                {
                    id: 7,
                    title: 'Здравоохранение'
                },
                {
                    id: 3,
                    title: 'Помогающие профессии'
                },
                {
                    id: 1,
                    title: 'Бизнес и менеджмент'
                },
                {
                    id: 2,
                    title: 'Креативная индустрия'
                },
                {
                    id: 4,
                    title: 'Образование'
                },
                {
                    id: 5,
                    title: 'Фриланс'
                },
                {
                    id: 6,
                    title: 'Другое'
                }
            ],
            valuables: [
                {
                    id: 0,
                    title: 'Понимание и поддержка',
                },
                {
                    id: 1,
                    title: 'Решение проблемы и конкретный результат'
                },
                {
                    id: 2,
                    title: 'Психологическое сопровождение в течении стрессового или депрессивного периода жизни'
                }
            ],
            target: [
                {
                    id: 0,
                    title: 'Нормализовать эмоциональное состояние и самочувствие'
                },
                {
                    id: 1,
                    title: 'Повысить уровень энергии, мотивации и продуктивности'
                },
                {
                    id: 2,
                    title: 'Понять причины своего состояния/поведения/мыслей'
                },
                {
                    id: 3,
                    title: 'Улучшить отношения с окружающими'
                }
            ]

        }
           
    }

    errorOccured = (error) => {
        this.setState({error: true});
    }

    async componentDidMount() {
        let tasks = [];

        let templateTask = Connections.Get('/surveys/templates',
            (result) => {
                console.log('Received template:', result)

                this.setState({questions: result.value.questions});
            },
            (error) => {
                console.log('Error occured on receive templateTask', error)
            },
            (fatal) => {
                console.log('Fatal error occured on receive templateTask', fatal)
            }
        );

        tasks.push(templateTask);

        if (this.props.auth)
        {
            let clientSurveyTask = Connections.AuthorizeGet('/client/clientsurvey',
                (result) => {
                    console.log('Received survey', result);

                    let clientSurvey = result.value.clientSurvey;

                    this.setState({
                        questionsAnswers: clientSurvey.questionsAnswers,
                        name: clientSurvey.name,
                        age: clientSurvey.personAge,
                        timezone: clientSurvey.timezone,
                        sex: clientSurvey.specialistSex,
                        timePeriods: clientSurvey.timePeriods,
                        showDecision: true
                    });
                },
                (error) => {
                    console.log('Error occured on receive survey', error);
                },
                (fatal) => {
                    console.log('Fatal error occured on receive survey', fatal);
                }
            );
            tasks.push(clientSurveyTask);
        }
        else
        {
            // Parse json from localStorage
            console.log('Parsing localStorage for client survey');
            let surveyData = localStorage.getItem('survey');
            if (surveyData)
            {
                let survey = JSON.parse(surveyData);
                this.setState({
                    sex: survey.preferredGender ?? null,
                    timezone: survey.timezoneOffset ?? null,
                    timePeriods: survey.timePeriods ?? [],
                    name: survey.name ?? null,
                    age: survey.age ?? null,
                    questionsAnswers: survey.questionsAnswers ?? []
                })
            }
        }

        Promise.all(tasks).then(res => {
            this.setState({loadingComplete: true});
        });
        


        window.ym(94253611,'hit','platform/survey');

        Connections.SendActionStats({browserId: Utils.GetBrowserId(), actionId: 'SurveyMobile'});
    }

    clearSurvey = () => {
        localStorage.removeItem('survey');
        this.props.logout();

        this.setState({
            questionsAnswers: [],
            name: '',
            age: '',
            timezone: new Date().getTimezoneOffset(),
            sex: null,
            timePeriods: [],
            showDecision: false
        });
    }

    register = async () => {
        let messages = [];
        if (!this.state.name)
            messages.push('Введите имя');

        if (!this.state.age)
            messages.push('Введите возраст');


        if (messages.length != 0)
        {
            this.setState({messages: messages});
            return;
        }
  
        let surveyData = {
            preferredGender: this.state.sex,
            timezoneOffset: this.state.timezone,
            timePeriods: this.state.timePeriods,
            name: this.state.name,
            age: this.state.age,
            questionsAnswers: this.state.questionsAnswers,

            version: process.env.REACT_APP_SURVEY_VERSION
        };

        let rates = [];

        let rateQuestions = this.state.questionsAnswers.filter(x => x.questionId === 3);

        for (let i = 0; i < 7; i++)
        {
            let index = rateQuestions.findIndex(x => x.optionId === (7 + i));
            if (index !== -1)
                rates.push(rateQuestions[index].value);
            else
                rates.push(0);
        }

        let payload =  { 
            browserId: Utils.GetBrowserId(),
            utm: window.location.search,
            rates: rates,
            thoughtProblems: this.state.questionsAnswers.filter(x => x.questionId === 4).map(x => x.value), 
            symptoms: this.state.questionsAnswers.filter(x => x.questionId === 5).map(x => x.value), 
            liveProblems: this.state.questionsAnswers.filter(x => x.questionId === 6).map(x => x.value), 
            valuables: this.state.questionsAnswers.filter(x => x.questionId === 7).map(x => x.value),
            targets: this.state.questionsAnswers.filter(x => x.questionId === 8).map(x => x.value),
            jobs: this.state.questionsAnswers.filter(x => x.questionId === 9).map(x => x.value),
            ownJob: this.state.questionsAnswers.find(x => x.questionId === 9)?.ownValue,
            age: surveyData.age + '',
            name: surveyData.name
        };

        //console.log(JSON.stringify({SurveyAppliedEventData: payload}));
        
        Connections.SendStats(
           {SurveyAppliedEventData: payload}
        );
        

        localStorage.setItem('survey', JSON.stringify(surveyData));
        console.log(surveyData)


        // Send to server if authed

        
        if (this.props.auth)
        {
            let tokenData = decodeToken(localStorage.getItem('token'));

            surveyData.clientId = tokenData.id;
            surveyData.subcategoriesId = [];
            surveyData.consultationType = 'Single';
            surveyData.timezoneOffset = parseInt(surveyData.timezoneOffset);

            console.log('Survey', surveyData)
            
            await Connections.AuthorizePost('/client/applysurvey', 
                surveyData,
                (result) => { console.log('Updating success', result)},
                (error) => { console.log('Updateing error', error)},
                (fatalError) => { console.log('Updating survey fatal', fatalError)}
            )
        }
        

        this.setState({next: true}, () => { window.ym(94253611,'reachGoal','Vpered_K_select')});
    }


    getTimezoneDisplay = (timezone) => {
        return timezone >= 0 ? "UTC-" + timezone / 60 : "UTC+" + (-(timezone / 60));
    }

    toggleTimePeriod = (timePeriod) => {

        if (!this.isTimePeriodActive(timePeriod))
        {
            let timePeriods = this.state.timePeriods;
            timePeriods.push(timePeriod);
            this.setState({timePeriods: timePeriods})
        }
        else
        {
            let timePeriods = this.state.timePeriods.filter(x => x != timePeriod);
            this.setState({timePeriods: timePeriods})
        }
    }

    isTimePeriodActive = (timePeriod) => {
        return this.state.timePeriods.findIndex(x => x == timePeriod) !== -1;
    }

    inputName = (name) => {
        if (name.length > 30)
            return;

        this.setState({name: name})
    }

    inputAge = (age) => {
        if (age != '' && parseInt(age) != age)
            return;

        this.setState({age: age});
    }


    showSurveyTemplate = (questions) => {
        
        let items = [];

        questions.forEach((item, index) => {

            if (item.type === 'CheckWithOwn')
            {
                items.push(this.showCheckWithOwnQuestion(item));
            }
            else if (item.type === 'Rating')
            {
                items.push(this.showRatingQuestion(item));
            }
            else if (item.type === 'Check')
            {
                items.push(this.showCheckQuestion(item));
            }
        })

        return items;
    }

    onToggle = (questionId, optionId) => {
        
        let answers = this.state.questionsAnswers.filter(x => !(x.questionId === questionId && x.value === optionId));

        if (answers.length === this.state.questionsAnswers.length)
            answers.push({ questionId: questionId, value: optionId });

        
        this.setState({questionsAnswers: answers});
    }

    isChecked = (questionId, optionId) => {
        return this.state.questionsAnswers.findIndex(x => x.questionId === questionId && x.value === optionId) !== -1;
    }

    getRate = (questionId, optionId) => {
        var index = this.state.questionsAnswers.findIndex(x => x.questionId === questionId && x.optionId === optionId);

        if (index === -1)
            return 0;

        return this.state.questionsAnswers[index].value;
    }

    setRate = (questionId, optionId, value) => {
        var answers = this.state.questionsAnswers.filter(x => !(x.questionId === questionId && x.optionId === optionId));

        answers.push({questionId: questionId, optionId: optionId, value: value});

        this.setState({questionsAnswers: answers});
    }

    toggleOwnInput = (questionId) => {
        let ownInputShow = this.state.ownInputShow;

        if (!ownInputShow[questionId])
        {
            ownInputShow[questionId] = true;
        }
        else
        {
            ownInputShow[questionId] = false;
        }

        this.setState({ownInputShow: ownInputShow});
    }

    isOwnInputChecked = (questionId) => {
        return this.state.ownInputShow[questionId];
    }

    setOwnInput = (questionId, input) => {
        let answers = this.state.questionsAnswers.filter(x => !(x.questionId === questionId && x.ownValue));
        
        answers.push({ questionId: questionId, ownValue: input });
        
        this.setState({questionsAnswers: answers});
    }

    getOwnInput = (questionId) => {
        let index = this.state.questionsAnswers.findIndex(x => x.questionId === questionId && x.ownValue);
        if (index === -1)
            return;

        return this.state.questionsAnswers[index].ownValue;
    }

    showCheckWithOwnQuestion = (question) => {
        
        return <div key={question.id}>
            <div className="content-block">
                <div>
                    <h2 className="reg-block-header">{question.title}</h2>
                        
                    <div className="reg-columns" >
                        <div className="reg-column-block">   

                            {
                                question.options.map( (item, index) => 
                                    <span key={index} className="body-text-small reg-block-item">
                                        <input type="checkbox" id={item.id} checked={this.isChecked(question.id, item.id)} onChange={() => this.onToggle(question.id, item.id)}/>    
                                        <label htmlFor={item.id}>{item.text}</label>
                                    </span>
                                )
                            }

                            <span className="body-text-small reg-block-item">
                                <input type="checkbox" id={question.id + 'different'} checked={this.isOwnInputChecked(question.id)} onChange={() => this.toggleOwnInput(question.id)}/>    
                                <label htmlFor={question.id + 'different'}>Другое</label>
                            </span>

                            {this.isOwnInputChecked(question.id) ?
                                <textarea value={this.getOwnInput(question.id)} onChange={e => this.setOwnInput(question.id, e.target.value)} />
                            :
                                undefined
                            }

                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    }


    showCheckQuestion = (question) => {
        return <div key={question.id}>
            <div className="content-block">
                <div>
                    <h2 className="reg-block-header">{question.title}</h2>
                        
                    <div className="reg-columns" >
                        <div className="reg-column-block">   

                            {
                                question.options.map( (item, index) => 
                                    <span key={index} className="body-text-small reg-block-item">
                                        <input type="checkbox" id={item.id} checked={this.isChecked(question.id, item.id)} onChange={() => this.onToggle(question.id, item.id)}/>    
                                        <label htmlFor={item.id}>{item.text}</label>
                                    </span>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>

        </div>
    }

    showRatingQuestion = (question) => {
        
        return <div style={{marginBottom: '37px'}} key={question.id}>
            <div className="content-block">
                <div>
                    <h2 className="reg-block-header">{question.title}</h2>
                        
                        {
                            question.options.map((item, index) => {
                                
                                let rate = this.getRate(question.id, item.id);
                                
                                return <span key={item.id} className="body-text-small reg-block-item rate-block">
                                    <span className="rate-header">{item.text}</span>
                                    <div className="rate-line" style={{width: '200px'}}>
                                        <div onClick={() => this.setRate(question.id, item.id, 0)} style={rate === 0 ? {background: 'white'} : undefined} className="rate-item">0</div>
                                        <div onClick={() => this.setRate(question.id, item.id, 1)} style={rate === 1 ? {background: 'white'} : undefined} className="rate-item">1</div>
                                        <div onClick={() => this.setRate(question.id, item.id, 2)} style={rate === 2 ? {background: 'white'} : undefined} className="rate-item">2</div>
                                        <div onClick={() => this.setRate(question.id, item.id, 3)} style={rate === 3 ? {background: 'white'} : undefined} className="rate-item">3</div>
                                        <div onClick={() => this.setRate(question.id, item.id, 4)} style={rate === 4 ? {background: 'white'} : undefined} className="rate-item">4</div>
                                        <div onClick={() => this.setRate(question.id, item.id, 5)} style={rate === 5 ? {background: 'white'} : undefined} className="rate-item">5</div>
                                    </div>
                                </span>
                            })
                        }
                </div>
            </div>

        </div>
    }

    render() {
        if (this.state.next)
            return <Navigate to={'/select' + window.location.search }/>

        if (this.state.error)
            return <div>Произошла ошибка, попробуйте позже</div>;

        if (!this.state.loadingComplete)
            return <div style={{height: '600px'}}><Loader /></div>;
     
        return <>

            <div style={{paddingTop: '22px'}}>
                <OnboardingHeaderMobile step={1} state={this.props.state}/>
            </div>

            {this.state.showDecision ?
                <RegisterDecisionModal name={this.state.name} onContinue={() => this.setState({showDecision: false})} onCreate={() => this.clearSurvey()}/>
            :
                undefined
            }

            <div style={{paddingLeft: '19px', paddingRight: '19px', marginTop: '14px'}}>

                {this.showSurveyTemplate(this.state.questions)}

{/*
                <div style={{marginBottom: '37px'}} className="content-block">
                    <div>
                        <h2 className="reg-block-header">Какие эмоциональные проблемы Вас сейчас волнуют, укажите их выраженность?</h2>
                        
                                <span className="body-text-small reg-block-item rate-block">
                                    <span className="rate-header">Состояние стресса</span>
                                    <div className="rate-line" >
                                        <div onClick={() => this.onRate(0, 0)} style={this.state.rate[0] === 0 ? {background: 'white'} : undefined} className="rate-item">0</div>
                                        <div onClick={() => this.onRate(0, 1)} style={this.state.rate[0] === 1 ? {background: 'white'} : undefined} className="rate-item">1</div>
                                        <div onClick={() => this.onRate(0, 2)} style={this.state.rate[0] === 2 ? {background: 'white'} : undefined} className="rate-item">2</div>
                                        <div onClick={() => this.onRate(0, 3)} style={this.state.rate[0] === 3 ? {background: 'white'} : undefined} className="rate-item">3</div>
                                        <div onClick={() => this.onRate(0, 4)} style={this.state.rate[0] === 4 ? {background: 'white'} : undefined} className="rate-item">4</div>
                                        <div onClick={() => this.onRate(0, 5)} style={this.state.rate[0] === 5 ? {background: 'white'} : undefined} className="rate-item">5</div>
                                    </div>
                                </span>
                                <span className="body-text-small reg-block-item rate-block">
                                    <span className="rate-header">Тревожность и внутреннее напряжение</span>
                                    <div className="rate-line" >
                                        <div onClick={() => this.onRate(1, 0)} style={this.state.rate[1] === 0 ? {background: 'white'} : undefined} className="rate-item">0</div>
                                        <div onClick={() => this.onRate(1, 1)} style={this.state.rate[1] === 1 ? {background: 'white'} : undefined} className="rate-item">1</div>
                                        <div onClick={() => this.onRate(1, 2)} style={this.state.rate[1] === 2 ? {background: 'white'} : undefined} className="rate-item">2</div>
                                        <div onClick={() => this.onRate(1, 3)} style={this.state.rate[1] === 3 ? {background: 'white'} : undefined} className="rate-item">3</div>
                                        <div onClick={() => this.onRate(1, 4)} style={this.state.rate[1] === 4 ? {background: 'white'} : undefined} className="rate-item">4</div>
                                        <div onClick={() => this.onRate(1, 5)} style={this.state.rate[1] === 5 ? {background: 'white'} : undefined} className="rate-item">5</div>
                                    </div>
                                </span>
                                <span className="body-text-small reg-block-item rate-block">
                                    <span className="rate-header">Панические атаки</span>
                                    <div className="rate-line" >
                                        <div onClick={() => this.onRate(2, 0)} style={this.state.rate[2] === 0 ? {background: 'white'} : undefined} className="rate-item">0</div>
                                        <div onClick={() => this.onRate(2, 1)} style={this.state.rate[2] === 1 ? {background: 'white'} : undefined} className="rate-item">1</div>
                                        <div onClick={() => this.onRate(2, 2)} style={this.state.rate[2] === 2 ? {background: 'white'} : undefined} className="rate-item">2</div>
                                        <div onClick={() => this.onRate(2, 3)} style={this.state.rate[2] === 3 ? {background: 'white'} : undefined} className="rate-item">3</div>
                                        <div onClick={() => this.onRate(2, 4)} style={this.state.rate[2] === 4 ? {background: 'white'} : undefined} className="rate-item">4</div>
                                        <div onClick={() => this.onRate(2, 5)} style={this.state.rate[2] === 5 ? {background: 'white'} : undefined} className="rate-item">5</div>
                                    </div>
                                </span>
                                <span className="body-text-small reg-block-item rate-block">
                                    <span className="rate-header">Депрессия и апатия</span>
                                    <div className="rate-line" >
                                        <div onClick={() => this.onRate(3, 0)} style={this.state.rate[3] === 0 ? {background: 'white'} : undefined} className="rate-item">0</div>
                                        <div onClick={() => this.onRate(3, 1)} style={this.state.rate[3] === 1 ? {background: 'white'} : undefined} className="rate-item">1</div>
                                        <div onClick={() => this.onRate(3, 2)} style={this.state.rate[3] === 2 ? {background: 'white'} : undefined} className="rate-item">2</div>
                                        <div onClick={() => this.onRate(3, 3)} style={this.state.rate[3] === 3 ? {background: 'white'} : undefined} className="rate-item">3</div>
                                        <div onClick={() => this.onRate(3, 4)} style={this.state.rate[3] === 4 ? {background: 'white'} : undefined} className="rate-item">4</div>
                                        <div onClick={() => this.onRate(3, 5)} style={this.state.rate[3] === 5 ? {background: 'white'} : undefined} className="rate-item">5</div>
                                    </div>
                                </span>
                                <span className="body-text-small reg-block-item rate-block">
                                    <span className="rate-header">Выгорание</span>
                                    <div className="rate-line" >
                                        <div onClick={() => this.onRate(4, 0)} style={this.state.rate[4] === 0 ? {background: 'white'} : undefined} className="rate-item">0</div>
                                        <div onClick={() => this.onRate(4, 1)} style={this.state.rate[4] === 1 ? {background: 'white'} : undefined} className="rate-item">1</div>
                                        <div onClick={() => this.onRate(4, 2)} style={this.state.rate[4] === 2 ? {background: 'white'} : undefined} className="rate-item">2</div>
                                        <div onClick={() => this.onRate(4, 3)} style={this.state.rate[4] === 3 ? {background: 'white'} : undefined} className="rate-item">3</div>
                                        <div onClick={() => this.onRate(4, 4)} style={this.state.rate[4] === 4 ? {background: 'white'} : undefined} className="rate-item">4</div>
                                        <div onClick={() => this.onRate(4, 5)} style={this.state.rate[4] === 5 ? {background: 'white'} : undefined} className="rate-item">5</div>
                                    </div>
                                </span>
                                <span className="body-text-small reg-block-item rate-block">
                                    <span className="rate-header">Частая смена настроения</span>
                                    <div className="rate-line" >
                                        <div onClick={() => this.onRate(5, 0)} style={this.state.rate[5] === 0 ? {background: 'white'} : undefined} className="rate-item">0</div>
                                        <div onClick={() => this.onRate(5, 1)} style={this.state.rate[5] === 1 ? {background: 'white'} : undefined} className="rate-item">1</div>
                                        <div onClick={() => this.onRate(5, 2)} style={this.state.rate[5] === 2 ? {background: 'white'} : undefined} className="rate-item">2</div>
                                        <div onClick={() => this.onRate(5, 3)} style={this.state.rate[5] === 3 ? {background: 'white'} : undefined} className="rate-item">3</div>
                                        <div onClick={() => this.onRate(5, 4)} style={this.state.rate[5] === 4 ? {background: 'white'} : undefined} className="rate-item">4</div>
                                        <div onClick={() => this.onRate(5, 5)} style={this.state.rate[5] === 5 ? {background: 'white'} : undefined} className="rate-item">5</div>
                                    </div>
                                </span>
                                <span className="body-text-small reg-block-item rate-block">
                                    <span className="rate-header">Гнев, агрессия и раздражительность</span>
                                    <div className="rate-line" >
                                        <div onClick={() => this.onRate(6, 0)} style={this.state.rate[6] === 0 ? {background: 'white'} : undefined} className="rate-item">0</div>
                                        <div onClick={() => this.onRate(6, 1)} style={this.state.rate[6] === 1 ? {background: 'white'} : undefined} className="rate-item">1</div>
                                        <div onClick={() => this.onRate(6, 2)} style={this.state.rate[6] === 2 ? {background: 'white'} : undefined} className="rate-item">2</div>
                                        <div onClick={() => this.onRate(6, 3)} style={this.state.rate[6] === 3 ? {background: 'white'} : undefined} className="rate-item">3</div>
                                        <div onClick={() => this.onRate(6, 4)} style={this.state.rate[6] === 4 ? {background: 'white'} : undefined} className="rate-item">4</div>
                                        <div onClick={() => this.onRate(6, 5)} style={this.state.rate[6] === 5 ? {background: 'white'} : undefined} className="rate-item">5</div>
                                    </div>
                                </span>
                                

                    </div>
                </div>

                <div className="content-block">
                    <div>
                        <h2 className="reg-block-header">Есть ли у Вас проблемы в мышлении и поведении?</h2>
                        
                        <div className="reg-columns" >
                            <div className="reg-column-block">

                                {
                                
                                this.state.problemsList.filter(x => x.id < 4).map((item, index) =>

                                    <span key={index} className="body-text-small reg-block-item">
                                        <input type="checkbox" id={'problems' + item.id} checked={this.state.selectedProblems.findIndex(x => x == item.id) !== -1} onChange={() => this.setState({selectedProblems: this.toggle(item.id, this.state.selectedProblems)})}/>      
                                        <label htmlFor={'problems' + item.id}>{item.title}</label>
                                    </span>
                                )

                                }
                                
                            </div>

                            <div className="reg-column-block">
                                {
                                
                                this.state.problemsList.filter(x => x.id > 3).map((item, index) =>

                                    <span key={index} className="body-text-small reg-block-item">
                                        <input type="checkbox" id={'problems' + item.id} checked={this.state.selectedProblems.findIndex(x => x == item.id) !== -1} onChange={() => this.setState({selectedProblems: this.toggle(item.id, this.state.selectedProblems)})}/>     
                                        <label htmlFor={'problems' + item.id}>{item.title}</label>
                                    </span>
                                )

                                }
                            </div>
                        </div>

                    </div>
                </div>


                <div className="content-block">
                    <div>
                        <h2 className="reg-block-header">Волнуют ли Вас физические симптомы?</h2>
                        
                        <div className="reg-columns" >
                            <div className="reg-column-block">
                                
                                {
                                    
                                    this.state.physical.map((item, index) =>

                                        <span key={index} className="body-text-small reg-block-item">
                                            <input type="checkbox" id={'physical' + item.id} checked={this.state.selectedPhysical.findIndex(x => x == item.id) !== -1} onChange={() => this.setState({selectedPhysical: this.toggle(item.id, this.state.selectedPhysical)})}/>    
                                            <label htmlFor={'physical' + item.id}>{item.title}</label>
                                        </span>
                                    )

                                }
                                
                            </div>
                        </div>

                    </div>
                </div>



                <div className="content-block">
                    <div>
                        <h2 className="reg-block-header">Есть ли у Вас сопутствующие проблемы в жизни?</h2>
                        
                            
                            {
                                
                                this.state.factors.map((item, index) =>

                                    <span key={index} className="body-text-small reg-block-item">
                                        <input type="checkbox" id={'factors' + item.id} checked={this.state.selectedFactors.findIndex(x => x == item.id) !== -1} onChange={() => this.setState({selectedFactors: this.toggle(item.id, this.state.selectedFactors)})}/>   
                                        <label htmlFor={'factors' + item.id}>{item.title}</label>
                                    </span>
                                )

                            }

                            <span className="body-text-small reg-block-item">
                                <input type="checkbox" id='factors-own' checked={this.state.factorsOwnShow} onChange={() => this.setState({factorsOwnShow: !this.state.factorsOwnShow})}/> 
                                <label htmlFor='factors-own'>Указать свой вариант</label>
                            </span>

                            { this.state.factorsOwnShow ?
                                <span className="body-text-small reg-block-item">
                                    <textarea style={{width: '300px'}} value={this.state.ownFactor} onChange={(e) => this.setState({ownFactor: e.target.value})}  placeholder="Указать свой вариант (80 символов)"></textarea>
                                </span>               
                            :
                                undefined
                            }      
                        </div>

                </div>


                
                <div className="content-block">
                    <div>
                        <h2 className="reg-block-header">Что для Вас наиболее важно получить от консультаций со специалистом?</h2>
                        
                        <div className="reg-columns" >
                            <div className="reg-column-block">
                            {
                                
                                this.state.valuables.map((item, index) =>

                                    <span key={index} className="body-text-small reg-block-item">
                                        <input type="checkbox" id={'valuables' + item.id} checked={this.state.selectedValuables.findIndex(x => x == item.id) !== -1} onChange={() => this.setState({selectedValuables: this.toggle(item.id, this.state.selectedValuables)})}/>  
                                        <label htmlFor={'valuables' + item.id}>{item.title}</label>
                                    </span>
                                )

                            }
                            </div>
                        </div>

                    </div>
                </div>



                <div className="content-block">
                    <div>
                        <h2 className="reg-block-header">К каким изменениям Вам хотелось бы прийти?</h2>
                        
                        <div className="reg-columns" >
                            <div className="reg-column-block">
                            {
                                
                                this.state.target.map((item, index) =>

                                    <span key={index} className="body-text-small reg-block-item">
                                        <input type="checkbox" id={'target' + item.id} checked={this.state.selectedTargets.findIndex(x => x == item.id) !== -1} onChange={() => this.setState({selectedTargets: this.toggle(item.id, this.state.selectedTargets)})}/> 
                                        <label htmlFor={'target' + item.id}>{item.title}</label>
                                    </span>
                                )

                            }

                            <span className="body-text-small reg-block-item">
                                <input type="checkbox" id='target-own' checked={this.state.targetOwnShow} onChange={() => this.setState({targetOwnShow: !this.state.targetOwnShow})}/> 
                                <label htmlFor='target-own'>Cвой вариант</label>
                            </span>

                            { this.state.targetOwnShow ?
                                <span className="body-text-small reg-block-item">
                                    <textarea style={{width: '300px'}} value={this.state.ownTarget} onChange={(e) => this.setState({ownTarget: e.target.value})} placeholder="Указать свой вариант (80 символов)"></textarea>
                                </span>               
                            :
                                undefined
                            }    
                            </div>
                        </div>

                    </div>
                </div>


                
                <div className="content-block">
                    <div>
                        <h2 className="reg-block-header">Сфера вашей профессиональной деятельности</h2>
                        
                        <div className="reg-columns" >
                            <div className="reg-column-block">
                            {
                                
                                this.state.jobs.map((item, index) =>

                                    <span key={index} className="body-text-small reg-block-item">
                                        <input type="checkbox" id={'jobs' + item.id} checked={this.state.selectedJobs.findIndex(x => x == item.id) !== -1} onChange={() => this.setState({selectedJobs: this.toggle(item.id, this.state.selectedJobs)})} /> 
                                        <label htmlFor={'jobs' + item.id}>{item.title}</label>
                                    </span>
                                )

                            }


                            { this.state.selectedJobs.findIndex(x => x == 6) !== -1 ?
                                    <span className="body-text-small reg-block-item">
                                        <textarea style={{width: '300px'}} value={this.state.ownJob} onChange={(e) => this.setState({ownJob: e.target.value})}  placeholder="Указать свой вариант (80 символов)"></textarea>
                                    </span>               
                                :
                                    undefined
                                }      
                            </div>
                        </div>

                    </div>
                </div>

*/}

                <div className="content-block">
                    <h2 className="reg-block-header">С кем Вам было бы комфортнее работать?</h2>
                    
                    <div className="reg-columns">
                        <div className="reg-column-block" style={{marginRight: '1rem'}}>
                            <span className="body-text-small reg-block-item">
                                <input checked={this.state.sex == null} onChange={() => this.setState({sex: null})} type="checkbox" id="anySex"/> 
                                <label htmlFor="anySex">Пол неважен</label>
                            </span>
                            <span className="body-text-small reg-block-item">
                                <input checked={this.state.sex === "Male"} onChange={() => this.setState({sex: "Male"})} type="checkbox" id="male"/> 
                                <label htmlFor="male">С мужчиной</label>
                            </span>
                            <span className="body-text-small reg-block-item">
                                <input checked={this.state.sex === "Female"} onChange={() => this.setState({sex: "Female"})} type="checkbox" id="female"/> 
                                <label htmlFor="female">С женщиной</label>
                            </span>
                        </div>

                    </div>
                </div>
                

                <div className="content-block">
                        <h2 className="reg-block-header">Выберите время сессии</h2>
                        {<TimezoneSelector timezone={this.state.timezone} onChange={(tz) => this.setState({timezone: tz})}/>}
                        <div className="reg-block-toggle">
                            <div onClick={() => this.setState({timeType: 1, timePeriods: []})} className={"reg-toggle toggle-left" + (this.state.timeType === 1 ? ' toggle-active' : '')}>Ближайшее</div>
                            <div onClick={() => this.setState({timeType: 2})} className={"reg-toggle toggle-right" + (this.state.timeType === 2 ? ' toggle-active' : '')}>Конкретное</div>
                        </div>
                        {this.state.timeType === 1 ?
                            <div style={{marginTop: '18px'}}>Сначала покажем психологов, свободных в ближайшее время</div>
                        :
                        <>
                            <div className="concrete-time-title">Сначала покажем психологов, свободных в указанные вами интервалы времени</div>
                            <div className="concrete-time">
                                <div>
                                    <div className="timeblock-title">Понедельник — пятница:</div>
                                    <div className="timeblock-container">
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekdayMorning")} onChange={() => this.toggleTimePeriod("WeekdayMorning")} type="checkbox" id="time1"/>
                                            <label htmlFor="time1">До 10:00</label>
                                        </div>
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekdayAfternoon")} onChange={() => this.toggleTimePeriod("WeekdayAfternoon")} type="checkbox" id="time2"/>
                                            <label htmlFor="time2">10:00 — 18:00</label>
                                        </div>
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekdayEvening")} onChange={() => this.toggleTimePeriod("WeekdayEvening")} type="checkbox" id="time3"/>
                                            <label htmlFor="time3">После 18:00</label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="timeblock-title">Суббота и воскресенье:</div>
                                    <div className="timeblock-container">
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekendMorning")} onChange={() => this.toggleTimePeriod("WeekendMorning")} type="checkbox" id="time4"/>
                                            <label htmlFor="time4">До 10:00</label>
                                        </div>
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekendAfternoon")} onChange={() => this.toggleTimePeriod("WeekendAfternoon")} type="checkbox" id="time5"/>
                                            <label htmlFor="time5">10:00 — 18:00</label>
                                        </div>
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekendEvening")} onChange={() => this.toggleTimePeriod("WeekendEvening")} type="checkbox" id="time6"/>
                                            <label htmlFor="time6">После 18:00</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                </div>


                <div className="register-input-block">
                    <h2>Информация для специалиста</h2>
                    <input type="text" placeholder="Ваше имя" value={this.state.name} onChange={(e) => this.inputName(e.target.value)} />
                    <input type="text" placeholder="Возраст" onChange={(e) => this.inputAge(e.target.value)} value={this.state.age}/> 
                </div>
  
                {this.state.messages?.map((x, index) => <div key={index} style={{color: 'red'}}>{x}</div>)}

                <div style={{marginBottom: '20px'}} className="reg-controls">
                    <button onClick={this.register} className="reg-button">
                        <span className="button-text">Вперед</span>
                        <img src="images/icons/right.svg"/>
                    </button>
                </div>
            </div>
        </>
    }
}

export default MobileEmotionsSurvey;