import React from "react";
import { Navigate } from "react-router";
import connection from "../scripts/connection";
import Loader from "./Loader";


class ClientsList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {

        connection.Get('/categories',
        (success) => {
            console.log('Categories:',success);

            let subcategoriesArray = success.value.map(i => i.subCategories);
            let subcategories = [];

            for (let i = 0; i < subcategoriesArray.length; i++)
                subcategories = subcategories.concat(subcategoriesArray[i]);
            
            let subcategoriesDictionary = {};
            subcategories.forEach(i => subcategoriesDictionary[i.id] = i.title);

            this.setState({
                categories: subcategoriesDictionary,
                categoriesLoaded: true
            })
        },
        (error) => {
            console.log(error);
        },
        (fatalError) => {
            console.log(fatalError);
        });

        connection.AuthorizeGet('/specialist/clients',
        (success) => {
            console.log(success)
            this.setState({clients: success.value})
        },
        (error) => {
            console.log(error)
        },
        (fatal) => {
            console.log(fatal)
        })
    }

    viewProfile = (clientId) => {
        localStorage.setItem('clientId', clientId);
        this.setState({redirect: true});
    }

    render() {
        if (!this.state.clients || !this.state.categoriesLoaded)
            return <div style={{height: '600px'}}><Loader /></div>;;

        if (this.state.redirect)
            return <Navigate to='/client'/>

        return <div className="big-card">
            <h1 className="person-list-header">Мои клиенты</h1>
            <div className="person-list">
                {this.state.clients.map((item, index) => {
                return <div key={index} className="person-info client-info">
                        <div>
                            <h2 className="person-info-item">{item.name}; {item.age ?? 'не указан'}</h2>
                            <p className="person-about person-info-item">
                                {item.categoryItems.map(item => this.state.categories[item]).join(', ')}
                            </p>
                            {item.hasUnreadMessages ?
                            <p style={{color: 'orange'}}>
                                Есть непрочитанные сообщения
                            </p>
                            :
                            null}
                            <span onClick={() => this.viewProfile(item.id)} className="personlist-details">
                                Перейти к клиенту  
                            </span>
                        </div>
                    </div>
                })
                }
                
            </div>
        </div>;
    }
}

export default ClientsList;