import React from "react";

import { OpenVidu } from "openvidu-browser";
import {HttpTransportType, HubConnectionBuilder} from "@microsoft/signalr";
import NextConsulatations from "./NextConsultations";
import connection from "../scripts/connection";
import UserVideoComponent from "./video/UserVideoComponent";
import { toHaveDisplayValue } from "@testing-library/jest-dom/dist/matchers";
import { isExpired, decodeToken } from "react-jwt";
import DateTimeSelector from "./DateTimeSelector";
import MessageModal from "./MessageModal";
import ConsultationBookingModal from "./ConsultationBookingModal";
import Chat from "./Chat";
import DevicesCheckModal from "./devicescheck/DevicesCheckModal";
import SelectRoomModal from "./SelectRoomModal";


class SpecialistRoom extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            subscribers: [],

            camShow: true,
            micEnabled: true,

            deviceCheck: false
        }

        // Добавить время на начавшейся консультации
        // Добавить кнопки на начавшейся консультации (Чат, Назначение)
        this.connectVideo = this.connectVideo.bind(this);
    }

    componentDidMount() {
        this.connectSignalR();

        this.connectChatPipeline();
    }

    componentWillUnmount() {

        this.state.hubConnection?.stop();

        this.state.session?.disconnect()
        this.OV = null;
    }

    parseSeconds = (timeInSeconds) => {
        let minutes = parseInt(timeInSeconds / 60);
        let seconds = parseInt(timeInSeconds % 60);
        return (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);
    }


    getSpecialistId = () => {
        let tokenData = decodeToken(localStorage.getItem('token'));

        return parseInt(tokenData.id);
    }

    connectChatPipeline = () => {
        const chatHubConnection = new HubConnectionBuilder().withUrl(process.env.REACT_APP_API_URL + '/chat', {
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => localStorage.getItem('token')
        }).withAutomaticReconnect().build();

        console.log("mount")

        this.setState({ chatHubConnection }, () => {
            this.state.chatHubConnection
                .start()
                .then(() => {
                    console.log('Connection started!');

                    //this.state.chatHubConnection.invoke('ReceiveChatMessages', this.props.specialistId, this.props.clientId)
                    //.catch(err => console.log(err));

                    this.state.chatHubConnection.invoke('SpecialistHasUnreadMessage')
                    .catch(err => console.log(err));
                })
                .catch(err => console.log('Error while establishing connection :(', err));


            this.state.chatHubConnection.onreconnecting(error => {

                console.error('Connection lost: ', error);
            });

           
            this.state.chatHubConnection.on('ReceiveMessage', (message) => {

                console.log('Received message');


                if (!this.state.showChat)
                    this.setState({hasUnreadmessages: true});

                // Chat Id
                // 
            });
        });

    }
    

    connectSignalR = () => {
        const hubConnection = new HubConnectionBuilder().withUrl(process.env.REACT_APP_API_URL + '/room/stream', {
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => localStorage.getItem('token')
        }).withAutomaticReconnect().build();

        this.setState({ hubConnection }, () => {
            this.state.hubConnection
                .start()
                .then(() => {
                    console.log('Connection started!');

                    this.state.hubConnection.invoke('SendAgenda');
                    this.state.hubConnection.invoke('SendActiveConsultations');

                })
                .catch(err => console.log('Error while establishing connection :(', err));


            this.state.hubConnection.onreconnecting(error => {

                console.error('Connection lost: ', error);
            })

            this.state.hubConnection.on('ReceiveToken', (token, expiresIn) => {
                console.log('Received token', token);
                console.log('ExpiresIn', expiresIn);
                this.connectVideo(token);
               // this.setState({token: token});
               // Запускаем таймер здесь на отчет времени сессии
               // Передать данные в след формате:
               // Кол-во секунд до конца
               // Прибавить к текущей дате
               // По таймеру от нее отсчитывать
               console.log(expiresIn)
               let endDate = new Date().setSeconds(new Date().getSeconds() + expiresIn);
               console.log(endDate);
               let sessionDurationTime = setInterval(() => {
                let timeLeft = ((this.state.endDate ?? endDate) - new Date()) / 1000; 
                if (timeLeft < 300 && !this.state.endMessageShown)
                {
                    this.setState({showEndMessage: true, endMessageShown: true});
                }
                if (timeLeft > 0)
                {
                    this.setState({sessionDuration: this.parseSeconds(timeLeft)});
                }
                else
                {
                    clearInterval(this.state.sessionDurationTime);
                }

               }
               ,1000);

               this.setState({sessionDurationTime: sessionDurationTime, sessionDuration: '--:--', endDate: endDate});
            })

            this.state.hubConnection.on('ReceiveAgenda', (agenda) => {
                console.log("received agenda", agenda)

                this.setState({timeLeft: undefined});
                let roomStartTimer = null;
                if (agenda)
                {
                    let expireDate = new Date();
                    expireDate.setSeconds(expireDate.getSeconds() + agenda.timeLeft)
                    
                    roomStartTimer = setInterval(() => {
                        //let timeLeft = this.state.timeLeft ?? agenda.timeLeft;
                        let timeLeft = (expireDate.getTime() - new Date().getTime()) / 1000;

                        if (timeLeft > 0)
                        {
                            timeLeft--;
                            this.setState({timeLeft: timeLeft});
                        }
                        else
                        {
                            clearInterval(this.state.roomStartTimer);
                            this.setState({timeLeft: 0});
                        }
                        
                    }, 1000);
                }


                this.setState({openRoom: true, agenda: agenda, roomStartTimer: roomStartTimer, consultationType: "Single"})
            });

            this.state.hubConnection.on('ReceiveActiveConsultations', (consultations) =>{

                console.log('Receive active consultations:', consultations)
                this.setState({consultations});
            })

            this.state.hubConnection.on('SessionErrorOccured', (error) => {
                console.log("Received session error:", error);
            })

            this.state.hubConnection.on('BookingApproved', (offer) => {
                console.log("Booking approved:", offer);

                // Показать окно
                this.setState({message: "Консультация забронирована"})
            })
        });
    }

    roomCloseModal = () => {
        return <div className="modal-block">
                    <div className="modal-inside body-text-small" onClick={(e) => e.stopPropagation()}>
                        <h1>Завершение консультации</h1>
                        <div style={{marginTop: '1rem', marginBottom: '1rem'}}>
                            Вы уверены, что хотите завершить консультацию?
                        </div>
                        <div style={{display: 'flex', justifyContent: 'end', gap: '1rem'}}>
                            <button onClick={() => this.setState({leaveModal: undefined})}>Нет</button>
                            <button onClick={() => this.leaveSession()}>Да</button>
                        </div>
                    </div>
                </div>;
    }


    consultationTime = () => {
        let dt = new Date(this.state?.agenda?.closestDate);
        let day = dt.getUTCDate() < 10 ? '0' + dt.getUTCDate() : dt.getUTCDate();
        let month = (dt.getUTCMonth() + 1) < 10 ? '0' + (dt.getUTCMonth() + 1) : (dt.getUTCMonth() + 1);
        let year = dt.getUTCFullYear() % 100;

        let minutes = dt.getUTCMinutes() < 10 ? '0' + dt.getUTCMinutes() : dt.getUTCMinutes();
        let hours = dt.getUTCHours() < 10 ? '0' + dt.getUTCHours() : dt.getUTCHours();

        return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;
    }

    roomOpen = (sessionId) => {
        if (!localStorage.getItem('devicesChecked'))
        {
            this.setState({roomEnter: true, deviceCheck: true});
            return;
        }

        this.state.hubConnection.invoke('ConnectConsultation', sessionId);
        this.setState({consultationStarted: true});
    }

    deleteSubscriber(streamManager) {
        let subscribers = this.state.subscribers;
        let index = subscribers.indexOf(streamManager, 0);
        if (index > -1) {
            subscribers.splice(index, 1);
            this.setState({
                subscribers: subscribers,
            });
        }
    }

    leaveSession = () => {

        console.log('Leave session')
        this.state.hubConnection.invoke('CompleteConsultation', this.state.agenda.sessionId);
        this.setState({leaveModal: undefined});
    }

    connectVideo(token) {
        // --- 1) Get an OpenVidu object ---

        this.OV = new OpenVidu();

        // --- 2) Init a session ---

        this.setState(
            {
                session: this.OV.initSession(),
            },
            () => {
                var mySession = this.state.session;

                // --- 3) Specify the actions when events take place in the session ---

                // On every new Stream received...
                mySession.on('streamCreated', (event) => {
                    // Subscribe to the Stream to receive it. Second parameter is undefined
                    // so OpenVidu doesn't create an HTML video by its own
                    var subscriber = mySession.subscribe(event.stream, undefined);
                    var subscribers = this.state.subscribers;
                    //subscribers = []
                    subscribers.push(subscriber);

                    // Update the state with the new subscribers
                    this.setState({
                        subscribers: subscribers,
                    });
                });

                mySession.on('sessionDisconnected', (event) => {
                    
                    this.OV = null;
                    this.setState({
                        session: undefined,
                        subscribers: [],
                        mySessionId: 'SessionA',
                        myUserName: 'Participant' + Math.floor(Math.random() * 100),
                        mainStreamManager: undefined,
                        publisher: undefined,
                        consultationStarted: false,
                        openRoom: false,
                        agenda: undefined
                    });
            
            
                    this.state.hubConnection.invoke('SendAgenda');
                });

                // On every Stream destroyed...
                mySession.on('streamDestroyed', (event) => {

                    // Remove the stream from 'subscribers' array
                    this.deleteSubscriber(event.stream.streamManager);
                });

                // On every asynchronous exception...
                mySession.on('exception', (exception) => {
                    console.warn(exception);
                });

                // --- 4) Connect to the session with a valid user token ---

                // Get a token from the OpenVidu deployment
 
                    // First param is the token got from the OpenVidu deployment. Second param can be retrieved by every user on event
                    // 'streamCreated' (property Stream.connection.data), and will be appended to DOM as the user's nickname
                mySession.connect(token, {})
                    .then(async () => {

                        // --- 5) Get your own camera stream ---


                        // Obtain the current video device in use
                        let camId = localStorage.getItem('camId');
                        let micId = localStorage.getItem('micId');
            


                        // Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
                        // element: we will manage it on our own) and with the desired properties
                        let publisher = await this.OV.initPublisherAsync(undefined, {
                            audioSource: micId ?? undefined, // The source of audio. If undefined default microphone
                            videoSource: camId ?? undefined, // The source of video. If undefined default webcam
                            publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
                            publishVideo: true, // Whether you want to start publishing with your video enabled or not
                            resolution: '1920x1080', 
                            frameRate: 30, // The frame rate of your video
                            insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                            mirror: true, // Whether to mirror your local video or not
                        });


                        var devices = await this.OV.getDevices();
                        var videoDevices = devices.filter(device => device.kind === 'videoinput');
                        var currentVideoDeviceId = publisher.stream.getMediaStream().getVideoTracks()[0].getSettings().deviceId;
                        var currentVideoDevice = videoDevices.find(device => device.deviceId === currentVideoDeviceId);

                        // --- 6) Publish your stream ---

                        mySession.publish(publisher);


                        // Set the main video in the page to display our webcam and store our Publisher
                        this.setState({
                            currentVideoDevice: currentVideoDevice,
                            mainStreamManager: publisher,
                            publisher: publisher,
                        });
                    })
                    .catch((error) => {
                        console.log('There was an error connecting to the session:', error.code, error.message);
                    });
            });
    }


    changeVidState = () => {
        let vidState = !this.state.camShow;
        this.state.publisher.publishVideo(vidState);
        this.setState({camShow: vidState});
    }

    changeMicState = () => {
        let micState = !this.state.micEnabled;
        this.state.publisher.publishAudio(micState);
        this.setState({micEnabled: micState});
    }

    offerBooking = () => {
        this.setState({showOfferWindow: true})
    }

    offerTimeSelected = (time, consultationType) => {
        console.log("Offer time:", time);

        this.state.hubConnection.invoke('BookingOffer', parseInt(this.state.agenda.clientId), time, this.state.consultationType);

        this.setState({showOfferWindow: false});
    }
    deviceCheckCompleted = () => {
        localStorage.setItem('devicesChecked', true);
        let roomEnter = this.state.roomEnter;
        this.setState({deviceCheck: false, roomEnter: false}, () => {
            if (roomEnter)
                this.setState({showOpenConsultation: true});
        })
    }

    render() {
        return <div className="big-card body-text-small ">
            
            <DevicesCheckModal showDevicesCheck={this.state.deviceCheck} onCompleted={() => this.deviceCheckCompleted()}/>

            { this.state.showChat ?
                <Chat specialistId={this.state.agenda?.specialistId} clientId={this.state.agenda?.clientId} receiverId={this.state.agenda?.clientId} show={this.state.showChat} onHide={() => this.setState({showChat: false})}/> 
            :
                undefined
            }

            {this.state.showEndMessage ?
                <MessageModal onHide={() => this.setState({showEndMessage: false})} message={"Через 5 минут сессия будет завершена"} header={'Информация'}/>
                :
                undefined
            }

            {this.state.message ?
                <MessageModal onHide={() => this.setState({message: undefined})} message={this.state.message} header={'Информация'}/>
            :
                undefined
            }

            {this.state.showConsultations ?
                <NextConsulatations show={true} onHide={() => this.setState({showConsultations: false})} />
            :
                undefined
            }

            {this.state.showOpenConsultation ?
                <SelectRoomModal 
                    onHide={() => this.setState({showOpenConsultation: false})} 
                    consultations={this.state.consultations} 
                    onConsEnter={(roomId) => {this.setState({showOpenConsultation: false}, () => this.roomOpen(roomId))}}
                />
            :
                undefined
            }



            {this.state.showOfferWindow ?
                <ConsultationBookingModal show={true}
                                hideHeader={true}
                                specialistId={decodeToken(localStorage.getItem('token')).id}
                                onSelectedTime={(time, consultationType) => this.offerTimeSelected(time, consultationType)}
                                onHide={() => this.setState({showOfferWindow: false})}
                                
                                consultationType={this.state.consultationType}
                                consultationTypeSelector={true}
                                onConsultationTypeSelected={(consultationType) => this.setState({consultationType: consultationType})}
                                />
            :
                undefined
            }

            {this.state.leaveModal ?
                this.roomCloseModal()
            :
                undefined
            }

            {this.state.mainStreamManager ?
                    <>
                        <div>До конца сессии: {this.state.sessionDuration}</div>
                        <UserVideoComponent streamManager={this.state.subscribers.length !== 0 ? this.state.subscribers[this.state.subscribers.length - 1] : undefined}
                                        changeCamState={this.changeVidState}
                                        changeMicState={this.changeMicState}
                                        camShow={this.state.camShow}
                                        micEnabled={this.state.micEnabled}
                                        myVideoManager={this.state.publisher}
                                        leaveConsultation={() => this.setState({leaveModal: true})}
                        />
                    </>
                    :
                    
            <div className="video-block">
                <div className="video-block-content">
                    {!this.state.consultationStarted ?
                        this.state.agenda === undefined ?
                        <span style={{textAlign: 'center'}}>Получение информации о консультациях...</span>
                        :
                        this.state.agenda !== null ?
                        <span style={{textAlign: 'center'}}>Консультация запланирована на: {this.consultationTime()}</span>
                        :
                        <span style={{textAlign: 'center'}}>Нет запланированных консультаций</span>
                        
                        : undefined
                    }
                    {/*
                        // Если консультация началась - Кнопка войти
                        this.state.timeLeft == 0 && !this.state.consultationStarted ? 
                            <button onClick={this.roomOpen}>Открыть комнату</button>
                        :
                        undefined
                */}
                    {
                        // Если консультация началась - Кнопка войти
                        this.state.timeLeft == 0 && !this.state.consultationStarted ? 
                            <button style={{padding: '25px', width: 'auto'}} onClick={() => this.setState({showOpenConsultation: true})}>Подключиться к сессии</button>
                        :
                            <button style={{padding: '25px', width: 'auto', background: 'gray'}}>Подключиться к сессии</button>
                    }
                    {
                        // Проверка оборудования
                        !this.state.consultationStarted ? 
                            <button style={{padding: '25px', width: 'auto'}} onClick={() => this.setState({deviceCheck: true})}>Проверка оборудования</button>
                        :
                            undefined
                    }
                    
                </div>
            </div>
    }
            <div style={{display: 'flex', flexFlow: 'wrap'}}>
            <button onClick={() => this.setState({showConsultations: true})} style={{marginTop: '1rem'}} className="button-white payments-button video-button">
                <img src="images/icons/blueCalendar.svg"/>
                <span>Следующие сессии</span>
            </button>
            {this.state.hasUnreadmessages ? <div style={{marginTop: 'auto', marginBottom: 'auto'}}>У вас есть непрочитанные сообщения</div>: null}
            {this.state.consultationStarted ?
                <>
                    <button onClick={this.offerBooking} style={{marginTop: '1rem'}} className="button-white payments-button video-button">
                        <img src="images/icons/blueCalendar.svg"/>
                        <span>Предложить бронирование</span>
                    </button>
                    <button onClick={() => this.setState({showChat: true})} style={{marginTop: '1rem'}} className="button-white payments-button video-button">
                        Чат с клиентом
                    </button>
                </>
            :
                undefined
            }
            </div>
        </div>;
    }
}

export default SpecialistRoom;