import React from "react";

import { Stack, Dropdown, DropdownButton } from "react-bootstrap";



function DeviceSelector(props) {
    return (<>
        <Stack gap={3} style={{alignSelf: 'center'}}>
            <div className="device-selector-message">
                Выберите устройство
            </div>
            <Stack direction="horizontal" gap={2} style={{position: 'relative', alignSelf: 'center'}}>
                <img className="device-selector-icon" src={props.iconPath} />
                <DropdownButton variant="device-selector" title={props.selectedDevice.label}>
                    {props.devices.length === 1 ? <Dropdown.Item as="button" style={{width: '222px'}} /> : undefined}

                    {props.devices.filter(item => item !== props.selectedDevice).map((item, index) => {
                    
                    return <div key={index}>
                        <Dropdown.Item  onClick={() => props.onSelectDevice(item)} as="button" style={{width: '222px', whiteSpace: 'pre-wrap'}}>{item.label}</Dropdown.Item>
                        {(props.devices.length - 2) !== index ?
                        <Dropdown.Divider  />
                        : undefined }
                    </div>
                    })
                    }
                </DropdownButton>
            </Stack>
            { props.failed ?
            <div style={{color: 'red'}}>Попробуйте выбрать другое устройство</div>
            : undefined
            }
        </Stack>
    </>);
}

export default DeviceSelector;