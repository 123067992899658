import { Stack, Button } from "react-bootstrap";


function GetPermissionContent() {
    return (
        <Stack gap={4} style={{alignItems: 'center'}}>
            <div className="device-test-message" style={{fontSize: '1.5rem'}}>
                Ожидание выдачи разрешения на использование устройств
            </div>
            <div className="device-test-message" style={{fontSize: '1rem'}}>
                В появившемся окне нажмите кнопку "Разрешить"
            </div>
        </Stack>
        );
}

export default GetPermissionContent;