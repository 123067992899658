import React from "react";


class Faq extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            show: [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        }
    }

    render() {
        return <div>
            <div className="faq-item">
                <h2 className="faq-header">Сколько длится сессия ?</h2>
                <div className="body-text-small">Индивидуальная сессия длится 55 минут.<br />
                      Сессия для двоих длится 100 минут
                </div>
            </div>

            <div className="faq-item">
                <h2 className="faq-header">Как оплатить консультации ?</h2>

                <div>Оплата сессий в сервисе проходит через безопасную сделку. За 8 часов до консультации с психологом стоимость сессии спишется с вашей карты и заморозится. На счёт специалиста деньги поступят только после сессии. Таким образом мы можем гарантировать, что вы получите консультацию, а психолог оплату своего труда. Если специалист не провел сессию или что-то пошло не так, обратитесь в службу поддержки - мы вернем деньги.</div>
            </div>
            <div className="faq-item">
                <h2 className="faq-header">Мой диалог с психологом конфиденциален?</h2>
                <div>Да, все сказанное вами во время сессии абсолютно конфиденциально.</div>
            </div>
            <div className="faq-item">
                <h2 className="faq-header">Могу ли связаться с терапевтом в промежутке между сессиями? </h2>
                <div>Да, в вашем личном кабинете будет чат с вашим психологом.</div>
            </div>
            <div className="faq-item">
                <h2 className="faq-header">Можно ли перенести сессию, если я не могу посетить ее в запланированное время?</h2>
                <div>Да, сессию можно переносить, если до ее начала осталось больше 8 часов.<br/>
                    На главном экране есть кнопка "перенести сессию", нажмите на нее и выберете другое время для встречи с вашим психологом.
                </div>
            </div>
            <div className="faq-item">
                <h2 className="faq-header">Меня не устраивает психолог, как мне быть?</h2>
                <div>Попробуйте начать работу с другим. В вашем личном кабинете есть раздел "специалисты" - там вы можете подобрать другого терапевта.</div>
            </div>
            <div className="faq-item">
                <h2 className="faq-header">В каком формате проходят консультации?</h2>
                <div>Консультации проходят в формате видео-чата  в вашем личном кабинете сервиса.</div>
            </div>
            <div className="faq-item">
                <h2 className="faq-header">Какие устройства подходят для онлайн-сессий ?</h2>
                <div>Посещать сессии можно с браузера ноутбука, смартфона или планшета.</div>
            </div>
            <div className="faq-item">
                <h2 className="faq-header">Что делать, если видео-чат не включается во время сессии?</h2>
                <div>Если вы вдруг видите, что видео-комната  не включается, зайдите в личный кабинете с другого браузера (мы рекомендуем посещать сессии через Google Chrome) - скорее всего проблема будет решена.</div>
            </div>

        </div>;
    }
}

export default Faq;