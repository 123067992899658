import React from "react";


class AboutClient extends React.Component {

    
    render() {
        return <div className="big-card">
            <h1 className="client-card-header">Карточка клиента</h1>
            <div>
                <h2 className="client-card-item">Евгений Петров</h2>
                <div className="client-card-item">Возраст: 18 лет</div>
                <div className="client-card-item">Дата сеанса: 20 октября, 19:00</div>
                <div className="client-card-item">Проблемы: тревожность, упадок сил, проблемы с родителями</div>
                <button className="button-white payments-button video-button">
                    <img src="images/icons/chat.svg" />
                    <span>Написать сообщение</span>
                </button>
            </div>
            <button className="button-white button-client-card">
                <img src="images/icons/arrowLeft.svg"/>
                <span>Назад</span>
            </button>
        </div>;
    }
}

export default AboutClient;