import React from "react";

import { Modal, Stack, Button, Dropdown, DropdownButton } from "react-bootstrap";
import DeviceSelector from "../components/DeviceSelector";
import TestPanelRoute from "../components/TestPanelRoute";
import NoDeviceContent from "../errorPanels/NoDeviceContent";
import VideoPanelContent from "./VideoPanelContent";
import GetPermissionContent from "../errorPanels/GetPermissionContent";
import PermissionDeniedContent from "../errorPanels/PermissionDeniedContent";
import AlreadyUseContent from "../errorPanels/AlreadyUseContent";
import UnknownErrorContent from "../errorPanels/UnknowErrorContent";

const VIEW = {
    WaitPermission: 0,
    Test: 1,
    NotFound: 2,
    NotReadable: 3,
    NotAllowed: 4,
    Unknown: 5
}

class VideoTestPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            view: VIEW.WaitPermission
        }
    }

    componentDidMount() {
        this.initVideo();
    }

    initVideo = () => {
        navigator.mediaDevices?.getUserMedia({video: true }).then(res => 
        {
            res.getTracks().forEach(function(track) {
                track.stop();
            });


            this.getDevices();

            navigator.mediaDevices.ondevicechange = this.getDevices;
            
        }).catch(err => {
            // Нет девайса
            // Не удалось получить доступ
            // Доступ запрещен

            if (err.name == 'NotFoundError')
            {
                // Нет девайса
                this.setState({view: VIEW.NotFound})
            }
            else if (err.name == 'NotReadableError')
            {
                // Already use
                this.setState({view: VIEW.NotReadable})

            }
            else if (err.name == 'NotAllowedError')
            {
                // Проверьте разрешение
                this.setState({view: VIEW.NotAllowed})
            }
            else
            {
                this.setState({view: VIEW.Unknown})
            }

            console.log(err.toString());
        });
    }


    getDevices = () => {
        navigator.mediaDevices?.enumerateDevices().then(result =>
        {
            let videoDevices = result.filter(item => item.kind === 'videoinput');

            let deviceId = localStorage.getItem('camId');
            let selectedDevice = videoDevices.find(x => x.deviceId == deviceId);
            console.log('Saved device:', selectedDevice);

            this.setState({cams: videoDevices, view: VIEW.Test, selectedDevice: selectedDevice ?? videoDevices[0] });

        });
    }

    deviceSelected = (device) => {
        localStorage.setItem('camId', device.deviceId);
        this.setState({selectedDevice: device});

        console.log('Selected device:', device);
    }


    getCurrentView = () => {
        if (this.state.view === VIEW.WaitPermission)
        {
            // Загрузочный экран
            return <GetPermissionContent />
            
        }
        else if (this.state.view === VIEW.Test)
        {
            // Экран проверки
            return <VideoPanelContent cams={this.state.cams}
                                        selectedDevice={this.state.selectedDevice}
                                        onSelectDevice={(device) => this.deviceSelected(device)}
                                        onTestResult={this.props.onTestResult}
                                        failed={this.props.failed}/>;
        }
        else if (this.state.view === VIEW.NotFound)
        {
            // Не найдено устройств
            return <NoDeviceContent onDevicesUpdate={this.initVideo}/>

        }
        else if (this.state.view === VIEW.NotReadable)
        {
            // Устройство уже используется
            return <AlreadyUseContent onDevicesUpdate={this.initVideo}/>
        }
        else if (this.state.view === VIEW.NotAllowed)
        {
            // Доступ запрещен
            return <PermissionDeniedContent onDevicesUpdate={this.initVideo}/>
        }

        return <UnknownErrorContent />
    }


    render() {

        return (
            <Stack className="devices-check-content">
                <TestPanelRoute step={2}/>

                {this.getCurrentView()}
            </Stack>
        );
    }

}

export default VideoTestPanel;