import { Stack, Button } from "react-bootstrap";


function PermissionDeniedContent(props) {
    return (
        <Stack gap={4} style={{alignItems: 'center'}}>
            <div className="device-test-message" style={{fontSize: '1.5rem'}}>
                Невозможно получить доступ к устройствам
            </div>
            <div className="device-test-message" style={{fontSize: '1rem'}}>
                Проверьте настройки доступа к устройствам в браузере и операционной системе
            </div>
            <Button onClick={props.onDevicesUpdate} variant="device-test-button">Обновить</Button>
        </Stack>
        );
}

export default PermissionDeniedContent;