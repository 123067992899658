import { Stack, Button } from "react-bootstrap";


function UnknownErrorContent(props) {
    return (
        <Stack gap={4} style={{alignItems: 'center'}}>
            <div className="device-test-message" style={{fontSize: '1.5rem'}}>
                Непредвиденная ошибка
            </div>
            <div className="device-test-message" style={{fontSize: '1rem'}}>
                Невозможно получить доступ к устройствам <br/>
                Рекомендуем использовать браузер Google Chrome последней версии
            </div>
        </Stack>
        );
}

export default UnknownErrorContent;