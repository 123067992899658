import React from "react";
import { Link } from "react-router-dom";


class TelegramOnboardingHeaderMobile extends React.Component {

    render () {

        return <div style={{marginTop: '1rem', marginBottom: '1rem'}} className="onboarding-header">
            <div>
                <img src="images/logo.svg" alt="Logo"/>
            </div>
            <div>
                
            </div>
        </div>
    }
}

export default TelegramOnboardingHeaderMobile;