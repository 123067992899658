import React from "react";
import {HttpTransportType, HubConnectionBuilder} from "@microsoft/signalr";

import { animateScroll } from "react-scroll";
import Loader from "./Loader";


class Chat extends React.Component {

    constructor(props) {
        super(props);

        this.state = { 
            messages: [],
            message: "",
            messagesLoaded: false,
        }
    }

    componentDidMount() {
        this.connectSignalR();
    }


    connectSignalR = () => {
        const hubConnection = new HubConnectionBuilder().withUrl(process.env.REACT_APP_API_URL + '/chat', {
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => localStorage.getItem('token')
        }).withAutomaticReconnect().build();

        console.log("mount")

        this.setState({ hubConnection }, () => {
            this.state.hubConnection
                .start()
                .then(() => {
                    console.log('Connection started!');
                 //   this.state.hubConnection.invoke('GetMessageHistory',  this.props.receiverId); // other side id

                 console.log(this.props.specialistId, this.props.clientId);

                    this.state.hubConnection.invoke('ReceiveChatMessages', this.props.specialistId, this.props.clientId)
                    .catch(err => console.log(err));
                })
                .catch(err => console.log('Error while establishing connection :(', err));


            this.state.hubConnection.onreconnecting(error => {

                console.error('Connection lost: ', error);
            });

            /*
            this.state.hubConnection.on('ReceiveHistory', (history) => {
                this.setState({messages: history, messagesLoaded: true});
                this.scrollToBottom();
            });

            this.state.hubConnection.on('ReceiveMessage', (message) => {
                let msg = this.state.messages;
                if (msg.findIndex(item => item.id === message.id) !== -1)
                    return;
                msg.push(message);
                this.setState({messages: msg});
            });*/

            this.state.hubConnection.on('ReceiveMessage', (message) => {

                console.log('Received message');
                if (message.specialistId != this.props.specialistId || message.clientId != this.props.clientId)
                    return;

                this.messagesRead();

                let messages = this.state.messages;
                messages.push(message);
                this.setState({messages: messages});

            });

            // senderId
            // content
            // date
            this.state.hubConnection.on('ReceiveMessages', (messages) => {

                console.log('Received messages');

                this.messagesRead();
                this.setState({messagesLoaded: true, messages: messages})
            });
        });

    }

    messagesRead = () => {
        this.state.hubConnection.invoke('MessagesRead', this.props.specialistId, this.props.clientId)
        .catch(err => console.log(err));
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    componentWillUnmount() {
        console.log("umount")
        this.state.hubConnection?.stop();
    }

    scrollToBottom() {
        animateScroll.scrollToBottom({
          containerId: "chatContainer",
          duration: 0
        });
    }


    sendMessage = () => {
        if (!this.state.message)
            return;

        this.state.hubConnection
        .invoke('SendChatMessage', this.props.specialistId, this.props.clientId, this.props.receiverId, this.state.message)
        .catch(err => console.log(err));

        this.setState({message: ""});
    }

    getMessageTime = (dt) => {
        let date = new Date(dt);
        let hours = date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours();
        let minutes = date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes();
        return hours + ':' + minutes;
    }

    printMessages = () => {
        console.log('print mesasges')
        return this.state.messages.map((message, index) => {
            if (message.senderId != this.props.receiverId)
            {
                return <div key={index} style={{display: 'flex', marginTop: '0.3rem'}}>
                    <div style={{width: '50%', background: '#E7F3F4',  marginLeft: 'auto', marginRight: '0.1rem', padding: '0.5rem', borderRadius: '15px'}}>
                        <div style={{wordBreak: 'break-word'}}>{message.content}</div>
                        <div style={{width: '50px', marginLeft: 'auto', color: '#C3C9DB'}}>{this.getMessageTime(message.date)}</div>
                    </div>
                </div>;
            }

            return <div key={index} style={{display: 'flex', marginTop: '0.3rem'}}>
                <div style={{width: '50%', background: '#F0F2FA', padding: '0.5rem', borderRadius: '15px'}}>
                    <div style={{wordBreak: 'break-word'}}>{message.content}</div>
                    <div style={{width: '50px', marginLeft: 'auto', color: '#C3C9DB'}}>{this.getMessageTime(message.date)}</div>
                </div>
            </div>;
        })
    }

    render() {
        if (!this.props.show)
            return;



        return <div className="modal-block" onClick={this.props.onHide}>
            <div className="body-text-small modal-inside" onClick={(e) => e.stopPropagation()}>
                <div style={{position: 'relative'}}>
                    <h2>Чат</h2>
                    <span onClick={this.props.onHide} style={{position: 'absolute', right: 0, top: 0, cursor: 'pointer'}}>X</span>
                </div>
                {this.state.messagesLoaded ?
                    <>
                        <div id='chatContainer' style={{overflow: 'auto', height: '400px'}}>
                            {this.printMessages()}
                        </div>
                        <div style={{display: 'flex', marginTop: '1rem'}}>
                            <input className="chat-input" onKeyDown={(e) => e.code === "Enter" ? this.sendMessage() : undefined} value={this.state.message} onChange={(e) => this.setState({message: e.target.value})} type="text" placeholder="Сообщение"/>
                            <button onClick={this.sendMessage}>Отправить</button>
                        </div>
                    </>
                :
                    <Loader />
                }
            </div>
        </div>;
    }
}

export default Chat;