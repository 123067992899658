import React from "react";

import { Stack, Button } from "react-bootstrap";
import DeviceSelector from "../components/DeviceSelector";


class MicroPanelContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            stream: undefined,
            streamInitializing: false,
            failed: false
        }
    }

    componentWillUnmount() {
        this.stopAudioStream();
        this.state.audio?.pause();
    }

    startAudioStream = () => {
        if (this.state.streamInitializing)
            return;
        this.setState({streamInitializing: true})

        navigator.mediaDevices?.getUserMedia({audio: { deviceId: this.props.selectedDevice.deviceId } }).then(res => {
            this.setState({stream: res, streamInitializing: false, failed: false, recording: true, changed: false, startTime: new Date(), message: 'Запись...'});


            let mediaRecorder = new MediaRecorder(res);
            let audioChunks = [];


            let timer = setInterval(() => {
                if (!this.state.startTime)
                    clearInterval(timer);

                if (new Date().getTime() - this.state.startTime?.getTime() > 10000)
                {
                    clearInterval(timer);
                    this.stopAudioStream();
                }
            }, 1000)

            mediaRecorder.addEventListener("dataavailable", event => {
                audioChunks.push(event.data);
            });

            mediaRecorder.addEventListener("stop", () => {
                console.log(audioChunks)
                let audioBlob = new Blob(audioChunks);
                let audioUrl = URL.createObjectURL(audioBlob);
                let audio = new Audio(audioUrl);
                this.setState({audio: audio, recording: false, mediaRecorder: undefined, startTime: undefined, message: undefined});
            });
    
            this.setState({mediaRecorder: mediaRecorder});

            mediaRecorder.start();
            
        }).catch(err => {

            this.setState({failed: true, streamInitializing: false});

            console.log(err);
        });
    }

    stopAudioStream = () => {
        console.log('Stop recording')
        this.state.mediaRecorder?.stop();
        this.state.stream?.getTracks().forEach(track => track.stop());
        this.setState({stream: undefined});
    }

    playAudio = () => {
        if (this.state.audio)
            this.state.audio.onended = () => this.setState({message: undefined});

        let playPromise = this.state.audio?.play();
        this.setState({message: 'Проигрывается...'})
    }


    getButtonBlock = () => {
        if (this.state.recording)
        {
            // Кнопка остановить
            return <Button onClick={() => this.stopAudioStream()} variant="device-test-button" style={{display: 'flex', justifyContent: 'space-evenly'}}><img src="images/icons/stop.svg" /> Остановить запись</Button>
        }
        if (this.state.audio && !this.state.changed)
        {
            // Кнопка проиграть
            return <Button onClick={() => { this.playAudio(); this.props.onTestResult()}} variant="device-test-button" style={{display: 'flex', justifyContent: 'space-evenly', width: '280px'}}><img src="images/icons/playTriangle.svg" /> Прослушать сообщение</Button>
        }
        
        
            // Кнопка записать
        return <Button onClick={() => this.startAudioStream()} variant="device-test-button" style={{display: 'flex', justifyContent: 'space-evenly', width: '280px'}}><img src="images/icons/Play.svg" /> Записать сообщение</Button>
        
    }

    getStageBlock = () => {
        if (!this.state.message)
            return;

        return (<div style={{textAlign: 'center'}}>
            {this.state.message}    
        </div>)
    }

    selectDevice = (device) => {
        this.stopAudioStream();
        this.setState({changed: true, message: undefined});
        this.props.onSelectDevice(device);
        this.state.audio?.pause();
    }


    render() {
        return (
            <>
            <Stack gap={3}>
                <div className="device-test-message">
                    Запишите сообщение, чтобы проверить микрофон
                </div>

                {this.getButtonBlock()}
                {this.getStageBlock()}
            
            </Stack>

            <DeviceSelector iconPath="images/icons/Mic.svg"
                            devices={this.props.mics}
                            selectedDevice={this.props.selectedDevice}
                            onSelectDevice={this.selectDevice}
                            failed={this.props.failed}
            />

        </>
        );
    }

}

export default MicroPanelContent;