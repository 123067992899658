import React from "react";
import Connections from "../../scripts/connection";
import CategoriesBlock from "./CategoriesBlock";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Calendar from "../Calendar"
import TimeSelector from "./TimeSelector";
import PhoneInput from "react-phone-number-input/input";
import Loader from "../Loader";
import { Navigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { decodeToken } from "react-jwt";
import TimezoneSelector from "../TimezoneSelector";


class ClientSurvey extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            age: [
                25, 60
            ],
            sex: null,
            lgtqExp: false,

            selectedCategories: [],
            toggle: 2,
            timeType: 1,
            timePeriods: [],

            timezone: new Date().getTimezoneOffset()
        }
           
    }

    errorOccured = (error) => {
        this.setState({error: true});
    }

    async componentDidMount() {
        // Parse the shit
        let searchParams = new URLSearchParams(window.location.search);
        
        let from = searchParams.get("surveyType");
        if (from)
            localStorage.setItem('from', from);


        let cluster = from ?? localStorage.getItem('from');
        console.log('Cluster', cluster);
        let listResult = Connections.Get('/categories/' + (cluster ?? "common"),
            (result) => {
                this.setState({categories: result.value});
            },
            this.errorOccured,
            this.errorOccured
        );

        let utmPromo = searchParams.get("utm_campaign");
        if (utmPromo)
        {
            utmPromo = utmPromo.split('-')[0]?.toUpperCase();
            let promo = null;

            if (utmPromo === "DIRECT")
                promo = "СЕССИЯ1";

            if (utmPromo === "TELEGRAM")
                promo = "MENTAL20";

            if (utmPromo === "POST")
                promo = "MENTAL20";

            if (utmPromo === "POISK")
                promo = "MOSKVA20";

            if (promo)
            {
                await Connections.AuthorizePost('/promo/applypromo', promo,
                (success) => {
                    console.log(success)
                    if (success.value?.success)
                    {
                        this.setState({promoApplied: true, promoError: false})
                    }
                },
                () => {},
                () => {}
                );
            }
        }

        let selectedData = Connections.AuthorizeGet('/client/clientsurvey',
        (success) => {
            console.log(success)
            if (success.value != null)
            {
                let survey = success.value.clientSurvey;
                this.setState({
                    name: survey.name,
                    personAge: survey.personAge,
                    lgtqExp: survey.lgbtqExp,
                    age: [survey.minAge, survey.maxAge],
                    sex: survey.specialistSex,
                    selectedCategories: survey.workCategories,
                    //selectedTime: survey.selectedTime,
                    //toggle: survey.consultationType ?? typeIndex,
                    surveyType: survey.surveyType,
                    timezone: survey.timezone ?? new Date().getTimezoneOffset(),
                    timePeriods: survey.timePeriods,
                    timeType: survey.timePeriods.length > 0 ? 2 : 1
                })
            }
        },
        this.errorOccured,
        this.errorOccured)


        let promo = Connections.AuthorizeGet('/promo/activepromo',
        (success) => {
            if (success.value?.code)
                this.setState({promoApplied: true, promo: success.value.code});
        },
        this.errorOccured,
        this.errorOccured
        )

        Promise.all([listResult, selectedData, promo]).then(res => {

            this.setState({loadingComplete: true}, () => {
                let ts = localStorage.getItem('timeSelector');
                if (ts)
                {
                    const element = document.getElementById('time');

                    if (element) {
                        element.scrollIntoView();
                    }
                    localStorage.removeItem('timeSelector');
                }

            });
        });
    }

    validateData = () => {
        let messages = [];

        /*if (!this.state.selectedTime)
            messages.push('Не выбрано время');*/

        if (!this.state.name)
            messages.push('Введите имя');

        if (this.state.personAge && (this.state.personAge < 0 || this.state.personAge > 100))
            messages.push('Некорректный возраст');


        if (!this.state.selectedCategories || this.state.selectedCategories.length === 0)
            messages.push('Не выбраны категории');

        this.setState({messages: messages})
        return messages.length === 0;
    }


    register = () => {
        console.log(this.state.selectedTime)

        if (!this.validateData())
            return;
        console.log(this.state.selectedCategories)

        let result = decodeToken(localStorage.getItem('token'));
        console.log('Decoded token', result);

        console.log(this.state.selectedCategories);

        console.log({clientId: result.id,
            name: this.state.name,
            age: this.state.personAge,
            subcategoriesId: this.state.selectedCategories.map(i => i.subCategoryId),
            lgbtExperienceRequired: this.state.lgtqExp,
            preferredGender: this.state.sex,
            minSpecialistAge: this.state.age[0],
            maxSpecialistAge: this.state.age[1],})

        Connections.AuthorizePost('/client/applysurvey', 
        {
            clientId: result.id,
            name: this.state.name,
            age: this.state.personAge,
            subcategoriesId: this.state.selectedCategories.map(i => i.subCategoryId),
            lgbtExperienceRequired: this.state.lgtqExp,
            preferredGender: this.state.sex,
            minSpecialistAge: this.state.age[0],
            maxSpecialistAge: this.state.age[1],
            timezoneOffsetInMinutes: this.state.timezone,
            timePeriods: this.state.timePeriods,
            consultationType: localStorage.getItem('from')?.toLowerCase() == "relationships" ? "Double" : "Single"
            

//            timezoneOffset: new Date().getTimezoneOffset()
        },
        (result) => {
            console.log('SUCESS', result)
            this.props.onSurveyComplete();
            this.setState({next: true});
        },
        (error) => {
            console.log('unSUCESS', error)
            let messages = this.state.messages;
            messages.push(error.value);
            this.setState({messages: messages});
        },
        (requestError) => {
            console.log('unSUCESS', requestError)
        });
    }

    sendCode = () => {
        Connections.Post('/auth/generateotc', {
            number: this.state.phone
        },
        (result) => {
            this.setState({codeMessage: 'Код отправлен!'})
        },
        (error) => {
            this.setState({codeMessage: 'Произошла ошибка!'})
        },
        (requestError) => {
            this.setState({codeMessage: 'Произошла ошибка!'})
        })
    }

    getTimezoneDisplay = (timezone) => {
        return timezone >= 0 ? "UTC-" + timezone / 60 : "UTC+" + (-(timezone / 60));
    }

    toggleTimePeriod = (timePeriod) => {

        if (!this.isTimePeriodActive(timePeriod))
        {
            let timePeriods = this.state.timePeriods;
            timePeriods.push(timePeriod);
            this.setState({timePeriods: timePeriods})
        }
        else
        {
            let timePeriods = this.state.timePeriods.filter(x => x != timePeriod);
            this.setState({timePeriods: timePeriods})
        }
    }

    isTimePeriodActive = (timePeriod) => {
        return this.state.timePeriods.findIndex(x => x == timePeriod) !== -1;
    }

    applyPromo = () => {

        Connections.AuthorizePost('/promo/applypromo', this.state.promo,
        (success) => {
            console.log(success)
            if (success.value?.success)
            {
                this.setState({promoApplied: true, promoError: false})
            }
            else
            {
                console.log('wtf')
                this.setState({promoError: true})
            }
        },
        this.errorOccured,
        this.errorOccured
        );
    }

    removePromo = () => {

        Connections.AuthorizePost('/promo/removepromo', null,
        (success) => {
            this.setState({promoApplied: false, promo: null})
        },
        this.errorOccured,
        this.errorOccured);
    }

    render() {
        if (this.state.next)
            return <Navigate to='/select'/>

        if (this.state.error)
            return <div>Произошла ошибка, попробуйте позже</div>;

        if (!this.state.loadingComplete)
            return <div style={{height: '600px'}}><Loader /></div>;
     
        return <>
            <div className="reg-steps">
                <div className="reg-steps-item">
                    <span className="step-num step-num-current step-num-active">1</span>
                    <span className="body-text-small reg-num-title">Общие вопросы</span>
                </div>
                
                
                {this.props.state == 2 || this.props.state == 3 ?
                <>
                    <div className="reg-steps-divide reg-steps-divide-type1"></div>

                <Link to='/select' style={{textDecoration: 'none', cursor: 'pointer'}}>

                <div className="reg-steps-item">
                    <span className="step-num step-num-current">2</span>
                    <span className="body-text-small reg-num-title">Выбор специалиста</span>
                </div>
                </Link>
                </>
                :
                <>
                    <div className="reg-steps-divide reg-steps-divide-type2"></div>

                    <div className="reg-steps-item">
                        <span className="step-num">2</span>
                        <span className="body-text-small reg-num-title">Выбор специалиста</span>
                    </div>
                </>
                }   


                {this.props.state === 3 ?
                <>
                    <div className="reg-steps-divide reg-steps-divide-type1"></div>

                    <Link to='/payment'>
                    <div className="reg-steps-item">
                        <span className="step-num step-num-current">3</span>
                        <span className="body-text-small reg-num-title">Запись и оплата</span>
                    </div>
                </Link>
                </>
                :
                <>
                <div className="reg-steps-divide reg-steps-divide-type1"></div>
                    <div className="reg-steps-item">
                        <span className="step-num">3</span>
                        <span className="body-text-small reg-num-title">Запись и оплата</span>
                    </div>
                    </>
                }
            </div>
            <div className="big-card">
            {/*<div className="reg-top-block">
                    <div>
                        <h2 className="reg-block-header">Выберите вид консультации</h2>
                        <div className="reg-block-toggle">
                            <div onClick={() => this.setState({toggle: 2})} className={"reg-toggle toggle-left" + (this.state.toggle === 2 ? ' toggle-active' : '')}>Индивидуальная</div>
                            <div onClick={() => this.setState({toggle: 1})} className={"reg-toggle toggle-right" + (this.state.toggle === 1 ? ' toggle-active' : '')}>Для двоих</div>
                        </div>
            </div>
                </div>
                <div className="divide-line"></div>*/}
                <CategoriesBlock selectedCategories={this.state.selectedCategories} onUpdate={(selectedCategories) => this.setState({selectedCategories: selectedCategories})} categories={this.state.categories} />              

                <div className="divide-line"></div>

                <div>
                    <h2 className="reg-block-header">Что важно в психологе?</h2>
                    
                    <div className="reg-columns">
                        <div className="reg-column-block" style={{marginRight: '1rem'}}>
                            <span className="body-text-large reg-block-header">С кем вам было бы комфортнее работать</span>
                            <span className="body-text-small reg-block-item">
                                <input checked={this.state.sex == null} onChange={() => this.setState({sex: null})} type="checkbox" id="anySex"/> 
                                <label htmlFor="anySex">Пол неважен</label>
                            </span>
                            <span className="body-text-small reg-block-item">
                                <input checked={this.state.sex === "Male"} onChange={() => this.setState({sex: "Male"})} type="checkbox" id="male"/> 
                                <label htmlFor="male">С мужчиной</label>
                            </span>
                            <span className="body-text-small reg-block-item">
                                <input checked={this.state.sex === "Female"} onChange={() => this.setState({sex: "Female"})} type="checkbox" id="female"/> 
                                <label htmlFor="female">С женщиной</label>
                            </span>
                        </div>

                        {/*
                        <div className="reg-column-block">
                            <span className="body-text-large reg-block-header">Возраст психолога</span>
                            <span className="body-text-small reg-block-item">
                                <input checked={this.state.anyAge} onChange={() => this.setState({anyAge: !this.state.anyAge})} type="checkbox" id="ageChb"/>
                                <label htmlFor="ageChb">Возраст неважен</label>
                            </span>
                            <span style={this.state.anyAge ? {display: 'none'} : {}} className="body-text-small reg-block-item slider-block">
                                <div className="age-block" style={{color: '#3A767B'}}>
                                    <span>{this.state.age[0]}</span>
                                    <span>{this.state.age[1]}</span>
                                </div>
                                <RangeSlider rangeSlideDisabled={true} min={25} max={60} value={[this.state.age[0], this.state.age[1]]} onInput={(age) => this.setState({age: age})}/>
                                <div className="age-block">
                                    <span>25</span>
                                    <span>60</span>
                                </div>
                            </span>
                        </div>
                        */
                        }
                    </div>
                   
                </div>
                
                <div className="divide-line"></div>
                <div>
                        <h2 className="reg-block-header">Выберите время сессии</h2>
                        {<TimezoneSelector timezone={this.state.timezone} onChange={(tz) => this.setState({timezone: tz})}/>}
                        <div className="reg-block-toggle">
                            <div onClick={() => this.setState({timeType: 1, timePeriods: []})} className={"reg-toggle toggle-left" + (this.state.timeType === 1 ? ' toggle-active' : '')}>Ближайшее</div>
                            <div onClick={() => this.setState({timeType: 2})} className={"reg-toggle toggle-right" + (this.state.timeType === 2 ? ' toggle-active' : '')}>Конкретное</div>
                        </div>
                        {this.state.timeType === 1 ?
                            <div style={{marginTop: '18px'}}>Сначала покажем психологов, свободных в ближайшее время</div>
                        :
                        <>
                            <div className="concrete-time-title">Сначала покажем психологов, свободных в указанные вами интервалы времени</div>
                            <div className="concrete-time">
                                <div>
                                    <div className="timeblock-title">Понедельник — пятница:</div>
                                    <div className="timeblock-container">
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekdayMorning")} onChange={() => this.toggleTimePeriod("WeekdayMorning")} type="checkbox" id="time1"/>
                                            <label htmlFor="time1">До 10:00</label>
                                        </div>
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekdayAfternoon")} onChange={() => this.toggleTimePeriod("WeekdayAfternoon")} type="checkbox" id="time2"/>
                                            <label htmlFor="time2">10:00 — 18:00</label>
                                        </div>
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekdayEvening")} onChange={() => this.toggleTimePeriod("WeekdayEvening")} type="checkbox" id="time3"/>
                                            <label htmlFor="time3">После 18:00</label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="timeblock-title">Суббота и воскресенье:</div>
                                    <div className="timeblock-container">
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekendMorning")} onChange={() => this.toggleTimePeriod("WeekendMorning")} type="checkbox" id="time4"/>
                                            <label htmlFor="time4">До 10:00</label>
                                        </div>
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekendAfternoon")} onChange={() => this.toggleTimePeriod("WeekendAfternoon")} type="checkbox" id="time5"/>
                                            <label htmlFor="time5">10:00 — 18:00</label>
                                        </div>
                                        <div className="timeblock-item">
                                            <input checked={this.isTimePeriodActive("WeekendEvening")} onChange={() => this.toggleTimePeriod("WeekendEvening")} type="checkbox" id="time6"/>
                                            <label htmlFor="time6">После 18:00</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                </div>
                {/*
                <div id='time'>
                    <TimeSelector onSelect={(time) => {console.log(time); this.setState({selectedTime: time})}} onErrorOccured={() => this.setState({error: true})} />
                </div>
                    */}
                <div className="divide-line"></div>

                <div>
                    <h2 className="reg-block-header">О себе</h2>

                    <div>
                        <div className="reg-field-block" style={{marginBottom: '1rem'}}>
                            <input onChange={(e) => {this.setState({name: e.target.value})}} value={this.state.name} className="field-text reg-field" type="text" placeholder="Имя или псевдоним"/>
                            <input onChange={(e) => {this.setState({personAge: e.target.value})}} value={this.state.personAge} min={0} max={100} className="field-text reg-field" type="number" placeholder="Возраст"/>
                        </div>
                        {!this.state.promoApplied ?
                        <div>
                            <input onChange={(e) => {this.setState({promo: e.target.value})}} value={this.state.promo} className="field-text reg-field" type="text" placeholder="Промокод"/>
                            <span onClick={this.applyPromo} style={{marginLeft: '-60px', padding: '0px 18px', color: '#3A767B', background: 'white', cursor: 'pointer', marginTop: 'auto', marginBottom: 'auto'}}>Ок</span>
                            {this.state.promoError ? <div>Не удалось применить промокод</div> : undefined }
                        </div>
                        :
                        <div>
                            <div><span>Применен промокод: {this.state.promo}</span><span style={{marginLeft: '10px', color: '#3A767B', cursor: 'pointer'}} onClick={this.removePromo}>Удалить</span></div>
                        </div>
                        }
                    </div>
                    
                    <div>
                    {this.state.messages?.map((item, index) => <div style={{color: 'red'}} key={index}>{item}</div>)}
                    </div>
                </div>

                <div className="reg-controls">
                    <button onClick={this.register} className="reg-button">
                        <span className="button-text">Вперед</span>
                        <img src="images/icons/right.svg"/>
                    </button>
                </div>
            </div>
        </>
    }
}

export default ClientSurvey;