import React from "react";


class ClientDiaryRecord extends React.Component {

    constructor(props) {
        super(props);
    }

    dateToString = (date, $1, $2, $3, separator='') => {
        const dateObj = {
            date: String(date.getDate()).padStart(2, '0'),
            month: String(date.getMonth() + 1).padStart(2, '0'),
            year: date.getFullYear()
        };

        return dateObj[$1] + separator + dateObj[$2] + separator + dateObj[$3];
    }

    formatDate = (recordTime) => {

        return this.dateToString(recordTime, 'date', 'month', 'year', '.');
    }

    formatTime = (recordTime) => {

        return recordTime.getHours() + ':' + (recordTime.getMinutes() < 10 ? '0' + recordTime.getMinutes() : recordTime.getMinutes());
    }

    render() {

        return  <div className="diary-card">
                    <div className="diary-card-header">
                        <div>{this.formatDate(this.props.date)}</div>
                        <div>{this.formatTime(this.props.date)}</div>
                    </div>
                    <div>
                        <div className="diary-card-block-header">Событие</div>
                        <div>
                            {this.props.event}
                        </div>
                    </div>
                    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.64645 15.3536C3.84171 15.5488 4.15829 15.5488 4.35355 15.3536L7.53553 12.1716C7.7308 11.9763 7.7308 11.6597 7.53553 11.4645C7.34027 11.2692 7.02369 11.2692 6.82843 11.4645L4 14.2929L1.17157 11.4645C0.976311 11.2692 0.659728 11.2692 0.464466 11.4645C0.269204 11.6597 0.269204 11.9763 0.464466 12.1716L3.64645 15.3536ZM3.5 0L3.5 15H4.5L4.5 0L3.5 0Z" fill="#3A767B"/>
                    </svg>
                    <div>
                        <div className="diary-card-block-header">Эмоция</div>
                        <div>
                            {this.props.emotions}
                        </div>
                    </div>
                    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.64645 15.3536C3.84171 15.5488 4.15829 15.5488 4.35355 15.3536L7.53553 12.1716C7.7308 11.9763 7.7308 11.6597 7.53553 11.4645C7.34027 11.2692 7.02369 11.2692 6.82843 11.4645L4 14.2929L1.17157 11.4645C0.976311 11.2692 0.659728 11.2692 0.464466 11.4645C0.269204 11.6597 0.269204 11.9763 0.464466 12.1716L3.64645 15.3536ZM3.5 0L3.5 15H4.5L4.5 0L3.5 0Z" fill="#3A767B"/>
                    </svg>
                    <div>
                        <div className="diary-card-block-header">Мысль</div>
                        <div>
                            {this.props.thought}
                        </div>
                    </div>
                    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.64645 15.3536C3.84171 15.5488 4.15829 15.5488 4.35355 15.3536L7.53553 12.1716C7.7308 11.9763 7.7308 11.6597 7.53553 11.4645C7.34027 11.2692 7.02369 11.2692 6.82843 11.4645L4 14.2929L1.17157 11.4645C0.976311 11.2692 0.659728 11.2692 0.464466 11.4645C0.269204 11.6597 0.269204 11.9763 0.464466 12.1716L3.64645 15.3536ZM3.5 0L3.5 15H4.5L4.5 0L3.5 0Z" fill="#3A767B"/>
                    </svg>
                    <div>
                        <div className="diary-card-block-header">Реакция</div>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.props.reaction}
                        </div>
                    </div>
                </div>;
    }
}


export default ClientDiaryRecord;