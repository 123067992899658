import React from "react";
import PhoneInput from "react-phone-number-input/input";


import Connections from '../scripts/connection'

class Login extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            phone: '+7',
            code: ''
        }
    }

    receiveCode = () => {
        if (this.state.time && this.state.time > 0)
            return;

        Connections.Post('/auth/generateotc', 
            {number: this.state.phone},
            (result) => {
                let time = 59;
                let nextCodeIn = setInterval(() => {
                    let timeLeft = this.state.time ?? time;
                    timeLeft -= 1;
                    if (timeLeft <= 0)
                    {
                        clearInterval(this.state.nextCodeIn);
                        timeLeft = undefined
                    }
                    this.setState({nextCodeIn: nextCodeIn, time: timeLeft});
                }, 1000);
                this.setState({message: 'Код отправлен!'});
            },
            (result) => {
                this.setState({message: result.value})
            },
            (err) => {
                this.setState({message: "Произошла ошибка, попробуйте позже"})
            });
        this.setState({codeSended: true});
    }

    login = () => {
        Connections.Post('/auth/auth',{
            phone: this.state.phone,
            code: this.state.code
        },
        (result) => {
            localStorage.setItem('token', result.value);
            this.props.authed();
        },
        (result) => {
            this.setState({message: result.value});
        },
        (err) => {
            console.log('Fatal error')
        });
    }

    render() {
        return (
            <div className="login-content">
                <div className="login-form">
                    <h1 className="login-item">Вход для клиентов</h1>
                    <div className="login-item">
                        <div className="field-heading login-field-title">Номер телефона</div>
                        <div className="login-field field-text">
                            <span>+7</span>
                            <PhoneInput defaultCountry="RU" className="field-text" style={{border: 'none', padding: '0', paddingLeft: '0.4rem', width: '85%'}} placeholder="999 999-99-99" value={this.state.phone} onChange={(event) => { this.setState({phone: event})}}/>
                        </div>
                    </div>
                    <div className="login-item">
                        <div className="field-heading login-field-title">Код из смс</div>
                        <div className="login-field">
                            <input value={this.state.code} onChange={(event) => this.setState({code: event.target.value})}  className="field-text login-code" type="text" placeholder="xxxxx"/>
                            <span onClick={() => this.receiveCode()} className={"body-text-small login-code-title" + (this.state.codeSended ? '-pressed' : '')}>Получить код</span>
                        </div>
                        <div>{this.state.message}</div>
                        {this.state.time > 0 ?
                        <div>Повторная отправка кода возможна через 0:{this.state.time}</div>
                        :
                        null
                        }
                    </div>
                    <div className="login-controls">
                        <button onClick={() => this.login()}>
                            <span className="button-text">Войти</span>
                            <img src="images/icons/right.svg"/>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;