import React from "react";
import { Navigate } from "react-router";
import connection from "../../scripts/connection";
import Loader from "../Loader";
import SpecialistsSlider from "./SpecialistsSlider";
import { Link } from "react-router-dom";
import MessageModal from "../MessageModal";
import DateTimeSelectorOnboarding from "../DateTimeSelectorOnboarding";
import ConsultationBookingModal from "../ConsultationBookingModal";
import { decodeToken } from "react-jwt";
import FreeBookingModal from "./FreeBookingModal";
import LoginForm from "../entry/LoginForm";
import RegisterModal from "../entry/RegisterModal";
import Utils from "../../scripts/utils";

class FreeSelectSpecialist extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            authed: false,
            faq: [
                {
                    show: false
                },
                {
                    show: false
                },
                {
                    show: false
                },
                {
                    show: false
                }
            ]
        }
    }

    componentDidMount() {

        let interval = setInterval(() => {
            let count = this.state.timeCount ?? -1;
            count++;

            if (count == 1 && !this.state.analyzer)
                this.setState({analyzer: true});

            if (count == 3 && !this.state.finder)
                this.setState({finder: true});

            if (count > 5)
            {
                clearInterval(interval);
            }

            this.setState({timeCount: count});

        }, 1000);

        connection.Get('/categories',
        (success) => {
            console.log('Categories:',success);

            let subcategoriesArray = success.value.map(i => i.subCategories);
            let subcategories = [];

            for (let i = 0; i < subcategoriesArray.length; i++)
                subcategories = subcategories.concat(subcategoriesArray[i]);
            
            let subcategoriesDictionary = {};
            subcategories.forEach(i => subcategoriesDictionary[i.id] = i.title);

            this.setState({
                categories: subcategoriesDictionary,
                categoriesLoaded: true
            })
        },
        (error) => {
            console.log(error);
        },
        (fatalError) => {
            console.log(fatalError);
        });

        connection.Get('/therapymethods',
        (success) => {
            console.log('therapyMethods:',success);

            let therapyMethods = {};
            success.value.forEach(item => therapyMethods[item.id] = item);

            this.setState({
                therapyMethods: therapyMethods
            })
        },
        (error) => {
            this.setState({
                therapyMethods: {}
            })
            console.log(error);
        },
        (fatalError) => {
            console.log(fatalError);
        });

        let surveyData = localStorage.getItem('survey');
        if (!surveyData && !this.props.auth)
        {
            this.setState({toSurvey: true});
            return;
        }

        let survey = JSON.parse(surveyData);
        let payload = {
            subcategoriesId: survey?.subcategoriesId?.map(i => i.subCategoryId) ?? [],
            preferredGender: survey?.preferredGender,
            timezoneOffsetInMinutes: survey?.timezoneOffset ?? new Date().getTimezoneOffset(),
            timePeriods: survey?.timePeriods ?? []
        }

        this.setState({
            subcategoriesId: survey?.subcategoriesId?.map(i => i.subCategoryId) ?? [],
            preferredGender: survey?.preferredGender,
            timezoneOffset: survey?.timezoneOffset ?? new Date().getTimezoneOffset(),
            timePeriods: survey?.timePeriods ?? []
        })


        connection.Post('/booking/availablespecialist',
        payload
        ,
        (success) => {
            console.log(success);
            this.setState({
                //selectedTime: success.value[0].availableTime[0],
                specialists: success.value,
                selectedSpecialist: success.value[0],
                loaded: true,
                //price: success.value.payment,
                consultationType: 0//,success.value.consultationType
            })
            console.log(this.state.selectedSpecialist)

            connection.SendStats({specialistSwitchEventData: {
                browserId: Utils.GetBrowserId(),
                specialistId: success.value[0]?.specialistId
            }})
        },
        (error) => {
            console.log(error);
            if (error.value.noSpecialists)
            {
                this.setState({loaded: true, noSpecialists: true});
            }
        },
        (requestError) => {
            console.log(requestError);
        })

        connection.SendStats({surveyViewedEventData: {
            browserId: Utils.GetBrowserId(),
            utm: window.location.search
        }})

        window.ym(94253611,'hit','platform/select');
    }

    componentDidUpdate() {
/*
        if (!this.state.observer && this.state.analyzer && this.state.finder)
        {
            let booking = document.getElementById('booking');
            
            let observer = new IntersectionObserver((entries, observer) => {

                entries.forEach(entry => {

                    if (entry.isIntersecting)
                    {
                        if (this.state.showFloating)
                            this.setState({showFloating: false})
                    }
                    else
                    {
                        if (!this.state.showFloating)
                            this.setState({showFloating: true})
                    }
                })
            });

            //observer.observe(booking);

            this.setState({observer: observer});
        }*/
    }

    parseDate = (specialistShowCase) => {
        let months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        let days = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];

        let dt = new Date(specialistShowCase.availableTime[0].date);

        return dt.getDate() + ' ' + months[dt.getMonth()] + ', ' + days[dt.getDay()];
    }

    getClosestTimeBlock = (closestDate) => {
        let months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        let days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

        let date = new Date(closestDate);
        // Время
        // День с месяцем
        // день недели
        let time = date.getHours() + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
        let day = date.getDate() + ' ' + months[date.getMonth()];
        let dayOfWeek = days[date.getDay()];


        let currentDt = new Date();
        let tommarowDt = new Date(currentDt.getFullYear(), currentDt.getMonth(), currentDt.getDate() + 1);


        let formatedDt = day + ', ' + time + ', ' + dayOfWeek;

        if (date.getDate() === currentDt.getDate())
            formatedDt = "сегодня, " + time
        else if (date.getDate() === tommarowDt.getDate())
            formatedDt = "завтра, " + time

        return <div className="closestdate-block">
            <img className="closestdate-icon" src="images/icons/calendarCurved.svg"/>
            <div>
                <div className="closestdate-text">Ближайшее время</div>
                <div className="closestdate-date">{formatedDt}</div>
            </div>
        </div>;
    }

    getWorkThemes = () => {
        if (this.state.selectedCategories?.length === 0)
            return null;

        if (this.state.selectedSpecialist.categoryMatch.length === 0)
            return;

        let themes = this.state.selectedSpecialist.categoryMatch.map((cat, index)=> {
            return <span key={index} className="tag">{this.state.categories[cat]}</span>;
        });

        let text = themes.length % 10 === 1 && themes.length !== 11 ? 'выбранной темой' : 'выбранными темами'

        return(<div className="about-block working-themes-block">
                    <h2 className="reg-block-header">Работает с {themes.length} {text}</h2>
                    <div className="tag-list">
                        {themes.map(i => i)}
                    </div>
                </div>)
    }

    getTherapyMethods = () => {
        if (this.state.therapyMethods?.length === 0)
            return null;
        
        if (this.state.selectedSpecialist.therapyMethods?.length === 0)
            return;

        return <>
            <hr/>
            <div className="about-block working-themes-block">
                <h2 className="reg-block-header">Методы терапии</h2>
                <div className="tag-list">
                    {this.state.selectedSpecialist.therapyMethods?.map((item, index) => {
                        return <span key={index} className="tag">{this.state.therapyMethods[item].title} { this.state.therapyMethods[item].description ? <img onClick={() => this.setState({message: this.state.therapyMethods[item].description})} className="info-icon-method" src="images/icons/info.svg"/> : undefined}</span>
                    })}
                </div>
            </div>
        </>;
    }

    getSpecialistWorkThemes = () => {
        return this.state.selectedSpecialist.subCategories.map((cat, index) => {
            return <span key={index}>{this.state.categories[cat]}</span>
        })
    }

    getSpecialistEducation = () => {
        return this.state.selectedSpecialist.education.map((edu, index) => {
           
            return (
                <div className="edu-item">
                    <div className="edu-item-mark"></div>
                    <div>
                        {edu.description}
                    </div>
                </div>
            )
        })
    }

    getExperience = () => {
        if (!this.state.selectedSpecialist.experience)
            return <span style={{height: '18px'}}></span>;

        let words = [ 'лет', 'год', 'года', 'года', 'года', 'лет', 'лет', 'лет', 'лет', 'лет']
        let exp = this.state.selectedSpecialist.experience;

        let word = words[exp % 10];


        if (exp == 11 || exp == 12 || exp == 13 || exp == 14)
            word = 'лет';

        return <span onClick={() => this.setState({showExpCheckedMessage: !this.state.showExpCheckedMessage})}>
                <span>Опыт {exp} {word}</span><img className="info-icon-exp" src="images/icons/info.svg"/>
                {this.state.showExpCheckedMessage ?
                <div onClick={() => this.setState({showExpCheckedMessage: false})} style={{marginLeft: '-40px'}} className="tooltipselect">
                    <div style={{padding: '7px'}} className="tooltiptext tooltip-exp">Образование и опыт специалиста проверены в Uwio</div>
                </div>
                :
                undefined
                }
               </span>;
    }

    getButtons = (specialistShowcase) => {

        return specialistShowcase.availableTime.map((item, index) => 
            <button key={index} onClick={() => this.bookConsultation(item)} className="pay-btn">{item.time}:00</button>
        );
    }

    addAdditionalTime = (time) => {
        let spec = this.state.selectedSpecialist;
        spec.availableTime = [ time ];
        this.setState({selectedSpecialist: spec, showTimeSelector: false});
    }

    selectSpecialst = (id) => {
        console.log("specId",id);
        this.setState(
            {
                selectedSpecialist: this.state.specialists.find(item => item.specialistId == id),
                showSecondBlock: false,
                showFirstBlock: false,
                showThirdBlock: false
            } 
        , () => {
            connection.SendStats(
                {specialistSwitchEventData: {
                    browserId: Utils.GetBrowserId(),
                    specialistId: id
                }}
            );     
        });
    }

    bookConsultation = (time) => {
        let tokenData = decodeToken(localStorage.getItem('token'));

        console.log(JSON.stringify({
            clientId: tokenData.id,
            specialistId: this.state.selectedSpecialist.specialistId,
            time: time,
        }))

        connection.AuthorizePost('/booking/bookconsultation',
        {
            clientId: tokenData.id,
            specialistId: this.state.selectedSpecialist.specialistId,
            time: time,
        },
        (success) => {
            this.setState({payment: true})
        },
        (error) => {

            // off 'booking' flag
            // parse error Messages
            // show error message

            // planned_consultation_cancelling

            //console.log(error)
            this.setState({booking: false, message: 'Похоже, что выбранное время уже забронировано. Пожалуйста, выберите другое'});
        },
        (fatalError) => {

            // off 'booking' flag
            // show error message

            this.setState({booking: false, message: 'Не удалось забронировать время. Сервис временно недоступен'});
        })
    }

    sendStats = () => {
        connection.SendStats({
            showTimeEventData: {
                browserId: Utils.GetBrowserId(),
                specialistId: this.state.selectedSpecialist?.specialistId
            }
        })
    }

    sendPreRegisterStats = (time) => {
        connection.SendStats({
            preRegisterEventData: {
                browserId: Utils.GetBrowserId(),
                selectedTime: time
            }
        })
    }

    getOpenCloseElement = (theme, item) => {
        if (!this.state.faq[theme][item])
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M4 8L12 16L20 8" stroke="#474747" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>;
        return <svg style={{rotate: '180deg'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M4 8L12 16L20 8" stroke="#474747" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>;
    }

    showFaqTheme = (theme) => {
        if (this.state.faq[theme].show)
        {
            let faq = this.state.faq;
            faq[theme] = { show: false };
            this.setState({faq: faq});
        }
        else
        {
            let faq = this.state.faq;
            faq[theme] = { show: true };
            this.setState({faq: faq});
        }
    }

    showFaqThemeContent = (theme, content) => {
        if (!this.state.faq[theme][content])
        {
            
            let faq = this.state.faq;
            faq[theme][content] = true;
            this.setState({faq: faq});

            connection.SendActionStats({browserId: Utils.GetBrowserId(), actionId: 'Q' + theme + 'E' + content});
        }
        else
        {
            let faq = this.state.faq;
            faq[theme][content] = false;
            this.setState({faq: faq});
        }
    }


    afterLogin = () => {

        this.props.onAuth();

        this.setState({showRegister: false, authed: true, booking: true});

        this.bookConsultation(this.state.selectedTime);
    }

    render() {
        if (this.state.toSurvey)
            return <Navigate to="/survey" />;

        if (!this.state.analyzer)
            return <div style={{height: '600px', position: 'relative'}}><Loader /><div style={{position: 'absolute', top: '65%', width: '100%'}}><div style={{textAlign: 'center'}}>Анализируем ваш запрос</div></div></div>;

        
        if (!this.state.finder)
            return <div style={{height: '600px', position: 'relative'}}><Loader /><div style={{position: 'absolute', top: '65%', width: '100%'}}><div style={{textAlign: 'center'}}>Подбираем подходящих специалистов</div></div></div>;

        if (!this.state.loaded || !this.state.categoriesLoaded || !this.state.therapyMethods)
            return <div style={{height: '600px'}}><Loader /></div>;

        if (this.state.payment)
        {
            this.props.onSelected();
            return <Navigate to='/payment'/>
        }

        if (this.state.error || !this.state.selectedSpecialist)
            return 'Произошла ошибка, попробуйте позже';

        if (this.state.booking)
            return <div style={{height: '600px'}}><Loader /></div>;

        return <>
            <div className="reg-steps">
                <Link to={'/survey' + window.location.search} style={{textDecoration: 'none', cursor: 'pointer'}}>
                    <div className="reg-steps-item">
                        <span className="step-num step-num-current step-num-active">1</span>
                        <span className="body-text-small reg-num-title">Общие вопросы</span>
                    </div>
                </Link>
                <div className="reg-steps-divide reg-steps-divide-type1"></div>
                <div className="reg-steps-item">
                    <span className="step-num step-num-current step-num-active">2</span>
                    <span className="body-text-small reg-num-title">Выбор специалиста</span>
                </div>


                {this.props.state == 3 ?
                <>
                                        <div className="reg-steps-divide reg-steps-divide-type1"></div>

                    <Link to='/payment'>
                        <div className="reg-steps-item">
                            <span className="step-num step-num-current">3</span>
                            <span className="body-text-small reg-num-title">Запись и оплата</span>
                        </div>
                </Link>
                </>
                :
                <>
                <div className="reg-steps-divide reg-steps-divide-type1"></div>
                <div className="reg-steps-item">
                        <span className="step-num">3</span>
                        <span className="body-text-small reg-num-title">Запись и оплата</span>
                    </div>
                    </>
                }
            </div>


            {!this.state.bannerButton ?
            <div className='banner'>
                <div className="banner-close" onClick={() => this.setState({bannerButton: true})}>x</div>
                <div className='banner-header'>
                    <b>Скидка 20%</b> на первую сессию
                </div>
                <div className='banner-content'>
                    <div className='banner-text'>Промокод «<b>ВЕСНА20</b>» до 30 апреля</div>
                    <button onClick={() => navigator.clipboard.writeText('ВЕСНА20').then(() => this.setState({bannerButton: true}))} className='banner-button'>{this.state.bannerButton ? 'Скопировано' : 'Применить'}</button>
                </div>
            </div>
            :
            undefined
            }


            {!this.state.noSpecialists ?
            <>
            {this.state.message ?
                <MessageModal show={true} message={this.state.message} header={"Информация"} onHide={() => this.setState({message: null})}/>
                :
                undefined
            }
            {this.state.showTimeSelector ?
                <DateTimeSelectorOnboarding show={true} specialistId={this.state.selectedSpecialist.specialistId} onSelect={(time) => this.addAdditionalTime(time)} onHide={() => this.setState({showTimeSelector: false})}/>
            :
                undefined
            }

            {this.state.showBookingModal && !this.state.authed && !this.props.auth?
                <FreeBookingModal 
                    specialistId={this.state.selectedSpecialist.specialistId}
                    price={this.state.selectedSpecialist.price}
                    specialistName={this.state.selectedSpecialist.name}
                    specialistPhotoUrl={this.state.selectedSpecialist.photoUrl}
                    onBooked={() => this.setState({payment: true})} 
                    onHide={() => this.setState({showBookingModal: false})}
                    onSelectedTime={(selectedTime) => this.bookConsultation(selectedTime)}
                    consultationType={this.state.selectedSpecialist.consultationType}
                    timezoneOffset={this.state.timezoneOffset}
                    register={(time) => this.setState({showRegister: true, selectedTime: time}, () => this.sendPreRegisterStats(time))}
                />
            :    
                undefined
            }

            {this.state.showRegister ?
                <RegisterModal onHide={() => this.setState({showRegister: false})}
                    subcategoriesId={this.state.subcategoriesId}
                    preferredGender={this.state.preferredGender}
                    timezoneOffset={this.state.timezoneOffset}
                    timePeriods={this.state.timePeriods}
                    onAuth={() => {this.afterLogin()}}
                />
                :
                undefined
            }


            {this.state.showBookingModal && this.props.auth?
                <ConsultationBookingModal 
                    specialistId={this.state.selectedSpecialist.specialistId}
                    price={this.state.selectedSpecialist.price}
                    specialistName={this.state.selectedSpecialist.name}
                    specialistPhotoUrl={this.state.selectedSpecialist.photoUrl}
                    onBooked={() => this.setState({payment: true})} 
                    onHide={() => {this.setState({showBookingModal: false})}}
                    onSelectedTime={(selectedTime) => this.bookConsultation(selectedTime)}
                    consultationType={this.state.selectedSpecialist.consultationType}
                    timezoneOffset={this.state.timezoneOffset}
                />
            :    
                undefined
            }

            
            <div className="big-card">
                <SpecialistsSlider mode={"person-selector-top"} sliderLength={7} onSelect={(id) => this.selectSpecialst(id)} selectedSpec={this.state.selectedSpecialist.specialistId} specialists={this.state.specialists.map(item => { return { id: item.specialistId, photo: item.photoUrl}})}/>
                <SpecialistsSlider 
                    mode={this.state.specialists.length >= 5 ? "specialist-mobile-slider" : 'specialist-mobile-slider specialist-mobile-slider-few'} 
                    sliderLength={5} 
                    onSelect={(id) => this.selectSpecialst(id)} 
                    selectedSpec={this.state.selectedSpecialist.specialistId} 
                    specialists={this.state.specialists.map(item => { return { id: item.specialistId, photo: item.photoUrl}})}
                />

                <div className="about">
                    <div id='booking' className="person-details">
                        <img className="person-avatar" src={ this.state.selectedSpecialist.photoUrl ? 'avatars/' + this.state.selectedSpecialist?.photoUrl : "images/avatars/selected.png"}/>
                        <div className="select-about-block">
                            <div className="select-about-details">
                                <h1>{this.state.selectedSpecialist.name}</h1>
                                {this.getExperience()}
                                <span>Сессия {this.state.selectedSpecialist.consultationType === "Double" ? '100' : '55'} мин - <span style={{fontWeight: 600}}>{this.state.selectedSpecialist.price} ₽</span></span>
                               {/* <span className="selected-date">{this.parseDate(this.state.selectedSpecialist)}</span>*/}
                               {this.getClosestTimeBlock(this.state.selectedSpecialist.closestTime)}

                            </div>
                            
                            <button onClick={() => this.setState({showBookingModal: true}, this.sendStats())} className="reg-time-button">Выбрать время сессии</button>
                            
                        </div>
                    </div>
                    <SpecialistsSlider 
                        mode={"person-selector-inside"} 
                        sliderLength={5} 
                        onSelect={(id) => this.selectSpecialst(id)}
                        selectedSpec={this.state.selectedSpecialist.specialistId} 
                        specialists={this.state.specialists.map(item => { return { id: item.specialistId, photo: item.photoUrl}})}
                    />
                </div>

                <div className="reg-first-blocks">
                    {/*this.getWorkThemes()*/}

                    {this.getTherapyMethods()}

                    {this.state.selectedSpecialist.videoUrl ?
                    <div className="reg-video-block">
                        <h2 className="reg-block-header">Видеопрезентация специалиста</h2>
                        <div className="video-presentation">
                            <iframe style={{width: '100%', height: '100%'}} src={"https://www.youtube.com/embed/" + this.state.selectedSpecialist.videoUrl}  title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                    :
                    undefined
                    }
                </div>
                <div className="divide-line"></div>
                <div>
                    <h2 className="reg-block-header">О специалисте</h2>
                    <div className="to-overlap" style={this.state.showSecondBlock ? {height: '100%'} : {height: '90px', overflow: 'hidden'}}>
                        <div style={{whiteSpace: 'pre-wrap'}}>
                        {this.state.selectedSpecialist.about}
                        </div>

                        { !this.state.showSecondBlock ?
                        <div className="overlap"></div>
                        :
                        ''
                        }
                    </div>

                    {!this.state.showSecondBlock ? 
                    <div onClick={() => this.setState({showSecondBlock: true})} className="show-more">
                        <span>Показать ещё</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#3F67F4" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.7071 10.7071C17.0976 10.3166 17.0976 9.68342 16.7071 9.29289C16.3166 8.90237 15.6834 8.90237 15.2929 9.29289L16.7071 10.7071ZM12 14L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L12 14ZM8.70711 9.29289C8.31658 8.90237 7.68342 8.90237 7.29289 9.29289C6.90237 9.68342 6.90237 10.3166 7.29289 10.7071L8.70711 9.29289ZM15.2929 9.29289L11.2929 13.2929L12.7071 14.7071L16.7071 10.7071L15.2929 9.29289ZM12.7071 13.2929L8.70711 9.29289L7.29289 10.7071L11.2929 14.7071L12.7071 13.2929Z" fill="#3A767B"/>
                        </svg>
                    </div>
                    :
                    <div onClick={() => this.setState({showSecondBlock: false})} className="show-more" style={{marginTop: '1rem'}}>
                        <span>Свернуть</span>
                        <svg style={{transform: 'rotate(180deg)'}} width="24" height="24" viewBox="0 0 24 24" fill="#3F67F4" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.7071 10.7071C17.0976 10.3166 17.0976 9.68342 16.7071 9.29289C16.3166 8.90237 15.6834 8.90237 15.2929 9.29289L16.7071 10.7071ZM12 14L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L12 14ZM8.70711 9.29289C8.31658 8.90237 7.68342 8.90237 7.29289 9.29289C6.90237 9.68342 6.90237 10.3166 7.29289 10.7071L8.70711 9.29289ZM15.2929 9.29289L11.2929 13.2929L12.7071 14.7071L16.7071 10.7071L15.2929 9.29289ZM12.7071 13.2929L8.70711 9.29289L7.29289 10.7071L11.2929 14.7071L12.7071 13.2929Z" fill="#3A767B"/>
                        </svg>
                    </div>
                    }
                </div>
                <div className="divide-line"></div>
                <div className="about-block">
                    <h2 className="reg-block-header">Образование</h2>
                    <div className="to-overlap" style={this.state.showThirdBlock ? {height: '100%'} : {height: '100px', overflow: 'hidden'}}>
                        {this.getSpecialistEducation()}
                        {!this.state.showThirdBlock ?
                        <div className="overlap"></div>
                        :
                        ''
                        }
                    </div>

                    { !this.state.showThirdBlock ?
                    <div onClick={() => this.setState({showThirdBlock: true})} className="show-more">
                        <span>Показать ещё</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#3F67F4" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.7071 10.7071C17.0976 10.3166 17.0976 9.68342 16.7071 9.29289C16.3166 8.90237 15.6834 8.90237 15.2929 9.29289L16.7071 10.7071ZM12 14L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L12 14ZM8.70711 9.29289C8.31658 8.90237 7.68342 8.90237 7.29289 9.29289C6.90237 9.68342 6.90237 10.3166 7.29289 10.7071L8.70711 9.29289ZM15.2929 9.29289L11.2929 13.2929L12.7071 14.7071L16.7071 10.7071L15.2929 9.29289ZM12.7071 13.2929L8.70711 9.29289L7.29289 10.7071L11.2929 14.7071L12.7071 13.2929Z" fill="#3A767B"/>
                        </svg>
                    </div>
                    :
                    <div onClick={() => this.setState({showThirdBlock: false})} className="show-more" style={{marginTop: '1rem'}}>
                        <span>Свернуть</span>
                        <svg style={{transform: 'rotate(180deg)'}} width="24" height="24" viewBox="0 0 24 24" fill="#3F67F4" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.7071 10.7071C17.0976 10.3166 17.0976 9.68342 16.7071 9.29289C16.3166 8.90237 15.6834 8.90237 15.2929 9.29289L16.7071 10.7071ZM12 14L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L12 14ZM8.70711 9.29289C8.31658 8.90237 7.68342 8.90237 7.29289 9.29289C6.90237 9.68342 6.90237 10.3166 7.29289 10.7071L8.70711 9.29289ZM15.2929 9.29289L11.2929 13.2929L12.7071 14.7071L16.7071 10.7071L15.2929 9.29289ZM12.7071 13.2929L8.70711 9.29289L7.29289 10.7071L11.2929 14.7071L12.7071 13.2929Z" fill="#3A767B"/>
                        </svg>
                    </div>
                    }
                </div>
                <div className="divide-line"></div>
                
                <div className="about-block">
                    <h2 className="reg-block-header">Частые вопросы</h2>
                    <div className="faq-block">
                        <div onClick={() => this.showFaqTheme(0)} className="faq-header">
                            <div className="faq-title">О работе с психологом</div>
                            <div>{this.getOpenCloseElement(0, 'show')}</div>
                        </div>
                        <div style={this.state.faq[0]?.show ? {} : {display: 'none'}} className="faq-content">
                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(0, 7)} className="faq-header">
                                    <div className="faq-title">Мой диалог с психологом конфиденциален?</div>
                                    <div>
                                        {this.getOpenCloseElement(0, 7)}
                                    </div>
                                </div>
                                <div style={this.state.faq[0][7] ? {} : {display: 'none'}} className="faq-content-entire">
                                    Ваша информация защищена на 100% и не передается третьим лицам. Только вы и психолог.
                                    Специалисты Uwio подписывают соответствующее соглашение
                                </div>
                            </div>

                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(0, 0)} className="faq-header">
                                    <div className="faq-title">Как проходит 1-ая сессия?</div>
                                    <div>
                                        {this.getOpenCloseElement(0, 0)}
                                    </div>
                                </div>
                                <div style={this.state.faq[0][0] ? {} : {display: 'none'}} className="faq-content-entire">
                                    Первая сессия отводится на диагностику, уточнение запроса, знакомство и формирование целей психотерапии. 
                                    <br/><br/>
                                    Для того, чтобы понять реальные причины ваших проблем и сформировать план терапии, психолог задаст  несколько вопросов о вашем  состоянии, вашей жизни и сопутствующих факторах, которые формируют нежелательный эмоциональный статус и деструктивные поведенческие реакции.
                                    По итогам первой сессии у вас появится детальное видение вашей проблемы и план работы с психологом.
                                </div>
                            </div>

                            <div className="faq-divide-line"></div>                            

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(0, 1)} className="faq-header">
                                    <div className="faq-title">Как подготовиться к первой сессии?</div>
                                    <div>
                                        {this.getOpenCloseElement(0, 1)}
                                    </div>
                                </div>
                                <div style={this.state.faq[0][1] ? {} : {display: 'none'}} className="faq-content-entire">
                                    В специальной подготовке нет необходимости. Но если у вас есть время и возможность, можете обдумать 3 вопроса - это поможет вам лучше понять свои цели и ожидания от психотерапии: 
                                    <br/><br/>
                                    1. На какие сферы вашей жизни негативно влияют ваши эмоции, мысли  поведение? <br/>
                                    2. Что вы хотели бы изменить в себе?<br/>
                                    3. К какому результату вы хотели бы прийти к завершению работы с психологом?<br />
                                </div>
                            </div>

                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(0, 2)} className="faq-header">
                                    <div className="faq-title">Как часто нужно встречаться со специалистом?</div>
                                    <div>
                                        {this.getOpenCloseElement(0, 2)}
                                    </div>
                                </div>
                                <div style={this.state.faq[0][2] ? {} : {display: 'none'}} className="faq-content-entire">
                                    В первое время оптимальная частота занятий - раз в неделю.
                                    <br/><br/>
                                    Такая периодичность встреч позволит вам погрузиться в работу, делать домашние задания, которые дал вам психолог  и наблюдать за изменениями в своей жизни. 
                                    В дальнейшем вы можете встречаться с психологом реже.
                                    В кризисных состояниях частота встреч с психологом может доходить до 2-3 раз в неделю.
                                </div>
                            </div>

                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(0, 3)} className="faq-header">
                                    <div className="faq-title">Как устроена работа с психологом?</div>
                                    <div>
                                        {this.getOpenCloseElement(0, 3)}
                                    </div>
                                </div>
                                <div style={this.state.faq[0][3] ? {} : {display: 'none'}} className="faq-content-entire">
                                    В мире существует более 600 психотерапевтических подходов.
                                    <br/><br/>
                                    Психологи Uwio используют подходы и протоколы когнитивно-поведенческой терапии, которые максимально эффективны при эмоциональных и поведенческих дисбалансах, таких к примеру, как тревожность, выгорание, стрессовые и депрессивные состояния, эмоциональные зависимости и поведенческие реакции, которые зачастую связаны с эмоциональными проблемами (к примеру компульсивное переедание или сексуальные трудности).
                                    <br/><br/>
                                    В рамках работы, психолог будет использовать разные методы, чтобы сделать терапию максимально индивидуальной. К примеру, кому-то подойдет рациональный и логичный подход, а в каких-то случаях психолог будет применять более "эластичный",  комбинированный инструментарий.
                                    <br/><br/>
                                    Сессии проходят в формате разговора о том, что тревожит клиента и опираются на специальные техники.
                                    Психолог будет помогать вам найти скрытые и деструктивные паттерны мышления, которые влияют на ваше эмоциональное состояние и поведение.
                                    Также вы будете учиться анализировать свои действия,  мысли и эмоции, вести специальный дневник и выполнять  домашние задания, для закрепления полученных навыков.
                                </div>
                            </div>

                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(0, 4)} className="faq-header">
                                    <div className="faq-title">Сколько сессий потребуется?</div>
                                    <div>
                                        {this.getOpenCloseElement(0, 4)}
                                    </div>
                                </div>
                                <div style={this.state.faq[0][4] ? {} : {display: 'none'}} className="faq-content-entire">
                                    Философия Uwio - краткосрочная и сфокусированная на результате терапия, поэтому мы изначально стремимся помочь вам достичь изменений в оптимальные сроки. 
                                    <br/><br/>
                                    Перечислим основные факторы, влияющие на длительность терапии:
                                    <br/><br/>
                                    <span className="faq-content-entire-line"><span className="edu-item-mark"></span>Особенности вашей личности: кому-то легко описывать личное и анализировать себя, а кому-то на это нужно больше времени.</span>
                                    <span className="faq-content-entire-line"><span className="edu-item-mark"></span>Сложность вашей проблемы.К примеру  с депрессией и выгоранием необходимо работать дольше, чем с тревожностью.</span>
                                    <span className="faq-content-entire-line"><span className="edu-item-mark"></span>Ваши цели и ожидания от терапии</span>
                                    <span className="faq-content-entire-line"><span className="edu-item-mark"></span>Готовность следовать рекомендациям вашего психолога и выполнять домашние задания. Если вы идете по намеченному с вашим психологом плану и прикладываете хотя-бы минимальное количество усилий для работы над собой, то кратко увеличиваете эффективность психотерапии.</span>
                                    <br/>
                                    В среднем, на проработку конкретной проблемы требуется, примерно, 3-6 сессий.
                                    <br/><br/>
                                    Если вам нужны более глубокие изменения и регулярная поддержка специалиста, после окончания основного курса вы можете продолжить ваши занятия с психологом в формате дополняющих встреч.
                                </div>
                            </div>

                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(0, 5)} className="faq-header">
                                    <div className="faq-title">Нужно ли разбираться в подходах психотерапии, чтобы добиться лучших результатов?</div>
                                    <div>
                                        {this.getOpenCloseElement(0, 5)}
                                    </div>
                                </div>
                                <div style={this.state.faq[0][5] ? {} : {display: 'none'}} className="faq-content-entire">
                                    Платформа Uwio специализируется на работе с эмоциональными и поведенческими дисбалансами, поэтому  мы отбираем специалистов, работающих именно в тех подходах, которые являются качественным стандартом в терапии данного кластера проблем, по этой причине  вам не обязательно разбираться в теоретических аспектах  подходов - мы уже выбрали для вас самых компетентных специалистов. 
                                    <br/> <br/>
                                    Психологи Uwio опираются на методы когнитивно-поведенческой терапии, которая доказала свою эффективность в клинических исследованиях по всему миру и рекомендована ВОЗ для эффективной терапии следующего перечня дисбалансов:
                                    <br/> <br/>
                                    <span className="edu-item-mark"></span>Депрессивные состояния и выгорание<br/>
                                    <span className="edu-item-mark"></span>Тревожность и фобические проявления<br/>
                                    <span className="edu-item-mark"></span>Стрессовые состояния и панические атаки<br/>
                                    <span className="edu-item-mark"></span>Компульсивное поведение<br/>
                                    <span className="edu-item-mark"></span>Эмоциональные зависимости<br/>
                                    <span className="edu-item-mark"></span>Психогенное переедание<br/>
                                    <span className="edu-item-mark"></span>Бессонница и упадок сил<br/>
                                </div>
                            </div>

                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(0, 6)} className="faq-header">
                                    <div className="faq-title">Не могу сформулировать  запрос и темы для обсуждения с психологом</div>
                                    <div>
                                        {this.getOpenCloseElement(0, 6)}
                                    </div>
                                </div>
                                <div style={this.state.faq[0][6] ? {} : {display: 'none'}} className="faq-content-entire">
                                    Первые сессии с психологом часто отводятся именно на анализ и формулировку запроса.
                                    Мы зачастую не понимаем, почему находимся в том или состоянии и что именно  снижает качество нашей жизни.
                                    Важная задача психолога - помочь вам все прояснить.
                                </div>
                            </div>

                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(0, 8)} className="faq-header">
                                    <div className="faq-title">Как я смогу понять, что психотерапия эффективна?</div>
                                    <div>
                                        {this.getOpenCloseElement(0, 8)}
                                    </div>
                                </div>
                                <div style={this.state.faq[0][8] ? {} : {display: 'none'}} className="faq-content-entire">
                                    
                                <span className="edu-item-mark"></span>В первую очередь, по собственным ощущениям. 
                                Через несколько сессий вы почувствуете, что ваше эмоциональное состояние стало меняться в лучшую сторону: вам будет гораздо проще делать то, что вы любите, появится энергия, улучшится продуктивность и общее самочувствие. 
                                А еще, вы начнете лучше анализировать себя и замечать те мысли, которые вызывают  негативные эмоциональные  реакции и поведение - это позволит вам прийти к нужным  изменениям в своем состоянии и жизни. <br/>
                                <br/>
                                <span className="edu-item-mark"></span>В дальнейшем, для более глубокого анализа, психолог будет использовать специально разработанные аналитические протоколы, которые помогут вам детальнее оценить ваши изменения и внести корректировки в процесс психотерапии, если они будут необходимы.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="faq-block">
                        <div onClick={() => this.showFaqTheme(1)} className="faq-header">
                            <div className="faq-title">О подборе психологов</div>
                            <div>{this.getOpenCloseElement(1, 'show')}</div>
                        </div>
                        <div style={this.state.faq[1]?.show ? {} : {display: 'none'}} className="faq-content">
                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(1, 0)} className="faq-header">
                                    <div className="faq-title">Почему именно эти специалисты?</div>
                                    <div>
                                        {this.getOpenCloseElement(1, 0)}
                                    </div>
                                </div>
                                <div style={this.state.faq[1][0] ? {} : {display: 'none'}} className="faq-content-entire">
                                    Мы проанализировали ваши основные и сопутствующие  проблемы. На основании карты ваших ответов подбираются специалисты, которые эффективно работают именно с этим кластером проблем.
                                </div>
                            </div>

                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(1, 1)} className="faq-header">
                                    <div className="faq-title">Почему нет возможности связаться напрямую?</div>
                                    <div>
                                        {this.getOpenCloseElement(1, 1)}
                                    </div>
                                </div>
                                <div style={this.state.faq[1][1] ? {} : {display: 'none'}} className="faq-content-entire">
                                    Все специалисты проходят многоступенчатую проверку образования, компетенций и работают по нашим стандартам и протоколам,  поэтому для анализа качества оказания услуг нам важно, чтобы коммуникация со специалистами осуществлялась внутри нашей платформы. Надеемся на ваше понимание.
                                </div>
                            </div>

                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(1, 2)} className="faq-header">
                                    <div className="faq-title">Почему у специалистов нет отзывов?</div>
                                    <div>
                                        {this.getOpenCloseElement(1, 2)}
                                    </div>
                                </div>
                                <div style={this.state.faq[1][2] ? {} : {display: 'none'}} className="faq-content-entire">
                                    В психотерапии, отзывы - это субъективная гарантия качества. Если целью психолога будет получение отзывов, это может повлиять на процесс его работы и эффективность терапии, что противоречит этике и принципам нашей платформы. К тому же у всех клиентов разные запросы и типы личностей, соответственно, хороший отзыв от одного клиента не является гарантом результата для другого.
                                    <br/><br/>
                                    Тот факт, что специалист сотрудничает с платформой Uwio, уже является подтверждением его компетентности - мы уверены в качестве работы нашей команды психологов.
                                    <br/><br/>
                                    Мы проверяем образование специалистов, их подготовку в психотерапевтических подходах и успешные кейсы в решении эмоциональных и поведенческих проблем клиентов.
                                </div>
                            </div>
                            
                        </div>
                    </div>


                    <div className="faq-block">
                        <div onClick={() => this.showFaqTheme(2)} className="faq-header">
                            <div className="faq-title">Запись к психологу и оплата</div>
                            <div>{this.getOpenCloseElement(2, 'show')}</div>
                        </div>
                        <div style={this.state.faq[2]?.show ? {} : {display: 'none'}} className="faq-content">
                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(2, 0)} className="faq-header">
                                    <div className="faq-title">Как оплатить консультацию?</div>
                                    <div>
                                        {this.getOpenCloseElement(2, 0)}
                                    </div>
                                </div>
                                <div style={this.state.faq[2][0] ? {} : {display: 'none'}} className="faq-content-entire">
                                    Оплатить консультацию необходимо на нашей платформе, после выбора даты и времени.<br/>
                                    <br/>
                                    Оплата сессий в сервисе проходит через безопасную сделку. За 8 часов до консультации с психологом стоимость сессии спишется с вашей карты и заморозится. На счёт специалиста деньги поступят только после сессии. Таким образом мы можем гарантировать, что вы получите консультацию, а психолог оплату своего труда. Если специалист не провел сессию или что-то пошло не так, обратитесь в службу поддержки - мы вернем деньги.<br/>
                                    <br/>
                                    Ссылка на следующие записи приходят по SMS.
                                </div>
                            </div>


                           
                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(2, 2)} className="faq-header">
                                    <div className="faq-title">Как перенести или отменить сессию с психологом?</div>
                                    <div>
                                        {this.getOpenCloseElement(2, 2)}
                                    </div>
                                </div>
                                <div style={this.state.faq[2][2] ? {} : {display: 'none'}} className="faq-content-entire">
                                    Отменить или перенести сессию вы можете в вашем личном кабинете на панели управления сессиями.
                                    <br/><br/>
                                    Бесплатная отмена и перенос сессии возможны более чем за 8 часов до ее начала.
                                </div>
                            </div>                            
                            

                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(2, 4)} className="faq-header">
                                    <div className="faq-title">Могу ли связаться со специалистом перед сессией?</div>
                                    <div>
                                        {this.getOpenCloseElement(2, 4)}
                                    </div>
                                </div>
                                <div style={this.state.faq[2][4] ? {} : {display: 'none'}} className="faq-content-entire">
                                    Да, в вашем личном кабинете будет чат с вашим психологом.<br/>
                                    Так же чатом можно пользоваться и  между сессиями.
                                </div>
                            </div>
                            
                        </div>
                    </div>


                    <div className="faq-block">
                        <div onClick={() => this.showFaqTheme(3)} className="faq-header">
                            <div className="faq-title">О платформе Uwio</div>
                            <div>{this.getOpenCloseElement(3, 'show')}</div>
                        </div>
                        <div style={this.state.faq[3]?.show ? {} : {display: 'none'}} className="faq-content">
                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(3, 0)} className="faq-header">
                                    <div className="faq-title">Почему Uwio, а не рекомендации друзей и знакомых?</div>
                                    <div>
                                        {this.getOpenCloseElement(3, 0)}
                                    </div>
                                </div>
                                <div style={this.state.faq[3][0] ? {} : {display: 'none'}} className="faq-content-entire">
                                    У специалиста, который помог вашему другу или знакомому может не оказаться нужных знаний и опыта для работы именно с вашим запросом. 
                                    <br/><br/>
                                    Речь идет о специализации: если психолог помог кому-то решить проблемы в отношениях, далеко не факт, что он поможет  преодолеть тревожное состояние или выгорание. <br/>
                                    К тому же знакомый может затрудняться в оценке компетенций специалиста и своих результатов.
                                    <br/><br/>
                                    С нашей стороны мы гарантируем компетентность специалистов.
                                </div>
                            </div>

                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(3, 1)} className="faq-header">
                                    <div className="faq-title">Какие преимущества есть в Uwio и чем мы отличаемся от других сервисов или агрегаторов?</div>
                                    <div>
                                        {this.getOpenCloseElement(3, 1)}
                                    </div>
                                </div>
                                <div style={this.state.faq[3][1] ? {} : {display: 'none'}} className="faq-content-entire">
                                    <span style={{fontWeight: 600}}>Во первых - наш профессиональный фокус и строгий отбор специалистов</span>
                                    <br/><br/>
                                    Uwio - это про эмоциональную устойчивость.<br/>
                                    Мы специализируемся на эффективной работе с эмоциональными проблемами, к примеру, депрессивными и тревожными состояниями, стрессом и выгоранием, поэтому сотрудничаем только с теми психологами, кто имеет необходимое образование, опыт и владеет методами  для успешного решения этой группы проблем.<br/>
                                    Все специалисты проходят строгую проверку и селекцию по необходимым нам специализациям.
                                    <br/><br/>
                                    <span style={{fontWeight: 600}}>Во вторых - оптимальная длительность терапии.</span>
                                    <br/><br/>
                                    Наша философия - не затягивать терапию на многие месяцы или годы, а помочь человеку здесь и сейчас.  Все специалисты Uwio работают в краткосрочном формате психотерапии и сфокусированы на конкретной проблеме и ее решении.
                                    <br/><br/>
                                    <span style={{fontWeight: 600}}>В третьих - стремимся развивать ваши навыки</span>
                                    <br/><br/>
                                    Психологи Uwio не только поддерживают и ведут вас к нужным изменениям, но и обучают навыкам самоанализа и управления  эмоциональным состоянием, которые помогут вам в будущем справляться с трудностями самостоятельно и легко.
                                    <br/><br/>
                                    <span style={{fontWeight: 600}}>В четвертых - простота выбора и удобство</span>
                                    <br/><br/>
                                    Мы создали IT-платформу, которая проанализирует ваше  эмоциональное состояние и сопутствующие проблемы, предложит подходящих специалистов на удобное время, а так же позволит посещать сессии в личном кабинете по конфиденциальной видео-связи.
                                </div>
                            </div>

                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(3, 2)} className="faq-header">
                                    <div className="faq-title">Как психологи проходят проверку?</div>
                                    <div>
                                        {this.getOpenCloseElement(3, 2)}
                                    </div>
                                </div>
                                <div style={this.state.faq[3][2] ? {} : {display: 'none'}} className="faq-content-entire">
                                    На данный момент в России нет законов об оказании психологической помощи и специалисты не проходят лицензирование: никто не проверяет образование, качество работы и эффективность методов практикующих психологов.<br/>
                                    К тому же, очень важно разделять психологов по их специализациям.<br/>
                                    Uwio решает для вас эти проблемы.<br/><br/>

                                    <span className="edu-item-mark"></span>На первом этапе мы проверяем наличие профильного психологического или медицинского образования<br/>
                                    <br/>
                                    <span className="edu-item-mark"></span>На втором этапе проводим селекцию по специализациям - мы сотрудничаем с психологами,  работающими в методах когнитивно-поведенческой терапии, которая наиболее эффективна при эмоциональных и поведенческих дисбалансах.<br/>
                                    <br/>
                                    <span className="edu-item-mark"></span>На третьем этапе проводим персональное интервью, в рамках которого рассматриваем реальный опыт работы специалиста,  его знания о работе и устройстве психики и умение правильно работать с запросом клиента
                                </div>
                            </div>

                            <div className="faq-divide-line"></div>

                            <div className="faq-content-block">
                                <div onClick={() => this.showFaqThemeContent(3, 3)} className="faq-header">
                                    <div className="faq-title">Как работает служба заботы Uwio?</div>
                                    <div>
                                        {this.getOpenCloseElement(3, 3)}
                                    </div>
                                </div>
                                <div style={this.state.faq[3][3] ? {} : {display: 'none'}} className="faq-content-entire">
                                    Если у вас возникнут вопросы о записи к психологу, оплате или технические сбои, вы всегда можете написать в телеграмм нашей службы заботы <a href="https://t.me/uwio_help">t.me/uwio_help</a>
                                    <br/><br/>
                                    Среднее время ответа службы заботы 2-3 часа
                                </div>
                            </div>

                            
                        </div>
                    </div>

                </div>
            </div>

            {this.state.showFloating ?
            <div className="booking-floating top-floating" style={{top: 0, bottom: 'auto'}}>
                <div className="booking-floating-content">
                    
                    <div style={{textAlign: 'center', fontWeight: '600', fontSize: '25px', marginBottom: '10px'}}>{this.state.selectedSpecialist.name}</div>

                    <button onClick={() => this.setState({showBookingModal: true}, this.sendStats())} className="booking-btn">
                        <span>Выбрать время сессии</span>
                    </button>
                </div>
            </div> : undefined }
            </>
            :
            <div className="big-card">
                К сожалению на выбранное время все специалисты забронированы, пожалуйста, выберите другое
            </div>
            }

        </>;
    }
}

export default FreeSelectSpecialist;