import React from "react";


class MessageModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    render() {

        return <div style={{zIndex: 100}} className="modal-block" onClick={() => this.props.onHide()}>
            <div className={"modal-inside body-text-small " + this.props.className} onClick={(e) => e.stopPropagation()}>
                <h1>{this.props.header}</h1>
                <div style={{marginTop: '1rem', marginBottom: '1rem', whiteSpace: 'pre-line'}}>
                    {this.props.message}
                </div>
                <div style={{display: 'flex', justifyContent: 'end'}}>
                    <button onClick={() => this.props.onHide()}>Ок</button>
                </div>
            </div>
        </div>;
    }

}

export default MessageModal;