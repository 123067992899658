import React from "react";
import { Navigate } from "react-router";

class BookingSuccess extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
        window.ym(94253611,'reachGoal','boughtsession');
        window._tmr.push({ type: 'reachGoal', id: 3479354, goal: 'stranica_success'});

        setInterval(() => {
            this.setState({redirect: true})
        }, 8000);

    }

    render() {
        if (this.state.redirect)
            return <Navigate to="/room" />

        return <div className="success-content">
            <div className="success-icon"><img src="images/icons/bookingSuccess.svg"/></div>
            <div className="success-title">Сессия забронирована</div>
            <div className="success-text">Переводим вас в личный кабинет</div>
        </div>;
    }
}

export default BookingSuccess;