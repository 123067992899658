import React from "react";
import { Navigate } from "react-router";
import { Link } from "react-scroll";
import connection from "../scripts/connection";
import Loader from "./Loader";


class NextConsulatations extends React.Component {


    constructor(props) {
        super(props);

        // Получить данные по след. консультациям
        this.state = {
            consultations: [],
            redirect: null,
        }
    }

    componentDidMount() {
        connection.AuthorizeGet('/specialist/NextConsultations',
        (success) => {
            console.log(success)
            this.setState({
                consultations: success.value,
                loaded: true
            })
        },
        (error) => {
            console.log(error)
        },
        (fatal) => {
            console.log(fatal)
        });
    }

    consultationTime = (time) => {
        let dt = new Date(time);
        let day = dt.getUTCDate() < 10 ? '0' + dt.getUTCDate() : dt.getUTCDate();
        let month = (dt.getUTCMonth() + 1) < 10 ? '0' + (dt.getUTCMonth() + 1) : (dt.getUTCMonth() + 1);
        let year = dt.getUTCFullYear();

        let minutes = dt.getUTCMinutes() < 10 ? '0' + dt.getUTCMinutes() : dt.getUTCMinutes();
        let hours = dt.getUTCHours() < 10 ? '0' + dt.getUTCHours() : dt.getUTCHours();

        return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;
    }

    profile = (clientId) => {
        console.log('clicked')
        localStorage.setItem('clientId', clientId);
        this.setState({redirect: true});
    }


    showConsultations = () => {
        if (this.state.consultations.length === 0)
            return <div style={{marginTop: 'auto', marginBottom: 'auto', textAlign: 'center'}}>Нет запланированных консультаций</div>;

        if (this.state.redirect)
            return <Navigate to='/client'/>

        return this.state.consultations.map((item, index) => {
            return (
            <div key={index}>
                <h3 onClick={() => this.profile(item.clientId)} style={{marginBottom: '0.5rem', color: '#3F67F4', cursor: 'pointer'}}>
                    {item.clientName}
                </h3>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>{item.consultationType === 1 ? 'Парная' : 'Индивидуальная'}</div>
                <div>
                    {this.consultationTime(item.date)}
                </div>
                </div>
                <hr />
            </div>
        )
        });
    }

    render() {
    if (!this.props.show)
        return;


    return <div className="modal-block" onClick={this.props.onHide}>
        <div className="body-text-small modal-inside" onClick={(e) => e.stopPropagation()}>
            <h2 style={{marginBottom: '1rem'}}>Следующие консультации</h2>
            <div style={{display: 'flex', flexFlow: 'column', overflow: 'auto', height: '400px'}}>
                {this.state.loaded ? 
                this.showConsultations()
                :
                <Loader />
                }
            </div>
        </div>
    </div>;
    }
}

export default NextConsulatations;