import React from "react";
import { decodeToken } from "react-jwt";
import Connections from '../scripts/connection'
import Loader from "./Loader";


class SpecialistProfile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cat1: false,
            cat2: false,
            cat3: false,
            workCategories: [],

            name: 'Екатерина Усова',
            about: 'Лишь элементы политического процесса будут своевременно верифицированы. В своём стремлении повысить качество жизни, они забывают, что сплочённость команды профессионалов позволяет выполнить важные задания по разработке стандартных подходов. Приятно, граждане, наблюдать, как действия представителей оппозиции описаны максимально подробно. Но стремящиеся вытеснить традиционное производство, нанотехнологии могут быть объявлены нарушающими общечеловеческие нормы этики и морали! Задача организации, в особенности же внедрение современных методик позволяет оценить значение благоприятных перспектив. С учётом сложившейся ...',
            education: [],
            photo: '',

            profileState: 'loading'
        }
    }


    saveSurvey = () => {
        let result = decodeToken(localStorage.getItem('token'));

        
        let survey = {
            specialistId: result.id,
            experience: this.state.exp,
            lgbtqExperience: true,
            name: this.state.name,
            age: this.state.age,
            about: this.state.about,
            educations: this.state.education,
            categoriesIds: this.state.activeCategories,
            therapyMethodsIds: this.state.therapyMethodsIds
        }

        console.log(survey);

        Connections.AuthorizePost('/specialist/editsurvey', survey,
        (result) => {
            this.setState({message: 'Изменения сохранены'})
        },
        (error) => {
            console.log(error);
        },
        (fatalError) => {
            console.log(fatalError);
        })
    }

    componentDidMount() {
        let surveyPromise = Connections.AuthorizeGet('/specialist/survey',
        (result) => {

            console.log('Specialist survey get', result)
            this.setState({
                name: result.value.name,
                about: result.value.about,
                education: result.value.education,
                activeCategories: result.value.workCategories,
                age: result.value.age,
                photo: result.value.photo,
                exp: result.value.experience,
                videoUrl: result.value.videoUrl,
                therapyMethodsIds: result.value.therapyMethodsIds
            });
        },
        (error) => {
            console.log(error)
            this.setState({error: true})
        },
        (fatalError) => {
            console.log(fatalError)
            this.setState({error: true})
        });

        let catsPromise = Connections.Get('/categories',
        (cats) => {
            console.log('Categories get', cats)
            this.setState({allCategories: cats.value})
        },
        (error) => {
            this.setState({error: true})
        },
        (fatalError) => {
            this.setState({error: true})
        })

        
        let therapyMethods = Connections.Get('/therapyMethods',
        (methods) => {
            console.log('TherapyMethods get', methods)
            this.setState({allTherapyMethods: methods.value})
        },
        (error) => {
            this.setState({error: true})
        },
        (fatalError) => {
            this.setState({error: true})
        })


        Promise.all([surveyPromise, catsPromise, therapyMethods]).then(() => 
        {
            this.setState({profileState: 'loaded'})
        });
    }


    removeItem = (index) => {
        let arr = this.state.education;
        arr.splice(index, 1);
        this.setState({education: arr});
    }

    saveEdu = () => {
        if (this.state.eduYear.length == 0 || this.state.eduDecsription.length == 0)
            return;


        let eduInfo = [
            {
                year: this.state.eduYear,
                description: this.state.eduDecsription
            }
        ];

        this.state.education.forEach(item => eduInfo.push(item));

        this.setState({education: eduInfo, eduWindow: false, eduYear: '', eduDecsription: ''});
        
    }

    addEducationModal = () => {
        return <div onClick={() => this.setState({eduWindow: false})} style={this.state.eduWindow ? {} : {display: 'none'}} className="modal-block">
            <div className="body-text-small modal-inside" style={{width: '600px'}} onClick={(e) => e.stopPropagation()}>
                <h2>Добавить информацию об образовании</h2><br/>
                <input value={this.state.eduYear} onChange={(event) => this.setState({eduYear: event.target.value})} placeholder="Год получения" type="text"/><br/><br/>
                <input value={this.state.eduDecsription} onChange={(event) => this.setState({eduDecsription: event.target.value})} style={{width: '70%'}} placeholder="Организация" type="text"/><br/><br/>
                <button onClick={() => this.saveEdu()}>Сохранить</button>
            </div>
        </div>;
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
          let img = event.target.files[0];

          let file = new FormData();
          file.append('avatar', img);


          Connections.UploadImage('/specialist/uploadavatar', file,
          (result) => {
            this.setState({photo: result.value.photo});
          },
          (error) => {
            this.setState({error: true})
          },
          (fatalError) => {
            this.setState({error: true})
          })
        }
      };


    selectCategory = (catId) => {
        let activeCats = this.state.activeCategories;
        
        if (activeCats.findIndex(ac => ac === catId) === -1)
            activeCats.push(catId);
        else
            activeCats = activeCats.filter(item => item !== catId);

        this.setState({activeCategories: activeCats});
    }
    

    categoriesBlock = () => {
        console.log(this.state.activeCategories);
        return this.state.allCategories.map((category, index) => {

            return <div key={index}> 
                <h3 style={{marginBottom: '1rem'}}>{category.title}</h3>
                {category.subCategories.map((item, index) => {
                    return <span key={index} className="body-text-small reg-block-item">
                        <input checked={this.state.activeCategories.findIndex(ac => ac === item.id) !== -1} onChange={() => this.selectCategory(item.id)} className="checkbox" type="checkbox" id={'cat' + item.id}/> 
                        <label htmlFor={"cat" + item.id}>{item.title}</label>
                    </span>
                })
                }
            </div>
        })
    }


    selectTherapyMethod = (tmId) => {
        let therapyMethods = this.state.therapyMethodsIds;
        
        if (therapyMethods.findIndex(ac => ac === tmId) === -1)
            therapyMethods.push(tmId);
        else
            therapyMethods = therapyMethods.filter(item => item !== tmId);

        this.setState({therapyMethodsIds: therapyMethods});
    }
    


    therpyMethodsBlock = () => {
        console.log(this.state.activeCategories);
        return this.state.allTherapyMethods.map((item, index) => {

            return <span key={index} className="body-text-small reg-block-item">
                <input checked={this.state.therapyMethodsIds.findIndex(ac => ac === item.id) !== -1} onChange={() => this.selectTherapyMethod(item.id)} className="checkbox" type="checkbox" id={'met' + item.id}/> 
                <label htmlFor={"met" + item.id}>{item.title}</label>
            </span>
                
        })
    }

    render() {
        if (this.state.error)
            return 'Error occured'
        
        if (this.state.profileState === 'loading')
            return <div style={{height: '600px'}}><Loader /></div>;

        return <div className="big-card">
            {this.addEducationModal()}
            <h1 className="reg-block-header">Мои данные</h1>
            <div className="about">
                <div className="person-details">
                    <div className="person-avatar profile-avatar">
                        <img className="profile-avatar" src={this.state.photo ? 'avatars/' + this.state.photo : "images/avatars/selected.png"}/>
                        <label htmlFor='imgup'><img className="profile-loadpic" src="images/icons/photo.svg"/></label>
                        <input id='imgup' type="file" name="myImage" onChange={this.onImageChange} hidden={true}/>
                    </div>

                    <div className="select-about-block profile-details">
                            <h2>Как вас зовут?</h2>
                            <input value={this.state.name} onChange={(event) => this.setState({name: event.target.value})} type="text" placeholder="Имя"/>
                    </div>
                </div>
            </div>
            
            <div className="about-block" style={{display: 'flex', alignItems: 'center', gap: '15px', flexWrap: 'wrap'}}>
                <div>
                    <span className="settings-input-label" style={{marginRight: '10px'}}>Возраст</span>
                    <input value={this.state.age} onChange={(e) => this.setState({age: e.target.value})} type="text" placeholder="Не указано"/>
                </div>
                <div>
                    <span className="settings-input-label" style={{marginRight: '10px'}}>Опыт</span>
                    <input value={this.state.exp} onChange={(e) => this.setState({exp: e.target.value})} type="number" placeholder="Не указано"/>
                </div>
            </div>

            <div className="divide-line"></div>

            <div className="about-block">
                <h2 className="reg-block-header">Рабочие категории</h2>
                <div className="reg-columns">
                    <div className="reg-column-block">
                        {this.categoriesBlock()}
                    </div>
                </div>
            </div>

            <div className="divide-line"></div>


            <div className="about-block">
                <h2 className="reg-block-header">Методы терапии</h2>
                <div className="reg-columns">
                    <div className="reg-column-block">
                        {this.therpyMethodsBlock()}
                    </div>
                </div>
            </div>

            {this.state.videoUrl ?<>
            <div className="divide-line"></div>
                    <div className="reg-video-block">
                        <h2 className="reg-block-header">Видеопрезентация</h2>
                        <div className="video-presentation">
                            <iframe style={{width: '100%', height: '100%'}} src={"https://www.youtube.com/embed/" + this.state.videoUrl}  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                    </>
                    :
                    undefined
                    }
            <div className="divide-line"></div>
            <div>
                <div className="profile-header">
                    <h2 className="reg-block-header">О специалисте</h2>
                    <div onClick={() => this.setState({editAbout: true})} className="show-more">Редактировать</div>
                </div>
                { this.state.editAbout ? 
                    <textarea style={{width: '100%', height: '100%', minHeight: '150px'}} value={this.state.about} onChange={(event) => this.setState({about: event.target.value})}/>
                :
                <>
                <div className="to-overlap" style={{height: '100%'}}>
                    <div style={{whiteSpace: 'pre-wrap'}}>{this.state.about}</div>
                </div>
                </>
                }
            </div>
            <div className="divide-line"></div>
            <div className="about-block">
                <div className="profile-header">
                    <h2 className="reg-block-header">Образование</h2>
                    <div onClick={() => this.setState({eduWindow: true})} className="show-more">Добавить</div>
                </div>                
                <div className="to-overlap">
                    {this.state.education.map((item,index) => 
                        <div key={index} className="edu-item">
                            <div>
                                {item.description}
                            </div>
                            <div onClick={() => this.removeItem(index)} className="show-more">Удалить</div>
                        </div>
                    )}
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'right'}}>
                <button onClick={() => this.saveSurvey()}>Сохранить</button>
            </div>
            <div>{this.state.message}</div>
        </div>;
    }
}

export default SpecialistProfile;