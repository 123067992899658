import React from "react";
import ClientDiaryRecord from "./ClientDiaryRecord";
import connection from "../scripts/connection";
import Loader from "./Loader";
import ClientDiary from "./ClientDiary";
import ClientAnalysis from "./ClientAnalysis";

class ClientDiaryView extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            show: 'reflection'
        }
    }

    componentDidMount() {

     
    }

    render () {

        return <div className="webapp-container">
            <div className="webapp-logo">
                <a className="webapp-back" href="/platform/client"><img src="images/icons/arrow-back.svg" alt="Назад"/></a>
                <img src="images/logo.svg" />
            </div>
            <h2 className="webapp-title" style={{marginBottom: '50px'}}>Панель аналитики</h2>

            <div style={{textAlign: 'center', marginBottom: '15px', fontWeight: '600'}}>
                Клиент: <span style={{color: '#3A767B'}}>{localStorage.getItem('clientName')}</span>
            </div>

            <div className="inner-menu">
                <div onClick={() => this.setState({show: 'reflection'})} className={"inner-menu-item" + (this.state.show === 'reflection' ? ' inner-menu-active' : '')}>
                    <img src="images/icons/reflection.svg" alt=""/>
                    <span>Рефлексия</span>
                </div>
                <div onClick={() => this.setState({show: 'state'})} className={"inner-menu-item" + (this.state.show !== 'reflection' ? ' inner-menu-active' : '')}>
                    <img src="images/icons/clock-black.svg" alt=""/>
                    <span>Состояние</span>
                </div>
            </div>

            {this.state.show === 'reflection' ?
                <ClientDiary />
            :
                <ClientAnalysis />
            }

        </div>;
    }
}

export default ClientDiaryView;