import React from "react";


class SelectRoomModal extends React.Component {


    consultationTime = (time) => {
        let dt = new Date(time);
        let day = dt.getUTCDate() < 10 ? '0' + dt.getUTCDate() : dt.getUTCDate();
        let month = (dt.getUTCMonth() + 1) < 10 ? '0' + (dt.getUTCMonth() + 1) : (dt.getUTCMonth() + 1);
        let year = dt.getUTCFullYear();

        let minutes = dt.getUTCMinutes() < 10 ? '0' + dt.getUTCMinutes() : dt.getUTCMinutes();
        let hours = dt.getUTCHours() < 10 ? '0' + dt.getUTCHours() : dt.getUTCHours();

        return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes;
    }


    showConsultations = (consultations) => {
        if (consultations.length === 0)
            return <div style={{marginTop: 'auto', marginBottom: 'auto', textAlign: 'center'}}>Нет консультаций</div>;

        return consultations.map((item, index) => {
            return (
            <div key={index}>
                <h3 style={{marginBottom: '0.5rem'}}>
                   Консультация: {this.consultationTime(item.date)}
                </h3>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <button onClick={() => this.props.onConsEnter(item.id)}>Подключиться</button>
                </div>
                <hr style={{margin: '0.5rem 0'}}/>
            </div>
        )
        });
    }


    render() {
        console.log("Select room show")

        return <div className="modal-block" onClick={() => this.props.onHide()}>
        <div className="body-text-small modal-inside" onClick={(e) => e.stopPropagation()}>
            <h2 style={{marginBottom: '1rem'}}>Выбор консультации</h2>
            <div style={{display: 'flex', flexFlow: 'column', overflow: 'auto', height: '400px'}}>
                {this.showConsultations(this.props.consultations)}
            </div>
        </div>
    </div>;
    }
}

export default SelectRoomModal;