import React from "react";


class AdvantagesBlock extends React.Component {

    render () {

        return <div className="adv-block">
            <div className="showcase-title">Почему стоит записаться в Uwio</div>

            <div className="adv-first">
                <div className="adv-item">
                    <div className="adv-item-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.49 10.19L20.49 9.63999L11.49 4.63999H11.38C11.3187 4.61407 11.2551 4.59398 11.19 4.57999H11H10.82C10.7517 4.59399 10.6847 4.61407 10.62 4.63999H10.51L1.51 9.63999C1.3561 9.7272 1.22809 9.85368 1.13903 10.0065C1.04997 10.1594 1.00305 10.3331 1.00305 10.51C1.00305 10.6869 1.04997 10.8606 1.13903 11.0134C1.22809 11.1663 1.3561 11.2928 1.51 11.38L4 12.76V17.5C4 18.2956 4.31607 19.0587 4.87868 19.6213C5.44129 20.1839 6.20435 20.5 7 20.5H15C15.7956 20.5 16.5587 20.1839 17.1213 19.6213C17.6839 19.0587 18 18.2956 18 17.5V12.76L20 11.64V14.5C20 14.7652 20.1054 15.0196 20.2929 15.2071C20.4804 15.3946 20.7348 15.5 21 15.5C21.2652 15.5 21.5196 15.3946 21.7071 15.2071C21.8946 15.0196 22 14.7652 22 14.5V11.06C21.9997 10.8828 21.9523 10.7089 21.8627 10.556C21.7731 10.4031 21.6445 10.2768 21.49 10.19ZM16 17.5C16 17.7652 15.8946 18.0196 15.7071 18.2071C15.5196 18.3946 15.2652 18.5 15 18.5H7C6.73478 18.5 6.48043 18.3946 6.29289 18.2071C6.10536 18.0196 6 17.7652 6 17.5V13.87L10.51 16.37L10.66 16.43H10.75C10.833 16.4404 10.917 16.4404 11 16.43C11.083 16.4404 11.167 16.4404 11.25 16.43H11.34C11.3931 16.4188 11.4438 16.3985 11.49 16.37L16 13.87V17.5ZM11 14.36L4.06 10.5L11 6.63999L17.94 10.5L11 14.36Z" fill="#3A767B"/>
                        </svg>
                    </div>
                    <div>Проверяем профильное образование психологов и подготовку в терапевтических подходах</div>
                </div>

                <div className="adv-item">
                    <div className="adv-item-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 3C15.552 3 16 3.448 16 4V6H21C21.552 6 22 6.448 22 7V20C22 20.552 21.552 21 21 21H3C2.448 21 2 20.552 2 20V7C2 6.448 2.448 6 3 6H8V4C8 3.448 8.448 3 9 3H15ZM16 8H8V19H16V8ZM4 8V19H6V8H4ZM14 5H10V6H14V5ZM18 8V19H20V8H18Z" fill="#3A767B"/>
                        </svg>
                    </div>
                    <div>Анализируем  кейсы специалистов по необходимым специализациям и знания об устройстве психики</div>
                </div>

                <div className="adv-item">
                    <div className="adv-item-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.21913 3.3753C5.4089 3.13809 5.69622 3 6 3H18C18.3038 3 18.5911 3.13809 18.7809 3.3753L22.7809 8.37531C23.0777 8.74638 23.0724 9.27512 22.7682 9.64018L12.7682 21.6402C12.5782 21.8682 12.2968 22 12 22C11.7032 22 11.4218 21.8682 11.2318 21.6402L1.23178 9.64018C0.927559 9.27512 0.922272 8.74638 1.21913 8.37531L5.21913 3.3753ZM6.48063 5L3.29095 8.98709L12 19.438L20.709 8.98709L17.5194 5H6.48063Z" fill="#3A767B"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M1 9C1 8.44772 1.44772 8 2 8H22C22.5523 8 23 8.44772 23 9C23 9.55228 22.5523 10 22 10H2C1.44772 10 1 9.55228 1 9Z" fill="#3A767B"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.6838 8.05134C8.20774 7.8767 8.77406 8.15986 8.94871 8.6838L12.9487 20.6838C13.1234 21.2077 12.8402 21.7741 12.3163 21.9487C11.7923 22.1234 11.226 21.8402 11.0513 21.3163L7.05134 9.31625C6.8767 8.79231 7.15986 8.22599 7.6838 8.05134Z" fill="#3A767B"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M16.3163 8.05134C16.8402 8.22599 17.1234 8.79231 16.9487 9.31625L12.9487 21.3163C12.7741 21.8402 12.2077 22.1234 11.6838 21.9487C11.1599 21.7741 10.8767 21.2077 11.0513 20.6838L15.0513 8.6838C15.226 8.15986 15.7923 7.8767 16.3163 8.05134Z" fill="#3A767B"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M4.6247 5.71911C5.05596 6.06412 5.12588 6.69341 4.78087 7.12467L3.29095 8.98707L12 19.4379L20.709 8.98707L19.2191 7.12467C18.8741 6.69341 18.944 6.06412 19.3753 5.71911C19.8066 5.3741 20.4359 5.44402 20.7809 5.87528L22.7809 8.37528C23.0777 8.74636 23.0724 9.2751 22.7682 9.64016L12.7682 21.6402C12.5782 21.8682 12.2968 22 12 22C11.7032 22 11.4218 21.8682 11.2318 21.6402L1.23178 9.64016C0.927559 9.2751 0.922272 8.74636 1.21913 8.37528L3.21913 5.87528C3.56414 5.44402 4.19343 5.3741 4.6247 5.71911Z" fill="#3A767B"/>
                        </svg>
                    </div>
                    <div>Используем самые эффективные методы терапии эмоциональных и поведенческих дисбалансов</div>
                </div>

                <div className="adv-item">
                    <div className="adv-item-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1214_389)">
                            <path d="M11 2C15.068 2 18.426 5.036 18.934 8.965L21.184 12.504C21.332 12.737 21.302 13.084 20.959 13.232L19 14.07V17C19 18.105 18.105 19 17 19H15.001L15 22H6V18.306C6 17.126 5.564 16.009 4.756 15.001C3.657 13.631 3 11.892 3 10C3 5.582 6.582 2 11 2ZM11 4C7.686 4 5 6.686 5 10C5 11.385 5.468 12.693 6.316 13.75C7.41 15.114 8 16.667 8 18.306V20H13L13.002 17H17V12.752L18.55 12.088L17.007 9.663L16.95 9.221C16.566 6.251 14.024 4 11 4ZM11 7C11.552 7 12 7.448 12 8V9H13C13.552 9 14 9.448 14 10C14 10.552 13.552 11 13 11H12V12C12 12.552 11.552 13 11 13C10.448 13 10 12.552 10 12V10.999L9 11C8.448 11 8 10.552 8 10C8 9.448 8.448 9 9 9L10 8.999V8C10 7.448 10.448 7 11 7Z" fill="#3A767B"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_1214_389">
                            <rect width="24" height="24" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div>Обучаем навыкам самоанализа и управления эмоциональным состоянием</div>
                </div>
            </div>

            <div className="adv-second">
                <div className="adv-second-item">
                    <div className="adv-second-title">
                        <svg className="adv-second-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 5L4.69699 5.07744C7.14576 5.34953 9.60878 4.70802 11.6137 3.27594L12 3L12.3863 3.27594C14.3912 4.70802 16.8542 5.34953 19.303 5.07744L20 5V12.0557C20 15.0859 18.288 17.856 15.5777 19.2111L12 21L8.42229 19.2111C5.71202 17.856 4 15.0859 4 12.0557V5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                        <span className="adv-second-title-text">Конфиденциальность</span>
                    </div>
                    <div className="adv-second-content">
                        Ваша информация защищена на 100% и не передается третьим лицам. Только вы и психолог. Специалисты Uwio подписывают соответствующее соглашение
                    </div>
                </div>

                <div className="adv-second-item">
                    <div className="adv-second-title">
                        <svg className="adv-second-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.9">
                            <path d="M8.625 15.375L15.375 8.625M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                            </g>
                        </svg>

                        <span className="adv-second-title-text">Бесплатная отмена<br/> и перенос сессии</span>
                    </div>
                    <div className="adv-second-content">
                        Вы можете бесплатно отменить или перенести сессию за 8 часов до ее начала
                    </div>
                </div>
            </div>

        </div>;
    }
}

export default AdvantagesBlock;