import React from "react";
import Loader from "./Loader";
import connection from "../scripts/connection";
import ClientDiaryRecord from "./ClientDiaryRecord";


class ClientDiary extends React.Component {


    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount() {

        let clientId = localStorage.getItem('clientId');

        connection.AuthorizeGet('/diary/users/' + clientId + '/records',
            (success) => {
                console.log(success);
                this.setState({records: success.value, loaded: true});
            },
            (responseError) => {
                this.setState({loaded: true, errorMessage: 'Произошла ошибка'});
            },
            (fatalError) => {
                this.setState({loaded: true, errorMessage: 'Не удается подключиться к серверу'})
            }
        );
    }

    render () {


        if (!this.state.loaded)
            return <Loader />

        if (this.state.errorMessage)
            return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{this.state.errorMessage}</div>

        return <div>
            {this.state.records?.length === 0 ?
                <div style={{textAlign: 'center'}}>Нет записей</div>
            :
            undefined
            } 

            {this.state.records?.map((record, index) => {
                return <ClientDiaryRecord 
                    key={index}
                    date={new Date(record.createDate)}
                    event={record.event}
                    emotions={record.emotions}
                    thought={record.thought}
                    reaction={record.symptoms + '\n\n' + record.behavior}
                />
            })}
        </div>;
    }
}

export default ClientDiary;