import React from "react";
import { Navigate } from "react-router";
import Loader from "./Loader";
import MessageModal from "./MessageModal";
import DateTimeSelectorOnboarding from "./DateTimeSelectorOnboarding";
import ConsultationBookingModal from "./ConsultationBookingModal";
import { decodeToken } from "react-jwt";
import connection from "../scripts/connection";

class SpecialistCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount() {

        connection.Get('/categories',
        (success) => {
            console.log('Categories:',success);

            let subcategoriesArray = success.value.map(i => i.subCategories);
            let subcategories = [];

            for (let i = 0; i < subcategoriesArray.length; i++)
                subcategories = subcategories.concat(subcategoriesArray[i]);
            
            let subcategoriesDictionary = {};
            subcategories.forEach(i => subcategoriesDictionary[i.id] = i.title);

            this.setState({
                categories: subcategoriesDictionary,
                categoriesLoaded: true
            })
        },
        (error) => {
            console.log(error);
        },
        (fatalError) => {
            console.log(fatalError);
        });

        connection.Get('/therapymethods',
        (success) => {
            console.log('therapyMethods:',success);

            let therapyMethods = {};
            success.value.forEach(item => therapyMethods[item.id] = item);

            this.setState({
                therapyMethods: therapyMethods
            })
        },
        (error) => {
            this.setState({
                therapyMethods: {}
            })
            console.log(error);
        },
        (fatalError) => {
            console.log(fatalError);
        });

        connection.AuthorizeGet('/booking/specialistSurvey?specialistId=' + localStorage.getItem("specialistId"),
        (success) => {
            console.log(success);
            this.setState({
            
                selectedSpecialist: success.value,
                loaded: true,
                consultationType: success.value.consultationType//,success.value.consultationType
            })
            console.log(this.state.selectedSpecialist)
        },
        (error) => {
            console.log(error);
            if (error.value.noSpecialists)
            {
                this.setState({loaded: true, noSpecialists: true});
            }
        },
        (requestError) => {
            console.log(requestError);
        })
    }

    parseDate = (specialistShowCase) => {
        let months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        let days = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];

        let dt = new Date(specialistShowCase.availableTime[0].date);

        return dt.getDate() + ' ' + months[dt.getMonth()] + ', ' + days[dt.getDay()];
    }

    getClosestTimeBlock = (closestDate) => {
        let months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        let days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

        let date = new Date(closestDate);
        // Время
        // День с месяцем
        // день недели
        let time = date.getHours() + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
        let day = date.getDate() + ' ' + months[date.getMonth()];
        let dayOfWeek = days[date.getDay()];


        let formatedDt = day + ', ' + time + ', ' + dayOfWeek;

        return <div className="closestdate-block">
            <img className="closestdate-icon" src="images/icons/calendarCurved.svg"/>
            <div>
                <div className="closestdate-text">Ближайшее время</div>
                <div className="closestdate-date">{formatedDt}</div>
            </div>
        </div>;
    }

    getWorkThemes = () => {
        if (this.state.selectedCategories?.length === 0)
            return null;

        if (this.state.selectedSpecialist.categoryMatch.length === 0)
            return (<div className="about-block working-themes-block">
                    <h2 className="reg-block-header">Не работает с вашими темами, но сможет помочь</h2>
                </div>)

        let themes = this.state.selectedSpecialist.categoryMatch.map((cat, index)=> {
            return <span key={index} className="tag">{this.state.categories[cat]}</span>;
        });

        let text = themes.length % 2 === 1 ? 'выбранной темой' : 'выбранными темами'

        return(<div className="about-block working-themes-block">
                    <h2 className="reg-block-header">Работает с {themes.length} {text}</h2>
                    <div className="tag-list">
                        {themes.map(i => i)}
                    </div>
                </div>)
    }

    getTherapyMethods = () => {
        if (this.state.therapyMethods?.length === 0)
            return null;
        
        if (this.state.selectedSpecialist.therapyMethods?.length === 0)
            return;

        return <>
            <hr/>
            <div className="about-block working-themes-block">
                <h2 className="reg-block-header">Методы терапии</h2>
                <div className="tag-list">
                {this.state.selectedSpecialist.therapyMethods?.map((item, index) => {
                        return <span key={index} className="tag">{this.state.therapyMethods[item].title} { this.state.therapyMethods[item].description ? <img onClick={() => this.setState({message: this.state.therapyMethods[item].description})} className="info-icon-method" src="images/icons/info.svg"/> : undefined}</span>
                    })}
                </div>
            </div>
        </>;
    }

    getSpecialistWorkThemes = () => {
        return this.state.selectedSpecialist.subCategories.map((cat, index) => {
            return <span key={index}>{this.state.categories[cat]}</span>
        })
    }

    getSpecialistEducation = () => {
        return this.state.selectedSpecialist.education.map((edu, index) => {
            console.log(edu);
            return (
                <div className="edu-item">
                    <div>
                        {edu.description}
                    </div>
                </div>
            )
        })
    }

    getExperience = () => {
        if (!this.state.selectedSpecialist.experience)
            return <span style={{height: '18px'}}></span>;

        let words = [ 'лет', 'год', 'года', 'года', 'года', 'лет', 'лет', 'лет', 'лет', 'лет']
        let exp = this.state.selectedSpecialist.experience;

        let word = words[exp % 10];


        if (exp == 11 || exp == 12 || exp == 13 || exp == 14)
            word = 'лет';

        return <span onClick={() => this.setState({showExpCheckedMessage: !this.state.showExpCheckedMessage})}>
            <span>Опыт {exp} {word}</span><img className="info-icon-exp" src="images/icons/info.svg"/>
            {this.state.showExpCheckedMessage ?
                <div onClick={() => this.setState({showExpCheckedMessage: false})} style={{marginLeft: '-40px'}} className="tooltipselect">
                    <div style={{padding: '7px', marginLeft: 'auto', left: 'auto'}} className="tooltiptext tooltip-exp">Образование и опыт специалиста проверены в Uwio</div>
                </div>
            :
            undefined
            }
           </span>;
    }

    addAdditionalTime = (time) => {
        let spec = this.state.selectedSpecialist;
        spec.availableTime = [ time ];
        this.setState({selectedSpecialist: spec, showTimeSelector: false});
    }

    selectSpecialst = (id) => {
        console.log("specId",id);
        this.setState(
            {
                selectedSpecialist: this.state.specialists.find(item => item.specialistId == id),
                showSecondBlock: false,
                showFirstBlock: false,
                showThirdBlock: false
            } 
        );
    }

    bookConsultation = (time) => {
        let tokenData = decodeToken(localStorage.getItem('token'));

        connection.AuthorizePost('/booking/bookconsultation',
        {
            clientId: tokenData.id,
            specialistId: this.state.selectedSpecialist.specialistId,
            time: time,
            consultationType: this.state.consultationType,
        },
        (success) => {
            this.setState({message: 'Консультация забронирована', showBookingModal: false});
        },
        (error) => {
            console.log(error)
            this.setState({message: error.value});
        },
        (fatalError) => {
            this.setState({error: true})
        })
    }

    render() {
        if (!this.state.loaded || !this.state.categoriesLoaded || !this.state.therapyMethods)
            return <div style={{height: '600px'}}><Loader /></div>;

        if (this.state.error)
            return 'Произошла ошибка, попробуйте позже';

        return <>

            {this.state.message ?
                <MessageModal show={true} message={this.state.message} header={"Информация"} onHide={() => this.setState({message: null})}/>
                :
                undefined
            }
            {this.state.showTimeSelector ?
                <DateTimeSelectorOnboarding show={true} specialistId={this.state.selectedSpecialist.specialistId} onSelect={(time) => this.addAdditionalTime(time)} onHide={() => this.setState({showTimeSelector: false})}/>
            :
                undefined
            }

            {this.state.showBookingModal ?
                <ConsultationBookingModal 
                    specialistId={this.state.selectedSpecialist.specialistId}
                    //price={this.state.selectedSpecialist.price}
                    specialistName={this.state.selectedSpecialist.name}
                    specialistPhotoUrl={this.state.selectedSpecialist.photoUrl}
                    onBooked={() => this.setState({payment: true})} 
                    onHide={() => this.setState({showBookingModal: false})}
                    onSelectedTime={(selectedTime, consultationType) => this.bookConsultation(selectedTime, consultationType)}
                    consultationType={this.state.consultationType}
                    consultationTypeSelector={true}
                    onConsultationTypeSelected={(consType) => this.setState({consultationType: consType})}
                />
            :    
                undefined
            }

            
            <div className="big-card">
                <div className="about">
                    <div className="person-details">
                        <img className="person-avatar" src={ this.state.selectedSpecialist.photoUrl ? 'avatars/' + this.state.selectedSpecialist?.photoUrl : "images/avatars/selected.png"}/>
                        <div className="select-about-block">
                            <div className="select-about-details">
                                <h1>{this.state.selectedSpecialist.name}</h1>
                                {this.getExperience()}
                                <span>Сессия {this.state.selectedSpecialist.consultationType === "Double" ? '100' : '55'} мин - {this.state.selectedSpecialist.price} ₽</span>
                               {/* <span className="selected-date">{this.parseDate(this.state.selectedSpecialist)}</span>*/}
                               {this.getClosestTimeBlock(this.state.selectedSpecialist.closestTime)}

                            </div>
                            
                            <button onClick={() => this.setState({showBookingModal: true})} className="reg-time-button">Выбрать время сессии</button>
                        </div>
                    </div>
                </div>

                <div className="reg-first-blocks">
                    {this.getWorkThemes()}

                    {this.getTherapyMethods()}

                    {this.state.selectedSpecialist.videoUrl ?
                    <div className="reg-video-block">
                        <h2 className="reg-block-header">Видеопрезентация специалиста</h2>
                        <div className="video-presentation">
                            <iframe style={{width: '100%', height: '100%'}} src={"https://www.youtube.com/embed/" + this.state.selectedSpecialist.videoUrl}  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                    :
                    undefined
                    }
                </div>
                <div className="divide-line"></div>
                <div>
                    <h2 className="reg-block-header">О специалисте</h2>
                    <div className="to-overlap" style={this.state.showSecondBlock ? {height: '100%'} : {height: '90px', overflow: 'hidden'}}>
                        <div style={{whiteSpace: 'pre-wrap'}}>
                        {this.state.selectedSpecialist.about}
                        </div>

                        { !this.state.showSecondBlock ?
                        <div className="overlap"></div>
                        :
                        ''
                        }
                    </div>

                    {!this.state.showSecondBlock ? 
                    <div onClick={() => this.setState({showSecondBlock: true})} className="show-more">
                        <span>Показать ещё</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#3F67F4" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.7071 10.7071C17.0976 10.3166 17.0976 9.68342 16.7071 9.29289C16.3166 8.90237 15.6834 8.90237 15.2929 9.29289L16.7071 10.7071ZM12 14L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L12 14ZM8.70711 9.29289C8.31658 8.90237 7.68342 8.90237 7.29289 9.29289C6.90237 9.68342 6.90237 10.3166 7.29289 10.7071L8.70711 9.29289ZM15.2929 9.29289L11.2929 13.2929L12.7071 14.7071L16.7071 10.7071L15.2929 9.29289ZM12.7071 13.2929L8.70711 9.29289L7.29289 10.7071L11.2929 14.7071L12.7071 13.2929Z" fill="#3F67F4"/>
                        </svg>
                    </div>
                    :
                    ''
                    }
                </div>
                <div className="divide-line"></div>
                <div className="about-block">
                    <h2 className="reg-block-header">Образование</h2>
                    <div className="to-overlap" style={this.state.showThirdBlock ? {height: '100%'} : {height: '100px', overflow: 'hidden'}}>
                        {this.getSpecialistEducation()}
                        {!this.state.showThirdBlock ?
                        <div className="overlap"></div>
                        :
                        ''
                        }
                    </div>

                    { !this.state.showThirdBlock ?
                    <div onClick={() => this.setState({showThirdBlock: true})} className="show-more">
                        <span>Показать ещё</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#3F67F4" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.7071 10.7071C17.0976 10.3166 17.0976 9.68342 16.7071 9.29289C16.3166 8.90237 15.6834 8.90237 15.2929 9.29289L16.7071 10.7071ZM12 14L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L12 14ZM8.70711 9.29289C8.31658 8.90237 7.68342 8.90237 7.29289 9.29289C6.90237 9.68342 6.90237 10.3166 7.29289 10.7071L8.70711 9.29289ZM15.2929 9.29289L11.2929 13.2929L12.7071 14.7071L16.7071 10.7071L15.2929 9.29289ZM12.7071 13.2929L8.70711 9.29289L7.29289 10.7071L11.2929 14.7071L12.7071 13.2929Z" fill="#3F67F4"/>
                        </svg>
                    </div>
                    :
                    ''
                    }
                </div>
            </div>
            </>
    }
}

export default SpecialistCard;